import { TabPanel } from "./TabPanel";
import { useContext, useState } from "react";
import { Message } from "components/SnackbarMessage";
import AlertDialog from "components/AlertDialog";
import OutlinedButton from "components/buttons/OutlinedButton";
import ClipboardCopy from "components/ClipboardCopy";
import {
  Box,
  Button,
  FormControl,
  TextField,
  Tooltip,
  Paper,
} from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";
import { useActionWithPath } from "utils/query";
import TagsDialog from "./TagsDialog";
import DataTable from "components/DataTable/DataTable";
import { ClusterType } from "utils/saastypes";
import { SUB_DOMAIN_REGEX } from "constants/common";
import { MessageContext } from "utils/MessageProvider";

interface URL {
  display: boolean;
  id: string;
  urlType: string;
  urlValue: string;
}

export default function UrlsTab({
  selectedTabIndex,
  index,
  clusterDetail,
}: {
  selectedTabIndex: number;
  index: number;
  clusterDetail: any;
}) {
  const conductorUiUrls = clusterDetail?.urls?.filter(
    (url: any) => url.urlType === "ConductorUIURL"
  );

  const { setMessage } = useContext(MessageContext);

  const [tagDialogEntityId, setTagDialogEntityId] = useState<string>("");

  const [showAddNewSubDomain, setShowAddNewSubDomain] = useState(false);
  const [showRemoveSubDomain, setShowRemoveSubDomain] = useState(false);
  const [subdomain, setSubdomain] = useState("");

  const showError = (message: string) => {
    setMessage(new Message(message, "error"));
  };

  const showSuccess = (message: string) => {
    setMessage(new Message(message, "success"));
  };

  const addSubdomainsAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError("Failed to add sub domain");
      } else {
        showSuccess(
          `Sub-domain has been updated successfully. It will take several minutes to propagate.`
        );
      }
    },
  });

  return (
    <TabPanel value={selectedTabIndex} index={index}>
      {tagDialogEntityId ? (
        <TagsDialog
          open={!!tagDialogEntityId}
          setOpen={() => setTagDialogEntityId("")}
          entityId={tagDialogEntityId}
        />
      ) : null}

      <AlertDialog
        isConfirmDisabled={!SUB_DOMAIN_REGEX.test(subdomain)}
        textTitle={"Add new Sub-Domain"}
        textContent={`This action will take several minutes to complete.`}
        open={showAddNewSubDomain}
        setOpen={setShowAddNewSubDomain}
        handleConfirm={() => {
          setShowAddNewSubDomain(false);
          // @ts-ignore
          addSubdomainsAction.mutate({
            method: "post",
            path: `/management/addSubDomain`,
            body: JSON.stringify({
              clusterName: clusterDetail?.name,
              subdomain,
              action: "CREATE",
            }),
            errorMessage: "Failed to add sub domain:",
          });
        }}
      >
        <Box sx={{ minWidth: 250, marginTop: 10, display: "flex" }}>
          <Box style={{ width: "100%" }}>
            <FormControl fullWidth>
              <TextField
                error={!SUB_DOMAIN_REGEX.test(subdomain)}
                helperText="Sub-domains are formed by lowercase letters or numbers and may be separated by hyphens (e.g., a-valid-sub-domain-123)"
                style={{ marginRight: 16 }}
                label="URL Sub-Domain"
                placeholder="sub-domain"
                value={subdomain}
                onChange={(ev) => {
                  setSubdomain(ev.target.value);
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </AlertDialog>

      <AlertDialog
        textTitle={"Remove Sub-Domain"}
        textContent={
          <>
            This action will take several minutes to complete. Please ensure
            this URL: <strong className="danger-text">{subdomain}</strong> is
            &nbsp;not used before deletion.
          </>
        }
        open={showRemoveSubDomain}
        setOpen={setShowRemoveSubDomain}
        handleConfirm={() => {
          setShowRemoveSubDomain(false);
          const subdomainToSubmit = subdomain.substring(
            0,
            subdomain.indexOf(".")
          );
          // @ts-ignore
          addSubdomainsAction.mutate({
            method: "post",
            path: `/management/addSubDomain`,
            body: JSON.stringify({
              clusterName: clusterDetail?.name,
              subdomain: subdomainToSubmit,
              action: "DELETE",
            }),
          });
        }}
        isInputConfirmation
        valueToBeDeleted={subdomain}
      />

      <div style={{ borderBottom: "1px solid lightgrey", padding: "16px" }}>
        <Paper variant="outlined">
          <DataTable
            pagination={false}
            noDataComponent={
              <Box padding={5} fontWeight={400}>
                No URLs found
              </Box>
            }
            data={conductorUiUrls}
            columns={[
              {
                name: "ID",
                label: "Entity Id",
                selector: (row: URL) => row.id,
                grow: 1,
              },
              {
                name: "url",
                label: "URL",
                selector: (row: URL) => row.urlValue,
                renderer: (val: any, row: URL) => {
                  const address = `https://${row.urlValue}`;

                  return (
                    <ClipboardCopy
                      style={{ display: "inline-flex" }}
                      value={address}
                    >
                      <a target="_blank" rel="noreferrer" href={address}>
                        {address}
                      </a>
                    </ClipboardCopy>
                  );
                },
                grow: 1,
              },
              {
                name: "swagger_url",
                label: "Swagger URL",
                selector: (row: URL) => row.urlValue,
                renderer: (val: any, row: URL) => {
                  const address = `https://${row.urlValue}`;
                  const swagger = `${address}/swagger-ui/index.html?configUrl=/api-docs/swagger-config#`;

                  return (
                    <ClipboardCopy
                      style={{ display: "inline-flex", marginLeft: "1em" }}
                      value={swagger}
                    >
                      <a target="_blank" rel="noreferrer" href={swagger}>
                        {address.slice(0, 15)}/.../swagger-ui/.../
                      </a>
                    </ClipboardCopy>
                  );
                },
                grow: 1,
              },
              {
                name: "actions",
                label: "Actions",
                selector: (row: URL) => null,
                right: true,
                renderer(val: any, row: any) {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                          setTagDialogEntityId(row.id);
                        }}
                      >
                        Edit Tags
                      </Button>

                      {/* This operation is meant for customer hosted clusters only */}
                      {/* It must not be possible to delete the last remaining URL  */}
                      {clusterDetail.type !== ClusterType.STARTER &&
                        conductorUiUrls.length > 1 && (
                          <Tooltip title={"Delete this URL"}>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                setSubdomain(`${row.urlValue}`);
                                setShowRemoveSubDomain(true);
                              }}
                              size="small"
                              aria-controls="basic-menu"
                              startIcon={
                                <DeleteRounded
                                  style={{ fontSize: 20, color: "error" }}
                                />
                              }
                              sx={{ ml: 2, textTransform: "none" }}
                            >
                              Delete
                            </Button>
                          </Tooltip>
                        )}
                    </>
                  );
                },
              },
            ]}
          />
        </Paper>

        {/* <Grid container>{urlsToRender}</Grid> */}

        <div
          style={{
            paddingTop: 15,
            paddingBottom: 15,
          }}
        >
          {/* This operation is meant for customer hosted clusters only */}
          {clusterDetail.type !== ClusterType.STARTER && (
            <OutlinedButton
              onClick={() => {
                setSubdomain("");
                setShowAddNewSubDomain(true);
              }}
            >
              Add Another
            </OutlinedButton>
          )}
        </div>
      </div>
    </TabPanel>
  );
}
