import { CustomerEnvironment } from "utils/saastypes";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  IntegrationInstructionsOutlined,
  LaptopChromebookOutlined,
} from "@mui/icons-material";
import * as React from "react";
import { useUserIds } from "utils/query";
import {
  resolveVersion,
  getColorFromState,
  getEnvironmentIcon,
  getComputeUnitIcon,
} from "utils/utils";
import { HostingChip } from "../chips/HostingChip";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import {
  ProviderLogo,
  buttonSx,
  IconFromState,
  clickableChipSx,
} from "./common";

const bottomSectionFontSize = 14;

const paperSx = {
  padding: 0,
  ml: 2.5,
  mr: 3.0,
  mb: 2.0,
  mt: 3.0,
};

const chipSx = {
  opacity: 1,
  "& span": {
    fontFamily: "Lexend, sans-serif",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 2.5,
    paddingRight: 2.5,
    paddingTop: 0.5,
  },
};

export default function ClusterDetailDisplay(props: {
  custEnv: CustomerEnvironment | undefined;
  deleteClusterMethod?: (
    confirmed: boolean,
    clusterName: string | undefined
  ) => void;
  enableClusterDeletionMethod?: (
    confirmed: boolean,
    clusterName: string | undefined
  ) => void;
  paperStyles?: React.CSSProperties;
  skipMiddleBar: boolean;
}) {
  const { authService } = useAuth();
  const [{ isOrkesUser }] = useAuthMachine(authService);

  const { data: userData } = useUserIds([props.custEnv?.createdBy as string]);

  if (props.custEnv === undefined) {
    return <></>;
  }

  const uiUrl = props.custEnv?.urls?.find(
    (x) => x?.urlType === "ConductorUIURL"
  )?.urlValue;
  const swaggerUrl = props.custEnv?.urls?.find(
    (x) => x?.urlType === "ConductorSwaggerURL"
  )?.urlValue;

  return (
    <Paper
      elevation={2}
      sx={paperSx}
      style={{
        margin: 25,
      }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginBottom: 10,
        }}
        className={`clusterTop`}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Box
            className={"paperOrgHeader"}
            style={{ fontSize: 18, paddingTop: 15, paddingLeft: 25 }}
          >
            <Tooltip title={"Cluster Details"}>
              <Button
                component={Link}
                to={`/home/clusterDetails/${props.custEnv?.name}`}
                variant="text"
                sx={{ ...buttonSx, p: 0, userSelect: "all" }}
              >
                {props.custEnv?.name}
                <Typography
                  component="span"
                  sx={{ width: 25, height: 20, ml: 1, mt: -4 }}
                >
                  <ProviderLogo provider={props.custEnv?.cloudProvider} />
                </Typography>
              </Button>
            </Tooltip>
          </Box>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingLeft: "25px",
              flexGrow: 1,
            }}
          >
            <Box
              style={{
                fontSize: 15,
                paddingTop: 12,
                paddingLeft: 5,
              }}
            >
              <Chip
                label={`${
                  props.custEnv?.computeUnitDescription
                    ? props.custEnv?.computeUnitDescription
                    : "NA"
                }`}
                avatar={
                  <Avatar
                    sx={{ margin: 0, padding: 0 }}
                    alt="O"
                    src={getComputeUnitIcon(props.custEnv?.computeUnits)}
                  />
                }
                color={"info"}
                variant={"outlined"}
                sx={chipSx}
              />
            </Box>
            <Box
              style={{
                fontSize: 15,
                paddingTop: 12,
                paddingLeft: 5,
              }}
            >
              <Chip
                label={`${
                  props.custEnv?.environmentType
                    ? props.custEnv?.environmentType
                    : "PRODUCTION"
                }`}
                color={"info"}
                variant={"outlined"}
                sx={chipSx}
                avatar={
                  <Avatar
                    sx={{
                      margin: 0,
                      padding: 0,
                      "& img": {
                        width: 18,
                        height: 18,
                      },
                    }}
                    alt="env"
                    src={getEnvironmentIcon(props.custEnv?.environmentType)}
                  />
                }
              />
            </Box>
            <Box
              style={{
                fontSize: 15,
                paddingTop: 12,
                paddingLeft: 5,
              }}
            >
              <HostingChip cluster={props.custEnv} />
            </Box>
            <Box
              className="ConductorclusterEnv"
              style={{
                fontSize: 15,
                paddingTop: 12,
                paddingLeft: 5,
                marginRight: 10,
              }}
            >
              <Tooltip title={"Click to monitor progress of the provisioning"}>
                <Link
                  to={`/home/clusterprogess?clusterName=${props.custEnv?.name}`}
                >
                  <Chip
                    label={
                      props.custEnv?.state === "SCHEDULE_DELETION"
                        ? "DELETION SCHEDULED"
                        : props.custEnv?.state ?? "NA"
                    }
                    color={getColorFromState(props.custEnv?.state)}
                    sx={clickableChipSx}
                    icon={<IconFromState state={props.custEnv?.state} />}
                  />
                </Link>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      {!props.skipMiddleBar && (
        <>
          <Divider />
          <Box
            style={{
              display: "flex",
              justifyContent: "start",
              minHeight: "fit-content",
              flexWrap: "wrap",
              marginBottom: "22px",
            }}
          >
            <Box
              style={{
                display: "flex",
                width: 250,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                Ingress Type & Links
              </div>
              <div
                className={"paperOrgHeader"}
                style={{ fontSize: bottomSectionFontSize + 2, opacity: 0.8 }}
              >
                <div>
                  {props.custEnv.ingressType === "INTERNAL"
                    ? "Internal (VPC)"
                    : "External (Internet)"}
                </div>
                <ul
                  style={{
                    padding: 0,
                    margin: 0,
                    listStyle: "none",
                    marginTop: 20,
                  }}
                >
                  <li>
                    <a
                      target="_blank"
                      rel={"noreferrer noopener"}
                      href={`https://${uiUrl}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 4,
                      }}
                    >
                      <LaptopChromebookOutlined />
                      &nbsp;Conductor UI
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel={"noreferrer noopener"}
                      href={`https://${swaggerUrl}`}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 4,
                      }}
                    >
                      <IntegrationInstructionsOutlined />
                      &nbsp;API Documentation
                    </a>
                  </li>
                </ul>
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                width: 300,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                Versions:
              </div>
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize + 2,
                  opacity: 0.8,
                }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  Conductor Server:{" "}
                  {resolveVersion(props.custEnv?.appVersions?.conductor)}
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  Conductor Workers:{" "}
                  {resolveVersion(props.custEnv?.appVersions?.workers)}
                </Typography>
                {props.custEnv?.appVersions?.metricsProxy && (
                  <Typography
                    style={{
                      fontSize: 16,
                      lineHeight: 1.7,
                    }}
                  >
                    Metrics Proxy:{" "}
                    {resolveVersion(props.custEnv?.appVersions?.metricsProxy)}
                  </Typography>
                )}
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  Orkes Agent:{" "}
                  {resolveVersion(props.custEnv?.appVersions?.agent)}
                </Typography>
              </div>
            </Box>
            <Box
              style={{
                display: "flex",
                width: 200,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                Created:
              </div>

              <div
                className={"paperOrgHeader"}
                style={{ fontSize: bottomSectionFontSize + 1, opacity: 0.8 }}
              >
                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  {userData && userData[props.custEnv?.createdBy]
                    ? userData[props.custEnv?.createdBy]?.userEmail
                    : "Unknown"}
                </Typography>

                <Typography
                  style={{
                    fontSize: 16,
                    lineHeight: 1.7,
                  }}
                >
                  {props.custEnv?.createdAt
                    ? new Date(props.custEnv?.createdAt).toLocaleString()
                    : "N/A"}
                </Typography>
              </div>
            </Box>
          </Box>
          <Divider />
        </>
      )}
      <Box
        style={{ display: "flex", justifyContent: "start", flexWrap: "wrap" }}
        className={"clusterBottom"}
      >
        <Box
          style={{
            display: "flex",
            width: 140,
            paddingLeft: 30,
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            className={"paperOrgHeader"}
            style={{
              fontSize: bottomSectionFontSize - 2,
              marginTop: 10,
              opacity: 0.6,
            }}
          >
            Cloud Provider
          </div>
          <div
            className={"paperOrgHeader"}
            style={{ fontSize: bottomSectionFontSize, opacity: 0.8 }}
          >
            {props.custEnv?.cloudProvider}
          </div>
        </Box>
        <Box
          style={{
            display: "flex",
            width: 140,
            paddingLeft: 30,
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            className={"paperOrgHeader"}
            style={{
              fontSize: bottomSectionFontSize - 2,
              marginTop: 10,
              opacity: 0.6,
            }}
          >
            Region
          </div>
          <div
            className={"paperOrgHeader"}
            style={{ fontSize: bottomSectionFontSize, opacity: 0.8 }}
          >
            {props.custEnv?.region}
          </div>
        </Box>
        {props.custEnv?.azIds && (!props.custEnv?.orkesAccount || isOrkesUser) && (
          <Box
            style={{
              display: "flex",
              width: 150,
              paddingLeft: 30,
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              className={"paperOrgHeader"}
              style={{
                fontSize: bottomSectionFontSize - 2,
                marginTop: 10,
                opacity: 0.6,
              }}
            >
              Availability Zones
            </div>
            <div
              className={"paperOrgHeader"}
              style={{ fontSize: bottomSectionFontSize, opacity: 0.8 }}
            >
              {props.custEnv.azIds?.map((azId) => (
                <div key={`${props.custEnv?.legacyId}-${azId}`}>{azId}</div>
              ))}
            </div>
          </Box>
        )}

        {props.custEnv?.accountId &&
          (!props.custEnv?.orkesAccount || isOrkesUser) && (
            <Box
              style={{
                display: "flex",
                width: 150,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize - 2,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                Account Id
              </div>
              <div
                className={"paperOrgHeader"}
                style={{ fontSize: bottomSectionFontSize, opacity: 0.8 }}
              >
                <div>{props.custEnv?.accountId}</div>
              </div>
            </Box>
          )}

        {(props.custEnv?.vpcId || props.custEnv?.vpcCidr) &&
          (!props.custEnv?.orkesAccount || isOrkesUser) && (
            <Box
              style={{
                display: "flex",
                width: 220,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize - 2,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                VPC
              </div>
              <div
                className={"paperOrgHeader"}
                style={{ fontSize: bottomSectionFontSize, opacity: 0.8 }}
              >
                <div>{props.custEnv?.vpcId}</div>
                <div>CIDR: {props.custEnv?.vpcCidr}</div>
              </div>
            </Box>
          )}

        {props.custEnv?.eksVersion &&
          (!props.custEnv?.orkesAccount || isOrkesUser) && (
            <Box
              style={{
                display: "flex",
                width: 100,
                paddingLeft: 30,
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                className={"paperOrgHeader"}
                style={{
                  fontSize: bottomSectionFontSize - 2,
                  marginTop: 10,
                  opacity: 0.6,
                }}
              >
                EKS
              </div>
              <div
                className={"paperOrgHeader"}
                style={{ fontSize: bottomSectionFontSize, opacity: 0.8 }}
              >
                <div>{props.custEnv?.eksVersion}</div>
              </div>
            </Box>
          )}
      </Box>
    </Paper>
  );
}
