import "../css/ClusterListings.css";
import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import { Helmet } from "react-helmet";
import { ChangeEvent, useState } from "react";
import { BootstrapOutlineButton } from "components/buttons/CustomButtons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  AzureScriptDto,
  CustomerCftDto,
  CustomerEnvironment,
  EnvironmentTypeOption,
  environmentTypeOptions as environmentTypes,
  environmentTypeOptions,
  ProvisionRequest,
} from "../utils/saastypes";
import LinearProgress from "../components/LinearProgress";
import AuthorizeAccountDialog from "../components/dialogs/AuthorizeAccountDialog";
import { IdTitle, useActionWithPath } from "utils/query";
import {
  getEnvironmentIcon,
  getValueForAutocomplete,
  isValidClusterName,
} from "utils/utils";
import ClusterField from "../components/ClusterField";
import StyledMainButton from "../components/buttons/StyledMainButton";

import awsLogo from "../images/aws-logo.svg";
import azureLogo from "../images/azure-logo.svg";
import gcloudLogo from "../images/gcloud-logo.svg";
import LogoSelector from "../components/LogoSelector";
import { useAuth } from "auth/AuthContext";
import { MAX_CLUSTER_NAME_SIZE } from "constants/common";
import AzureAuthorizeAccountDialog from "components/dialogs/AzureAuthorizeAccountDialog";
import { green } from "@mui/material/colors";

const validateClusterName = (clusterName: string | undefined) => {
  if (!clusterName) {
    return "Cluster name is required";
  } else if (!isValidClusterName(clusterName)) {
    return clusterName.startsWith("-")
      ? "Cluster name cannot start with a hyphen"
      : "Cluster name cannot contain special characters. Only alphanumeric and hyphen allowed";
  }
  return "";
};

const validateDataForCft = (
  provisionRequest: ProvisionRequest,
  errors: any,
  setErrors: any
): boolean => {
  const newErrors: any = {};
  if (
    undefined === provisionRequest.customerAccountId ||
    provisionRequest.customerAccountId?.trim() === ""
  ) {
    newErrors["customerAccountId"] = "Cloud provider account id cannot be null";
  }
  const clusterNameError = validateClusterName(provisionRequest.clusterName);
  if (clusterNameError) {
    newErrors["clusterName"] = clusterNameError;
  }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const validateDataForAzure = (
  provisionRequest: ProvisionRequest,
  errors: any,
  setErrors: any
): boolean => {
  const newErrors: any = {};
  if (
    undefined === provisionRequest.azureResourceGroup ||
    provisionRequest.azureResourceGroup?.trim() === ""
  ) {
    newErrors["azureResourceGroup"] = "Azure resource group cannot be null";
  }
  const clusterNameError = validateClusterName(provisionRequest.clusterName);
  if (clusterNameError) {
    newErrors["clusterName"] = clusterNameError;
  }
  setErrors(newErrors);
  return Object.keys(newErrors).length === 0;
};

const azureSupportedRegions: IdTitle[] = [
  { id: "eastus", title: "East US" },
  { id: "southcentralus", title: "South Central US" },
  { id: "westus3", title: "West US 3" },
  { id: "australiaeast", title: "Australia East (Sydney)" },
  { id: "northeurope", title: "North Europe" },
  { id: "westeurope", title: "West Europe" },
];

export default function CreateNewEnvironmentCustomCluster() {
  const navigate = useNavigate();
  const { setErrorToast } = useAuth();

  const [provisionRequest, setProvisionRequest] = useState<ProvisionRequest>(
    new ProvisionRequest("AWS")
  );
  const [errors, setErrors] = useState<any>({});
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [cftData, setCftData] = useState<CustomerCftDto>({});
  const [azureScriptData, setAzureScriptData] = useState<AzureScriptDto>({});
  const [clusterNameCheckTimeout, setClusterNameCheckTimeout] =
    useState<NodeJS.Timeout | null>();

  const [environmentType, setEnvironmentType] = useState<EnvironmentTypeOption>(
    environmentTypes[0]
  );

  const checkClusterNameAction = useActionWithPath({
    onSuccess: async (data: boolean) => {
      if (data) {
        setErrors({
          ...errors,
          ...{ clusterName: "Error: Cluster with that name already exists" },
        });
      }
    },
  });

  const handleCloudProviderChange = (cloudProvider: string) => {
    setProvisionRequest({
      ...provisionRequest,
      cloudProvider,
    });
  };

  const handleChangeData = (
    e: any,
    value?: any,
    reason?: string,
    id?: string
  ) => {
    if (id) {
      setProvisionRequest({
        ...provisionRequest,
        [id]: value,
      });
    } else {
      if (e.target.name === "customerAccountId") {
        if (e.target.value !== "") {
          let newErrors = { ...errors };
          delete newErrors.customerAccountId;
          setErrors(newErrors);
        } else {
          setErrors({
            ...errors,
            ...{
              customerAccountId: "Cloud provider account id cannot be null",
            },
          });
        }
      } else {
        let newErrors = { ...errors };
        delete newErrors.clusterName;
        delete newErrors.azureResourceGroup;
        setErrors(newErrors);
        if (clusterNameCheckTimeout) {
          clearTimeout(clusterNameCheckTimeout);
        }
        setClusterNameCheckTimeout(
          setTimeout(() => {
            // @ts-ignore
            checkClusterNameAction.mutate({
              method: "get",
              path: `/clusterNameExists?clusterName=${e.target.value}`,
            });
          }, 500)
        );
      }
      setProvisionRequest({
        ...provisionRequest,
        [e.target.name]: e.target.value,
      });
    }
  };

  const createCftAction = useActionWithPath({
    onSuccess: (data: CustomerCftDto) => {
      setCftData(data);
      console.log("CFT generation successful");
    },
    onError: async (response: any) => {
      console.error(response);
    },
  });

  const createAzureScriptAction = useActionWithPath({
    onSuccess: (data: AzureScriptDto) => {
      setAzureScriptData(data);
      console.log("Azure script generation successful");
    },
    onError: async (response: any) => {
      console.error(response);
    },
  });

  const verifyAuthAction = useActionWithPath({
    onSuccess: () => {
      console.log("Verify auth is successful");
      navigate(
        `/home/newcustomcluster-step2?accountId=${
          provisionRequest.customerAccountId
        }&clusterName=${provisionRequest.clusterName?.toLowerCase()}&cloudProvider=${
          provisionRequest.cloudProvider
        }`
      );
    },
  });

  const verifyAuthActionAzure = useActionWithPath({
    onSuccess: () => {
      setProvisionRequest({
        ...provisionRequest,
        azureCredentialsValidated: true,
      });
    },
  });

  function createCft() {
    if (validateDataForCft(provisionRequest, errors, setErrors)) {
      // @ts-ignore
      createCftAction.mutate({
        method: "get",
        path: `/generateOrkesRoleCft?clusterName=${provisionRequest.clusterName?.toLowerCase()}`,
      });
    }
  }

  function createAzureScript() {
    if (validateDataForAzure(provisionRequest, errors, setErrors)) {
      // @ts-ignore
      createAzureScriptAction.mutate({
        method: "get",
        path: `/generateOrkesAzureRoleScript?resourceGroupName=${provisionRequest.azureResourceGroup?.toLowerCase()}&clusterName=${provisionRequest.clusterName?.toLowerCase()}`,
      });
    }
  }

  const openAuthDialog = () => {
    if (validateDataForCft(provisionRequest, errors, setErrors)) {
      setAuthDialogOpen(true);
      createCft();
    }
  };

  const openAzureAuthDialog = () => {
    if (validateDataForAzure(provisionRequest, errors, setErrors)) {
      createAzureScript();
      setAuthDialogOpen(true);
    }
  };

  function triggerProvisioningFlow() {
    const clusNameLc = provisionRequest.clusterName?.toLocaleLowerCase();
    const ingressType = provisionRequest.ingressType
      ? provisionRequest.ingressType
      : "EXTERNAL";
    // TODO: Hard coded compute units, need to change
    const newProvReq = {
      ...provisionRequest,
      clusterName: clusNameLc,
      ingressType: ingressType,
      customerAccountId: "azure",
      environmentType: environmentType.id,
      computeUnits: 1,
      domainUrl: "generate.orkesconductor.io",
    };
    // @ts-ignore
    provisionEnvironmentAction.mutate({
      method: "post",
      path: `/provisionEnvironment`,
      body: JSON.stringify(newProvReq),
      successMessage: `Cluster ${clusNameLc} has been scheduled for provisioning. Redirecting you to monitor provisioning.`,
    });
  }

  const provisionEnvironmentAction = useActionWithPath({
    onSuccess: (data: CustomerEnvironment) => {
      setTimeout(() => {
        navigate(
          `/home/clusterprogess?clusterName=${data.name}&showSucess=true`
        );
      }, 3000);
    },
  });

  const closeDialogAndVerify = (verify: boolean) => {
    if (authDialogOpen) {
      setAuthDialogOpen(false);
    }
    if (verify) {
      if (validateDataForCft(provisionRequest, errors, setErrors)) {
        // @ts-ignore
        verifyAuthAction.mutate({
          method: "get",
          path: `/getRegions?clusterName=${provisionRequest.clusterName?.toLowerCase()}&customerAccountId=${
            provisionRequest.customerAccountId
          }`,
          errorMessage:
            "Error verifying authorization. Please check that the CFT stack has been created successfully and try again.",
        });
      }
    }
  };

  const closeDialogAndVerifyAzure = (verify: boolean) => {
    if (authDialogOpen) {
      setAuthDialogOpen(false);
    }
    if (verify) {
      if (validateDataForAzure(provisionRequest, errors, setErrors)) {
        // @ts-ignore
        verifyAuthActionAzure.mutate({
          method: "post",
          path: `/verifyAzureConnectivity`,
          body: JSON.stringify(provisionRequest),
          errorMessage:
            "Error verifying authorization. Please check that the script has run successfully and credentials are correct and try again.",
        });
      }
    }
  };

  function getAWSSection() {
    return (
      <>
        <Grid container spacing={2} style={{ width: 600 }}>
          <ClusterField
            id={"clusterName"}
            inputProps={{ maxLength: MAX_CLUSTER_NAME_SIZE }}
            onChange={(e) => handleChangeData(e)}
            label={"Cluster Name"}
            type={"text"}
            error={errors["clusterName"] !== undefined}
            ddWidth={300}
            helperText={
              errors?.clusterName
                ? errors.clusterName
                : `Enter a name for your cluster (Max ${MAX_CLUSTER_NAME_SIZE} chars, only alphabets and hyphens are allowed).`
            }
          />
          <ClusterField
            id={"customerAccountId"}
            onChange={(e) => handleChangeData(e)}
            error={errors["customerAccountId"] !== undefined}
            label={"AWS Account Id"}
            type={"text"}
            ddWidth={300}
            helperText={
              "Enter the account id of your AWS cloud account. You can find this in your cloud console."
            }
          />
        </Grid>

        <p className={"headerValue"} style={{ paddingTop: 20, fontSize: 14 }}>
          Before continuing to the next step, we need to authorize access to
          your cloud account. Authorizing access to this app will allow the app
          to create and provision the required assets in your cloud account.
        </p>

        <StyledMainButton
          disabled={Object.keys(errors).length !== 0}
          size="small"
          color={"inherit"}
          style={{
            width: 250,
            fontSize: 14,
            marginRight: 30,
            marginTop: 15,
          }}
          onClick={() => {
            openAuthDialog();
          }}
        >
          Authorize Orkes
        </StyledMainButton>

        <p className={"headerValue"} style={{ paddingTop: 30, fontSize: 14 }}>
          If you have already created a stack, you can continue with the
          verification.
        </p>
        <StyledMainButton
          size="small"
          color={"inherit"}
          style={{
            width: 250,
            fontSize: 14,
            marginRight: 10,
            marginTop: 10,
          }}
          onClick={() => {
            closeDialogAndVerify(true);
          }}
        >
          Verify Authorization & Continue
        </StyledMainButton>
      </>
    );
  }

  function getAzureSection() {
    return (
      <>
        <Grid container spacing={2} style={{ width: 600 }}>
          <ClusterField
            id={"clusterName"}
            inputProps={{ maxLength: MAX_CLUSTER_NAME_SIZE }}
            onChange={(e) => handleChangeData(e)}
            label={"Cluster Name"}
            labelValue={provisionRequest.clusterName}
            type={provisionRequest.azureCredentialsValidated ? "label" : "text"}
            error={errors["clusterName"] !== undefined}
            ddWidth={300}
            helperText={
              errors?.clusterName
                ? errors.clusterName
                : `Enter a name for your cluster (Max ${MAX_CLUSTER_NAME_SIZE} chars, only alphabets and hyphens are allowed).`
            }
          />
          <ClusterField
            id={"azureResourceGroup"}
            onChange={(e) => handleChangeData(e)}
            error={errors["azureResourceGroup"] !== undefined}
            label={"Azure Resource Group Name"}
            labelValue={provisionRequest.azureResourceGroup}
            type={provisionRequest.azureCredentialsValidated ? "label" : "text"}
            ddWidth={300}
            helperText={"Enter the name of your azure resource group."}
          />
          <ClusterField
            id={"region"}
            onChange={(e, value, reason) =>
              handleChangeData(
                e,
                getValueForAutocomplete(value),
                reason,
                "region"
              )
            }
            error={errors["region"] !== undefined}
            ddWidth={300}
            items={azureSupportedRegions ? azureSupportedRegions : []}
            label={"Azure Region"}
            labelValue={provisionRequest.region}
            type={
              provisionRequest.azureCredentialsValidated ? "label" : "select"
            }
            helperText={
              "Enter the region where you want to provision this cluster."
            }
          />
        </Grid>

        {provisionRequest.azureCredentialsValidated !== true && (
          <>
            <p
              className={"headerValue"}
              style={{ paddingTop: 20, fontSize: 14 }}
            >
              Before continuing to the next step, we need to authorize access to
              your cloud account. Authorizing access to this app will allow the
              app to create and provision the required assets in your cloud
              account.
            </p>

            <p
              className={"headerValue"}
              style={{ paddingTop: 20, fontSize: 14 }}
            >
              To authorize - we will generate a script that the administrator of
              your cloud account needs to run. This requires AZ CLI. In this
              script, we will create the applications, service principals, roles
              and role assignments required for cluster provisioning. The script
              will print credentials for the service principal, which is
              required in this request form to continue cluster provisioning.
            </p>

            <StyledMainButton
              disabled={
                Object.keys(errors).length !== 0 ||
                verifyAuthActionAzure.isLoading ||
                createAzureScriptAction.isLoading
              }
              size="small"
              color={"inherit"}
              style={{
                width: 250,
                fontSize: 14,
                marginRight: 30,
                marginTop: 15,
              }}
              onClick={() => {
                openAzureAuthDialog();
              }}
            >
              Generate Script
            </StyledMainButton>

            <p
              className={"headerValue"}
              style={{ paddingTop: 30, fontSize: 14 }}
            >
              If you have already run the script and have gathered the
              credentials, you can continue with the verification.
            </p>

            <ClusterField
              id={"azureSubscriptionId"}
              onChange={(e) => handleChangeData(e)}
              error={errors["azureSubscriptionId"] !== undefined}
              label={"Azure Subscription Id"}
              type={"text"}
              ddWidth={400}
              helperText={
                "Enter the subscription id where the resource group exists."
              }
            />
            <ClusterField
              id={"azureServicePrincipalApplicationId"}
              onChange={(e) => handleChangeData(e)}
              error={errors["azureApplicationId"] !== undefined}
              label={"Azure Service Principal Application Id"}
              type={"text"}
              ddWidth={400}
              helperText={"Enter the application id for the service principal."}
            />
            <ClusterField
              id={"azureServicePrincipalPassword"}
              onChange={(e) => handleChangeData(e)}
              error={errors["azureTenantId"] !== undefined}
              label={"Azure Service Principal Password"}
              type={"password"}
              ddWidth={400}
              helperText={"Enter the tenant id for the service principal."}
            />
            <ClusterField
              id={"azureTenantId"}
              onChange={(e) => handleChangeData(e)}
              error={errors["azureTenantId"] !== undefined}
              label={"Azure Tenant Id"}
              type={"text"}
              ddWidth={400}
              helperText={"Enter the tenant id for the service principal."}
            />

            <StyledMainButton
              size="small"
              color={"inherit"}
              disabled={verifyAuthActionAzure.isLoading}
              style={{
                width: 250,
                fontSize: 14,
                marginRight: 10,
                marginTop: 10,
              }}
              onClick={() => {
                closeDialogAndVerifyAzure(true);
              }}
            >
              {verifyAuthActionAzure.isLoading && (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                  <span>Verifying...</span>
                </>
              )}
              {!verifyAuthActionAzure.isLoading &&
                "Verify Authorization & Continue"}
            </StyledMainButton>
          </>
        )}

        {provisionRequest.azureCredentialsValidated === true && (
          <>
            <ClusterField
              id={"azureVnetName"}
              onChange={(e) => handleChangeData(e)}
              error={errors["azureVnetName"] !== undefined}
              label={"VNet Name?"}
              type={"text"}
              ddWidth={400}
              helperText={"Enter VNet name"}
            />
            <ClusterField
              id={"azureVnetCidr"}
              onChange={(e) => handleChangeData(e)}
              error={errors["azureVnetCidr"] !== undefined}
              label={"VNet CIDR?"}
              type={"text"}
              ddWidth={400}
              helperText={"Enter VNet CIDR (min /20 - max /16)"}
            />
            <ClusterField
              id={"environmentType"}
              onChange={(
                e: ChangeEvent<HTMLInputElement>,
                value: EnvironmentTypeOption
              ) => {
                setEnvironmentType(value);
              }}
              error={errors["environmentType"] !== undefined}
              ddWidth={400}
              items={environmentTypeOptions}
              value={environmentType}
              label={"Environment Type"}
              type={"select"}
              helperText={
                "Tag your cluster's environment type. It is helpful for distinguishing between multiple instances."
              }
              showDropdownIcons={true}
              iconHandler={getEnvironmentIcon}
            />

            <p
              className={"headerValue"}
              style={{ paddingTop: 20, fontSize: 14 }}
            >
              Once you have provided / selected all the required info, you can
              click the button below to start the cluster provisioning. Most
              provisioning flows will complete within an hour and we can monitor
              progress.
            </p>

            <StyledMainButton
              size="small"
              color={"inherit"}
              disabled={provisionEnvironmentAction.isLoading}
              style={{
                width: 250,
                fontSize: 15,
                marginRight: 10,
                marginTop: 10,
              }}
              onClick={() => {
                triggerProvisioningFlow();
              }}
            >
              {provisionEnvironmentAction.isLoading && (
                <>
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                  <span>Creating request...</span>
                </>
              )}
              {!provisionEnvironmentAction.isLoading &&
                "Create Provisioning Workflow"}
            </StyledMainButton>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Create New Custom Cluster</title>
      </Helmet>
      {(createCftAction.isLoading ||
        verifyAuthAction.isLoading ||
        verifyAuthActionAzure.isLoading) && <LinearProgress />}

      {authDialogOpen && provisionRequest.cloudProvider === "AWS" && (
        <AuthorizeAccountDialog
          cftData={cftData}
          handleClose={() => closeDialogAndVerify(false)}
          verifyMethod={() => closeDialogAndVerify(true)}
          progressPending={createCftAction.isLoading}
        />
      )}
      {authDialogOpen && provisionRequest.cloudProvider === "AZURE" && (
        <AzureAuthorizeAccountDialog
          azureScriptData={azureScriptData}
          handleClose={() => closeDialogAndVerify(false)}
          verifyMethod={() => closeDialogAndVerify(true)}
          progressPending={createAzureScriptAction.isLoading}
        />
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          borderBottom: "1px solid rgb(200,200,200, 0.5)",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "column",
            paddingLeft: 25,
            paddingRight: 0,
            paddingTop: 5,
          }}
        >
          <h2 style={{ marginBottom: 15 }}>Create New Custom Cluster</h2>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "left",
            flexDirection: "row",
            paddingRight: 20,
            paddingTop: 5,
          }}
        >
          <BootstrapOutlineButton
            color={"inherit"}
            style={{
              width: 100,
              fontSize: 15,
              lineHeight: "27px",
              marginRight: 10,
              fontFamily: "Lexend, sans-serif",
              fontWeight: 800,
              textAlign: "center",
              borderRadius: 5,
            }}
            //@ts-ignore
            component={Link}
            to={"/home/clusters"}
          >
            Cancel
          </BootstrapOutlineButton>
        </Box>
      </Box>

      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: "column",
          width: "100%",
          // borderRight: "2px solid lightgrey",
        }}
      >
        <Paper
          style={{
            marginTop: 30,
            marginLeft: 30,
            marginRight: 30,
            padding: 20,
            fontFamily: "Lexend, sans-serif",
            minWidth: 400,
            minHeight: 300,
            maxWidth: 900,
          }}
        >
          <p
            style={{
              fontSize: 18,
              marginTop: 0,
              fontFamily: "Lexend, sans-serif",
              fontWeight: 600,
            }}
          >
            Custom Cluster in your Cloud Account
          </p>
          <Grid container spacing={2} style={{ width: 600 }}>
            <Box style={{ display: "flex", margin: 16 }}>
              <LogoSelector
                isActive={provisionRequest.cloudProvider === "AWS"}
                src={awsLogo}
                isEnabled={true}
                onClick={() => handleCloudProviderChange("AWS")}
              />
              <LogoSelector
                isActive={provisionRequest.cloudProvider === "AZURE"}
                src={azureLogo}
                isEnabled={true}
                onClick={() => handleCloudProviderChange("AZURE")}
              />
              <LogoSelector
                isActive={provisionRequest.cloudProvider === "GCP"}
                src={gcloudLogo}
                onClick={() => {
                  setErrorToast("Google Cloud is currently not supported.");
                }}
                isEnabled={false}
              />
            </Box>
          </Grid>
          {provisionRequest.cloudProvider === "AWS" && getAWSSection()}
          {provisionRequest.cloudProvider === "AZURE" && getAzureSection()}
        </Paper>
      </Box>
    </>
  );
}
