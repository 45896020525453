import "../css/ClusterListings.css";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router";
import ClusterField from "../components/ClusterField";
import StyledMainButton from "../components/buttons/StyledMainButton";
import { useAuth } from "auth/AuthContext";
import LogoSelector from "../components/LogoSelector";

import awsLogo from "../images/aws-logo.svg";
import azureLogo from "../images/azure-logo.svg";
import gcloudLogo from "../images/gcloud-logo.svg";

import flagUsa from "../images/flag-usa.svg";
import flagIreland from "../images/flag-ireland.svg";
import flagAustralia from "../images/flag-australia.svg";
import flagIndia from "../images/flag-india.svg";
import flagUae from "../images/flag-uae.svg";
import flagSingapore from "../images/flag-singapore.png";
import flagUk from "../images/flag-uk.svg";
import flagFrance from "../images/flag-france.png";
import flagGermany from "../images/flag-germany.svg";
import { useActionWithPath, useCloudRegion } from "utils/query";
import {
  getAPIErrorMessage,
  getClusterCapacityIcon,
  getEnvironmentIcon,
  isValidClusterName,
} from "utils/utils";
import { MAX_CLUSTER_NAME_SIZE } from "constants/common";
import _noop from "lodash/noop";
import {
  CloudProvider,
  ClusterCapacityType,
  EnvironmentTypeOption,
  environmentTypeOptions as environmentTypes,
  Region,
  SubRegion,
} from "utils/saastypes";
import AlertDialog from "components/AlertDialog";
import { ProviderLogo } from "components/ClusterDetailCard/common";
import StarterClusterIcon from "images/svg/chip/starter-cloud-cluster.svg";
import StandardClusterIcon from "images/svg/chip/standard-cloud-cluster.svg";
import { useAuthMachine } from "auth/state/hook";

const styles = {
  countryHeader: {
    color: "white",
    background: "#444",
    borderRadius: "10px",
    padding: "8px",
  },
  countryColumn: {
    width: "250px",
    marginRight: "20px",
  },
  countrySelector: {
    padding: "15px 5px",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #eee",
    marginBottom: "8px",
    marginTop: "8px",
    "&:hover": {
      border: "1px solid lightgrey",
    },
  },
  countrySelectorActive: {
    padding: "15px 5px",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid #1976d2",
    marginBottom: "8px",
    marginTop: "8px",
  },
  countrySelectorDisabled: {
    padding: "15px 5px",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "10px",
    border: "1px solid rgb(200 200 200)",
    marginBottom: "8px",
    marginTop: "8px",
    color: "#999999",
    backgroundColor: "rgb(236 236 236)",
  },
};

const clusterCapacities = [
  {
    id: "99",
    title: "Starter Edition",
    type: ClusterCapacityType.STARTER,
  },
  {
    id: "1",
    title: "Standard Edition",
    type: ClusterCapacityType.STANDARD,
  },
];

const countryFlag = {
  usa: flagUsa,
  ireland: flagIreland,
  australia: flagAustralia,
  singapore: flagSingapore,
  france: flagFrance,
  uk: flagUk,
  india: flagIndia,
  uae: flagUae,
  germany: flagGermany,
};

type RegionToCountryMap = {
  [regionId: string]: keyof typeof countryFlag;
};

const getCountryFlagByRegionId = (regionId: string) => {
  const regionToCountry: RegionToCountryMap = {
    "us-east1": "usa",
    eastus: "usa",
    southcentralus: "usa",
    "us-east-1": "usa",
    centralus: "usa",
    westus2: "usa",
    "us-west1": "usa",
    "us-west-2": "usa",
    "eu-west-1": "ireland",
    "eu-west-2": "uk",
    "eu-west-3": "france",
    "ap-south-1": "india",
    "ap-southeast-1": "singapore",
    "ap-southeast-2": "australia",
    australiaeast: "australia",
    "me-central-1": "uae",
    "europe-west3": "germany",
  };

  return countryFlag[regionToCountry[regionId]] || "";
};

const DEFAULT_REGION = {
  [CloudProvider.AWS]: "us-east-1",
  [CloudProvider.AZURE]: "eastus",
  [CloudProvider.GCP]: "us-east1",
};

const CountrySelector = ({
  country = "usa",
  isActive = false,
  enabled = true,
  onClick = () => {},
  children,
}: {
  country?: keyof typeof countryFlag;
  isActive?: boolean;
  enabled?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}) => {
  return (
    <Box
      onClick={enabled ? onClick : _noop}
      sx={
        !enabled
          ? styles.countrySelectorDisabled
          : isActive
          ? styles.countrySelectorActive
          : styles.countrySelector
      }
    >
      {country && (
        <img
          style={{ marginRight: 8, width: 32 }}
          alt={country}
          src={countryFlag[country]}
        />
      )}
      {children}
    </Box>
  );
};

const CountryRegionLoader = ({
  cloudProvider = CloudProvider.AWS,
  cloudRegion,
  setCloudRegion,
  isLoadingCloudRegion,
  cloudProviderMapping,
  checkValidRegion,
}: {
  cloudProvider?: CloudProvider;
  cloudRegion: string;
  setCloudRegion: (value: string) => void;
  isLoadingCloudRegion: boolean;
  cloudProviderMapping: { [key in CloudProvider]: Region[] } | null;
  checkValidRegion: (regions: Region[]) => boolean;
}) => {
  let cpMap = cloudProviderMapping?.[cloudProvider];
  let mapChildren: any[] = [];
  cpMap?.forEach((cpMapEntry) => {
    let mapChildrenRegions: any[] = [];
    cpMapEntry.subRegions.forEach((region: any) => {
      mapChildrenRegions.push(
        <CountrySelector
          key={region.regionId}
          onClick={() => {
            setCloudRegion(region.regionId);
          }}
          enabled={region.enabled}
          isActive={cloudRegion === region.regionId}
          country={region.country}
        >
          {region.name}
        </CountrySelector>
      );
    });
    mapChildren.push(
      <Box key={cpMapEntry.regionName} sx={styles.countryColumn}>
        <Typography sx={styles.countryHeader}>
          {cpMapEntry.regionName}
        </Typography>
        {mapChildrenRegions}
      </Box>
    );
  });

  useEffect(() => {
    if (cpMap && cloudProvider) {
      const currentDefaultRegionId = DEFAULT_REGION[cloudProvider];
      const subRegions = cpMap.reduce(
        (result, region) => [...result, ...region.subRegions],
        [] as SubRegion[]
      );
      let currentDefaultRegion: SubRegion | null = null;
      let defaultAvailableRegion: SubRegion | null = null;

      if (subRegions) {
        for (const subRegion of subRegions) {
          if (!defaultAvailableRegion && subRegion.enabled) {
            defaultAvailableRegion = subRegion;
          }

          if (currentDefaultRegionId === subRegion.regionId) {
            currentDefaultRegion = subRegion;
          }
        }
      }

      if (!checkValidRegion(cpMap) && defaultAvailableRegion?.regionId) {
        setCloudRegion(
          currentDefaultRegion?.enabled
            ? currentDefaultRegionId
            : defaultAvailableRegion?.regionId
        );
      }
    }
  }, [cpMap, cloudRegion, setCloudRegion, checkValidRegion, cloudProvider]);

  return (
    <>
      {isLoadingCloudRegion && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {mapChildren.length > 0 ? (
        <Box
          style={{
            display: "flex",
            marginBottom: 20,
          }}
        >
          {mapChildren}
        </Box>
      ) : null}
    </>
  );
};

export default function CreateNewEnvironmentOrkesCluster() {
  const { authService, setErrorToast, setSuccessToast } = useAuth();

  const [{ isOrkesUser, currentUser, currentOrganization }] =
    useAuthMachine(authService);

  const navigate = useNavigate();
  const { data: regions, isLoading: isLoadingCloudRegion } = useCloudRegion();

  const [clusterName, setClusterName] = useState("");
  const [cloudProvider, setCloudProvider] = useState(CloudProvider.AWS);
  const [cloudRegion, setCloudRegion] = useState(
    DEFAULT_REGION[CloudProvider.AWS]
  );
  const [clusterCapacity, setClusterCapacity] = useState(clusterCapacities[0]);
  const [environmentType, setEnvironmentType] = useState(environmentTypes[0]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  function Subtitle({ children }: { children: ReactNode }) {
    return (
      <p
        style={{
          fontSize: 18,
          marginTop: 16,
          marginBottom: 32,
          fontFamily: "Lexend, sans-serif",
          fontWeight: 600,
        }}
      >
        {children}
      </p>
    );
  }

  const cloudProviderMapping = useMemo(
    () => (clusterCapacity ? regions?.[clusterCapacity.type] : null),
    [regions, clusterCapacity]
  );

  const checkValidRegion = useCallback(
    (regions: Region[]) =>
      regions?.some((region) =>
        region?.subRegions?.some((sub) => sub.regionId === cloudRegion)
      ),
    [cloudRegion]
  );

  const provisionOrkesClusterAction = useActionWithPath({
    onSuccess: (data: any) => {
      setSuccessToast(
        `Cluster ${data.name} has been scheduled for provisioning. Redirecting you to monitor provisioning.`
      );
      setTimeout(() => {
        setIsSubmitting(false);
        navigate(
          `/home/clusterprogess?clusterName=${data.name}&showSucess=true`
        );
      }, 3000);
    },
    onError: async (response: any) => {
      setIsSubmitting(false);
      response.json().then((json: any) => {
        setErrorToast(`Error invoking request: ${getAPIErrorMessage(json)}`);
      });
    },
  });

  function submitNewOrkesCluster() {
    setIsSubmitting(true);
    // @ts-ignore
    provisionOrkesClusterAction.mutate({
      method: "post",
      path: `/provisionOrkesHostedCluster`,
      body: JSON.stringify({
        clusterName: clusterName,
        cloudProvider: cloudProvider,
        region: cloudRegion,
        computeUnits: clusterCapacity.id,
        environmentType: environmentType.id,
      }),
    });
  }

  const getClusterNameHelperText = (name: string) => {
    if (name.length > 0 && !isValidClusterName(name)) {
      if (name.startsWith("-")) {
        return "Cluster Name cannot start with a hyphen.";
      } else {
        return "Cluster name cannot contain special characters. Only alphanumeric and hyphen allowed.";
      }
    }
    return "";
  };

  const isDisabledContinue = useMemo(
    () =>
      isSubmitting ||
      clusterName === "" ||
      !isValidClusterName(clusterName) ||
      (!!cloudProviderMapping &&
        !checkValidRegion(cloudProviderMapping[cloudProvider])),
    [
      isSubmitting,
      clusterName,
      cloudProviderMapping,
      cloudProvider,
      checkValidRegion,
    ]
  );

  const environmentTypeOptions = useMemo(() => {
    const result = [...environmentTypes];

    if (!isOrkesUser) {
      result.pop();
    }

    return result;
  }, [isOrkesUser]);

  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Create New Orkes Cluster</title>
      </Helmet>
      <AlertDialog
        textTitle={"New Orkes Cluster"}
        confirmLabel="Start Provisioning"
        maxWidth="sm"
        confirmBtnStyle={{
          background: "#0000ffb3",
          color: "white",
          textTransform: "unset",
          "&:hover": {
            background: "#0000ffe6",
          },
        }}
        closeBtnStyle={{
          color: "#0000ffb3",
          border: "1px solid #0000ffb3",
          textTransform: "unset",
        }}
        textContent={
          <Grid container>
            <Grid item xs={4} fontWeight="600" color="#000000cc">
              {[
                "Organization",
                "Cluster Name",
                "Capacity",
                "Cloud Provider",
                "Region",
                "Default Admin User",
              ].map((label, index) => (
                <Box key={index} my={1}>
                  {label}
                </Box>
              ))}
            </Grid>
            <Grid item xs={8} color="#000000b3">
              <Box my={1}>{currentOrganization?.name}</Box>
              <Box>{clusterName}</Box>
              <Box sx={{ display: "flex", alignItems: "center" }} my={1}>
                {clusterCapacity?.title}{" "}
                <img
                  style={{ height: "25px", width: "unset", marginLeft: 4 }}
                  alt="O"
                  src={
                    clusterCapacity?.title === "Starter Edition"
                      ? StarterClusterIcon
                      : StandardClusterIcon
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {cloudProvider}{" "}
                <Typography
                  component="span"
                  sx={{
                    width: 25,
                    height: 16,
                    ml: 1,
                    mt: -2,
                    svg: { height: "25px", width: "25px" },
                  }}
                >
                  <ProviderLogo provider={cloudProvider} />
                </Typography>
              </Box>
              <Box my={1}>
                {cloudRegion}{" "}
                <img src={getCountryFlagByRegionId(cloudRegion)} alt="" />
              </Box>
              <Box>{currentUser?.displayName || currentUser?.email}</Box>
            </Grid>
          </Grid>
        }
        open={showConfirmAlert}
        setOpen={() => setShowConfirmAlert(false)}
        handleConfirm={submitNewOrkesCluster}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Paper
          sx={{
            marginTop: "29px",
            marginLeft: "30px",
            marginRight: "30px",
            padding: "20px",
            minWidth: "400px",
            minHeight: "300px",
            maxWidth: "900px",
          }}
        >
          <Typography variant="h2" sx={{ marginBottom: "15px" }}>
            Create New Orkes Cluster
          </Typography>
          <Subtitle>Cloud provider</Subtitle>
          <Box
            sx={{
              display: "flex",
              marginBottom: "20px",
            }}
          >
            <LogoSelector
              isActive={cloudProvider === CloudProvider.AWS}
              onClick={() => setCloudProvider(CloudProvider.AWS)}
              src={awsLogo}
            />
            <LogoSelector
              isActive={cloudProvider === CloudProvider.AZURE}
              src={azureLogo}
              onClick={() => setCloudProvider(CloudProvider.AZURE)}
            />
            <LogoSelector
              isActive={cloudProvider === CloudProvider.GCP}
              src={gcloudLogo}
              onClick={() => {
                setCloudProvider(CloudProvider.GCP);
              }}
            />
          </Box>
          <Subtitle>Cloud Region</Subtitle>
          <CountryRegionLoader
            cloudProvider={cloudProvider}
            cloudRegion={cloudRegion}
            setCloudRegion={setCloudRegion}
            checkValidRegion={checkValidRegion}
            cloudProviderMapping={cloudProviderMapping}
            isLoadingCloudRegion={isLoadingCloudRegion}
          />
          <Subtitle>Cluster properties</Subtitle>
          <Grid container spacing={2} style={{ width: 600 }}>
            <ClusterField
              id={"clusterName"}
              ddWidth={300}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setClusterName(e.target.value);
              }}
              value={clusterName}
              label={"Cluster Name"}
              type={"text"}
              inputProps={{
                maxLength: MAX_CLUSTER_NAME_SIZE,
              }}
              error={!isValidClusterName(clusterName) && clusterName.length > 0}
              helperText={getClusterNameHelperText(clusterName)}
            />
            <ClusterField
              id={"clusterCapacity"}
              ddWidth={300}
              onChange={(
                e: ChangeEvent<HTMLInputElement>,
                value: typeof clusterCapacities[0]
              ) => {
                setClusterCapacity(value);
              }}
              items={clusterCapacities}
              label={"Cluster Capacity"}
              value={clusterCapacity}
              type={"select"}
              showDropdownIcons={true}
              iconHandler={getClusterCapacityIcon}
            />
            <ClusterField
              id={"environmentType"}
              ddWidth={300}
              onChange={(
                e: ChangeEvent<HTMLInputElement>,
                value: EnvironmentTypeOption
              ) => {
                setEnvironmentType(value);
              }}
              items={environmentTypeOptions}
              label={"Environment Type"}
              value={environmentType}
              type={"select"}
              showDropdownIcons={true}
              iconHandler={getEnvironmentIcon}
            />
          </Grid>

          <Box style={{ display: "flex", marginTop: 32 }}>
            <StyledMainButton
              onClick={() => setShowConfirmAlert(true)}
              size="small"
              color={"inherit"}
              disabled={isDisabledContinue}
              style={{
                width: 250,
                fontSize: 14,
                marginRight: 10,
              }}
            >
              {isSubmitting && (
                <CircularProgress size={24} style={{ marginRight: 8 }} />
              )}
              Continue
            </StyledMainButton>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
