import * as React from "react";
import { Fragment, useState } from "react";
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Avatar, Box, Chip, Paper, Tooltip, Typography } from "@mui/material";
import { StyledTab, StyledTabs } from "components/Tab";
import { useClusterDetail } from "utils/query";
import LinearProgress from "components/LinearProgress";
import { Message } from "components/SnackbarMessage";
import ActionsHistoryTab from "./ClusterDetailsTabs/ActionsHistoryTab";
import Heading from "components/Heading";
import { a11yProps, ColumnHeader } from "./ClusterDetailsTabs/TabPanel";
import DetailsTab from "./ClusterDetailsTabs/DetailsTab";
import InfoColumnTab from "./ClusterDetailsTabs/InfoColumnTab";
import PodsTab from "./ClusterDetailsTabs/PodsTab";
import SplitInfoColumnTab from "./ClusterDetailsTabs/SplitInfoColumnTab";
import ClipboardCopy from "../../components/ClipboardCopy";
import StatusTabNew from "./ClusterDetailsTabs/StatusTabNew";
import ApplicationPropertiesTab from "./ClusterDetailsTabs/ApplicationPropertiesTab";
import { CLUSTER_ACTION_TYPE, CLUSTER_STATE } from "constants/common";
import UrlsTab from "./ClusterDetailsTabs/UrlsTab";
import OutlinedButton from "components/buttons/OutlinedButton";
import { getColorFromState, getEnvironmentIcon } from "utils/utils";
import { HostingChip } from "components/chips/HostingChip";
import AuthenticationTab from "./ClusterDetailsTabs/AuthenticationTab";
import { IconFromState } from "components/ClusterDetailCard/common";
import { useAuth } from "auth/AuthContext";
import { MessageContext } from "utils/MessageProvider";
import ReleaseTab from "./ClusterDetailsTabs/ReleaseTab";
import { CLUSTER_DETAIL_URL, UNAUTHORIZED_ROUTE_URL } from "utils/routePaths";
import { CertificateTab } from "./ClusterDetailsTabs/CertificateTab";
import { useAuthMachine } from "auth/state/hook";
import ActionTypeSelector from "./ActionTypeSelector";
import { selectedTabIndex } from "./helpers";
import { clickableChipSx } from "components/ClusterDetailCard/common";

const sxChip = {
  fontSize: "14px",
  marginLeft: "16px",
  paddingTop: "2px",
  fontWeight: 400,
};

function isPlainObject(input: any) {
  return input && !Array.isArray(input) && typeof input === "object";
}

function formatData(data: any, propRenderers: any) {
  for (let key in data) {
    if (isPlainObject(data[key])) {
      Object.keys(data[key]).forEach((subKey) => {
        data[key + "." + subKey] = data[key][subKey];
      });
    }
  }
  let output = [];
  for (let key in propRenderers) {
    if (data[key]) {
      output.push({
        name: propRenderers[key].name,
        value: propRenderers[key].renderer
          ? propRenderers[key].renderer(data[key])
          : defaultRenderer(data[key]),
      });
    }
  }
  return output;
}

function defaultRenderer(val: Array<string> | string | boolean | undefined) {
  if (Array.isArray(val)) {
    return val.map((el, index) => (
      <Fragment key={index}>
        {el}
        <br />
      </Fragment>
    ));
  } else if (typeof val === "boolean") {
    return val ? <>true</> : <>false</>;
  } else {
    return val && val !== "undefined" ? <>{val}</> : <>-</>;
  }
}

const firstPageProps: { [key: string]: any } = {
  ingressType: { name: "Ingress Type" },
  vpcId: {
    name: "VPC ID",
    renderer: (val: string) => {
      return <ClipboardCopy value={val}>{val}</ClipboardCopy>;
    },
  },
  azIds: { name: "Availability Zones" },
  loadBalancerName: {
    name: "Load Balancer ID",
    renderer: (val: string) => {
      return <ClipboardCopy value={val}>{val}</ClipboardCopy>;
    },
  },
  vpcCidr: { name: "VPC CIDR" },
  subnetIds: { name: "Main Subnet IDs" },
  cloudEnvironmentTag: {
    name: "Cloud Environment Tag",
    renderer: (val: string) => {
      return <ClipboardCopy value={val}>{val}</ClipboardCopy>;
    },
  },
  ipRanges: {
    name: "",
    renderer: (val: string) => {
      let parts = val.split(" $$ ");
      return (
        <>
          <ColumnHeader>IP Range Start</ColumnHeader>
          <Typography>{parts[0]}</Typography>
          <ColumnHeader>IP Range End</ColumnHeader>
          <Typography>{parts[1]}</Typography>
        </>
      );
    },
  },
  utilitySubnetIds: { name: "Utility Subnet IDs" },
};

const secondPageLeftProps: { [key: string]: any } = {
  "elasticCacheConfig.engine": { name: "Engine" },
  "elasticCacheConfig.engineVersion": { name: "Engine Version" },
  "elasticCacheConfig.url": {
    name: "Engine Host",
    renderer: (val: string) => {
      return <ClipboardCopy value={val}>{val}</ClipboardCopy>;
    },
  },
  "elasticCacheConfig.port": { name: "Engine Port" },
  "elasticCacheConfig.replicationGroupId": { name: "Replication Group" },
  "elasticCacheConfig.subnetGroupId": { name: "Subnet Group ID" },
  "elasticCacheConfig.numberCacheClusters": {
    name: "Cache Nodes",
  },
  "elasticCacheConfig.nodeType": { name: "Node Type" },
};

const secondPageRightProps: { [key: string]: any } = {
  "rdsConfig.pgFamily": { name: "Postgres Family" },
  "rdsConfig.engineVersion": { name: "Database Type" },
  "rdsConfig.endpoint": {
    name: "Database Endpoint",
    renderer: (val: string) => {
      return <ClipboardCopy value={val}>{val}</ClipboardCopy>;
    },
  },
  "rdsConfig.pgName": {
    name: "Postgres Name",
    renderer: (val: string) => {
      return <ClipboardCopy value={val}>{val}</ClipboardCopy>;
    },
  },
};

export default function ClusterDetails() {
  const { clusterName, "*": splat } = useParams();
  const [, entryId] = splat?.split("/") || [];
  const [selectedActionType, setSelectedActionType] = useState<string>("");
  const [podName, setPodName] = useState("");
  const { pathname } = useLocation();
  const basePath = `${CLUSTER_DETAIL_URL.ROOT}/${clusterName}`;
  const relativePath = pathname.split(basePath)[1];
  const { setMessage } = React.useContext(MessageContext);

  const { authService } = useAuth();
  const [{ isOrkesUser }] = useAuthMachine(authService);

  const showError = (message: string) => {
    setMessage(new Message(message, "error"));
  };

  const showSuccess = (message: string) => {
    setMessage(new Message(message, "success"));
  };

  const {
    data: clusterDetail,
    isFetching: clusterDetailFetching,
    refetch: refetchClusterDetail,
  } = useClusterDetail(clusterName as string);

  if (clusterDetail && clusterDetail.ipRangeStart && clusterDetail.ipRangeEnd) {
    (
      clusterDetail as any
    ).ipRanges = `${clusterDetail.ipRangeStart} $$ ${clusterDetail.ipRangeEnd}`;
  }

  if (clusterDetail && clusterDetail.subnets) {
    clusterDetail.subnetIds = clusterDetail.subnets
      .filter((x) => x.type === "MAIN")
      .map((subnet) => subnet.awsId);
    clusterDetail.utilitySubnetIds = clusterDetail.subnets
      .filter((x) => x.type === "UTILITY")
      .map((subnet) => subnet.awsId);
  }

  const renderRoutes = () =>
    [
      {
        element: (
          <DetailsTab
            refetchClusterDetail={refetchClusterDetail}
            clusterDetail={clusterDetail}
            selectedTabIndex={0}
            index={0}
          />
        ),
      },
      {
        element: (
          <UrlsTab
            clusterDetail={clusterDetail}
            selectedTabIndex={1}
            index={1}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.URLS}`,
      },
      {
        element: (
          <StatusTabNew
            clusterDetail={clusterDetail}
            selectedTabIndex={2}
            index={2}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.STATUS}`,
      },
      {
        element: (
          <InfoColumnTab
            selectedTabIndex={3}
            index={3}
            dataPage={formatData(clusterDetail, firstPageProps)}
            nrColumns={3}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.NETWORK}`,
      },
      {
        element: (
          <SplitInfoColumnTab
            selectedTabIndex={4}
            index={4}
            dataPage={formatData(clusterDetail, secondPageLeftProps)}
            secondDataPage={formatData(clusterDetail, secondPageRightProps)}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.PERSISTENCE}`,
      },
      {
        element: (
          <ApplicationPropertiesTab
            selectedTabIndex={5}
            index={5}
            clusterDetail={clusterDetail}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.PROPERTIES}`,
      },
      {
        element: (
          <AuthenticationTab
            selectedTabIndex={5}
            index={5}
            clusterDetail={clusterDetail!}
            showError={showError}
            showSuccess={showSuccess}
            refetchClusterDetail={refetchClusterDetail}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.AUTHENTICATION}`,
      },
      {
        element: (
          <ActionsHistoryTab
            entryId={entryId}
            index={6}
            clusterDetail={clusterDetail}
            selectedTabIndex={6}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.HISTORY}/*`,
      },
      {
        element: (
          <PodsTab
            index={7}
            clusterDetail={clusterDetail}
            selectedTabIndex={7}
            deletePodAction={(name: string) => {
              setSelectedActionType(CLUSTER_ACTION_TYPE.DELETE_POD);
              setPodName(name);
            }}
            pullLogsAction={(name: string) => {
              setSelectedActionType(CLUSTER_ACTION_TYPE.PULL_LOGS);
              setPodName(name);
            }}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.PODS}`,
      },
      {
        element: <ReleaseTab selectedTabIndex={8} index={8} />,
        path: `/${CLUSTER_DETAIL_URL.RELEASES}`,
      },
      {
        element: (
          <CertificateTab
            selectedTabIndex={9}
            index={9}
            clusterName={clusterDetail?.name}
          />
        ),
        path: `/${CLUSTER_DETAIL_URL.CERTIFICATE}`,
      },
    ].map((route, routeIndex) => (
      <Route
        key={routeIndex}
        index={!route.path}
        path={route.path}
        element={route.element}
      />
    ));

  const renderTabItems = (cluster: any) =>
    [
      {
        label: "Details",
        isHidden: false,
        to: basePath,
      },
      {
        label: "URLs",
        isHidden: false,
        to: `${basePath}/${CLUSTER_DETAIL_URL.URLS}`,
      },
      {
        label: "Status",
        isHidden: false,
        to: `${basePath}/${CLUSTER_DETAIL_URL.STATUS}`,
      },
      {
        label: "Network",
        isHidden: false,
        to: `${basePath}/${CLUSTER_DETAIL_URL.NETWORK}`,
      },
      {
        label: "Persistence",
        isHidden: false,
        to: `${basePath}/${CLUSTER_DETAIL_URL.PERSISTENCE}`,
      },
      {
        label: "Application Properties",
        isHidden: [CLUSTER_STATE.DELETED, CLUSTER_STATE.DELETING].includes(
          cluster?.state as any
        ),
        to: `${basePath}/${CLUSTER_DETAIL_URL.PROPERTIES}`,
      },
      {
        label: "Authentication",
        isHidden: [CLUSTER_STATE.DELETED, CLUSTER_STATE.DELETING].includes(
          cluster?.state as any
        ),
        to: `${basePath}/${CLUSTER_DETAIL_URL.AUTHENTICATION}`,
      },
      {
        label: "Actions History",
        isHidden: [CLUSTER_STATE.DELETED, CLUSTER_STATE.DELETING].includes(
          cluster?.state as any
        ),
        to: `${basePath}/${CLUSTER_DETAIL_URL.HISTORY}`,
      },
      {
        label: "Pods",
        isHidden: [CLUSTER_STATE.DELETED, CLUSTER_STATE.DELETING].includes(
          cluster?.state as any
        ),
        to: `${basePath}/${CLUSTER_DETAIL_URL.PODS}`,
      },
      {
        label: "Releases",
        isHidden: cluster?.state !== CLUSTER_STATE.PROVISIONED,
        to: `${basePath}/${CLUSTER_DETAIL_URL.RELEASES}`,
      },
      {
        label: "Certificates",
        isHidden: cluster?.state !== CLUSTER_STATE.PROVISIONED,
        to: `${basePath}/${CLUSTER_DETAIL_URL.CERTIFICATE}`,
      },
    ].map((tab, index) =>
      tab.isHidden ? null : (
        <StyledTab
          key={tab.label}
          value={tab.label}
          component={Link}
          to={tab.to}
          label={tab.label}
          {...a11yProps(index)}
        />
      )
    );

  if (!isOrkesUser && clusterDetail?.environmentType === "TRIAL") {
    return <Navigate to={UNAUTHORIZED_ROUTE_URL} replace />;
  }

  const handleSelectedActionType = (value: string) => {
    setSelectedActionType(value);
  };
  const handleChangePodName = (value: string) => {
    setPodName(value);
  };

  return (
    <>
      <Helmet>
        <title>Cluster Details: {clusterName}</title>
      </Helmet>
      {clusterDetailFetching && <LinearProgress />}

      {!clusterDetailFetching && clusterDetail ? (
        <Box style={{ padding: 5, paddingLeft: 25, paddingTop: 25 }}>
          <div style={{ display: "flex" }}>
            <Heading level={2}>
              Cluster: {clusterDetail.name}{" "}
              <Chip
                label={`${
                  clusterDetail.environmentType
                    ? clusterDetail.environmentType
                    : "PRODUCTION"
                }`}
                color={"info"}
                variant={"outlined"}
                sx={{
                  ...sxChip,
                  display: "inline-flex",
                  width: "fit-content",
                  alignItems: "center",
                }}
                avatar={
                  <Avatar
                    sx={{
                      margin: 0,
                      padding: 0,
                      marginTop: "-3px",
                      "& img": {
                        width: 18,
                        height: 18,
                      },
                    }}
                    alt="env"
                    src={getEnvironmentIcon(clusterDetail.environmentType)}
                  />
                }
              />
              <HostingChip
                cluster={clusterDetail}
                sx={{ marginLeft: "16px" }}
              />
              <Tooltip title={"Click to monitor progress of the provisioning"}>
                <Link
                  to={`/home/clusterprogess?clusterName=${clusterDetail.name}`}
                >
                  <Chip
                    sx={{
                      ...clickableChipSx,
                      marginLeft: 4,
                      cursor: "pointer",
                    }}
                    label={
                      clusterDetail.state === "SCHEDULE_DELETION"
                        ? "DELETION SCHEDULED"
                        : clusterDetail.state ?? "NA"
                    }
                    color={getColorFromState(clusterDetail.state)}
                    icon={<IconFromState state={clusterDetail.state} />}
                  />
                </Link>
              </Tooltip>
            </Heading>
            {![CLUSTER_STATE.DELETED, CLUSTER_STATE.DELETING].includes(
              clusterDetail.state
            ) && (
              <ActionTypeSelector
                isOrkesUser={isOrkesUser}
                clusterDetail={clusterDetail}
                refetchClusterDetail={refetchClusterDetail}
                selectedActionType={selectedActionType}
                podName={podName}
                handleSelectedActionType={handleSelectedActionType}
                handleChangePodName={handleChangePodName}
              />
            )}
          </div>
          <Paper
            elevation={1}
            style={{
              marginRight: 10,
              marginTop: 16,
            }}
          >
            <StyledTabs
              sx={{
                width: { sm: "700px", md: "900px", lg: "auto", xl: "auto" },
              }}
              scrollButtons={true}
              variant={"scrollable"}
              value={selectedTabIndex(relativePath)}
              aria-label="basic tabs example"
            >
              {renderTabItems(clusterDetail)}
            </StyledTabs>
            <Routes>{renderRoutes()}</Routes>
            <Box
              sx={{
                display: "flex",
                borderBottom: "1px solid lightgrey",
                padding: "16px",
              }}
            >
              <Link
                style={{
                  marginLeft: "auto",
                }}
                to="/home"
              >
                <OutlinedButton>Return Home</OutlinedButton>
              </Link>
            </Box>
          </Paper>
        </Box>
      ) : null}
    </>
  );
}
