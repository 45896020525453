import { useSelector } from "@xstate/react";
import {
  AuthProviderContext,
  AuthProviderEventTypes,
  AuthProviderEvents,
  AuthProviderStates,
} from "./types";
import { useMemo } from "react";
import { ActorRef, State } from "xstate";
import { UserProfileType, Role } from "utils/saastypes";

export const useAuthMachine = (authService: ActorRef<AuthProviderEvents>) => {
  const currentUser = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.currentUser
  );
  const verificationStatus = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.verificationStatus
  );

  const messageType = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.messageType
  );
  const inviteId = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.inviteId
  );

  const isMagicLinkRequestPending = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.CONTINUE_WITH_MAGIC_LINK,
      ])
  );

  const isFormFieldsActive = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.LOGIN_SECTION,
        AuthProviderStates.LOGIN_FORM,
      ]) ||
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.SIGNUP_SECTION,
        AuthProviderStates.SIGNUP_FORM,
      ]) ||
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.FORGOT_PASSWORD_SECTION,
      ])
  );

  const isSubmittingNewPassword = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.RESET_PASSWORD_SECTION,
        AuthProviderStates.SUBMIT_NEW_PASSWORD,
      ])
  );

  const isLoadingState = useSelector(
    // FIXME this can be easily achieved with tags
    authService,
    (state: State<AuthProviderContext>) => state.hasTag("loading")
  );

  const isPasswordLess = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.LOGIN_SECTION,
        AuthProviderStates.LOGIN_FORM,
        AuthProviderStates.PASSWORD_LESS_FORM,
      ]) ||
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.SIGNUP_SECTION,
        AuthProviderStates.SIGNUP_FORM,
        AuthProviderStates.PASSWORD_LESS_FORM,
      ])
  );

  const isSignupSection = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.SIGNUP_SECTION,
      ])
  );

  const isResetPasswordSection = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.FORGOT_PASSWORD_SECTION,
      ])
  );

  const isLoginSection = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.LOGIN_SECTION,
      ])
  );

  const isVerifyingEmail = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.VERIFY_EMAIL,
        AuthProviderStates.VERIFYING_EMAIL,
      ])
  );

  const isEmailVerificationFailed = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.VERIFY_EMAIL,
        AuthProviderStates.EMAIL_VERIFICATION_FAILED,
      ])
  );

  const isEmailVerificationUrlInvalid = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.UNLOGGED,
        AuthProviderStates.VERIFY_EMAIL,
        AuthProviderStates.INVALID_EMAIL_VERIFICATION_URL,
      ])
  );

  const refetchBillingProfile = () => {
    authService.send({
      type: AuthProviderEventTypes.REFETCH_BILLING_PROFILE,
    });
  };

  const continueWithEmail = () => {
    authService.send({
      type: AuthProviderEventTypes.CONTINUE_WITH_EMAIL_EVENT,
    });
  };

  const refetchVerifyUserStatus = () => {
    authService.send({
      type: AuthProviderEventTypes.REFETCH_VERIFY_USER_STATUS_EVENT,
    });
  };

  const attemptToSendEmailLink = (email: string) => {
    authService.send({
      type: AuthProviderEventTypes.SEND_MAGIC_LINK_EVENT,
      email,
    });
  };

  const goBackToLoginSection = () => {
    authService.send({ type: AuthProviderEventTypes.GO_BACK_EVENT });
  };

  const showForgotPasswordSection = () => {
    authService.send({
      type: AuthProviderEventTypes.SWITCH_TO_FORGOT_PASSWORD_EVENT,
    });
  };

  const handleLogOut = () => {
    authService.send({
      type: AuthProviderEventTypes.LOG_OUT_EVENT,
    });
  };

  const isOrkesUser = useMemo(
    () => currentUser?.email?.endsWith("@orkes.io"),
    [currentUser]
  );

  const acceptInvite = (inviteId: string) => {
    localStorage.setItem("inviteIdInLocalStorage", inviteId);
    authService.send({
      type: AuthProviderEventTypes.ACCEPT_INVITE_EVENT,
      inviteId,
    });
  };

  const navigateToLogin = () => {
    authService.send({
      type: AuthProviderEventTypes.NAVIGATE_TO_LOGIN_EVENT,
    });
  };

  const switchCurrentOrganization = (organizationId: string) => {
    authService.send({
      type: AuthProviderEventTypes.SWITCH_CURRENT_ORGANIZATION,
      data: {
        id: organizationId,
      },
    });
  };

  /** This is currently handled with hooks. not refactoring yet */
  // const assignTrialOrganization = (data: AssignTrialOrganizationPayload) => {
  //   authService.send({
  //     type: AuthProviderEventTypes.ASSIGN_TRIAL_ORGANIZATION,
  //     data,
  //   });
  // };

  const loggedWithOrganization = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.hasTag("loggedUserHasOrganization")
    // state.matches([
    //   AuthProviderStates.LOGGED_USER,
    //   AuthProviderStates.LOGGED_USER_HAS_ORGANIZATION,
    // ])
  );

  const isSwitchingOrganization = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.LOGGED_USER,
        AuthProviderStates.SWITCHING_CURRENT_ORGANIZATION,
      ]) ||
      state.matches([
        AuthProviderStates.LOGGED_USER,
        AuthProviderStates.FETCHING_ORGANIZATION,
      ])
  );

  const loggedWithNoOrganization = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.matches([
        AuthProviderStates.LOGGED_USER,
        AuthProviderStates.LOGGED_USER_HAS_NO_ORGANIZATION,
      ])
  );

  const userHasLoggedIn = useSelector(
    authService,
    (state: State<AuthProviderContext>) =>
      state.hasTag("logged") || state.hasTag("loading")
  );

  const currentOrganization = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.currentOrganization
  );

  const currentUserRoles = useMemo(
    () => currentOrganization?.userRoles || [],
    [currentOrganization]
  );

  const hasAdminOrRootRole = useMemo(
    () =>
      [Role.COMPANY_ROOT, Role.COMPANY_ADMIN].some((r) =>
        currentUserRoles.includes(r)
      ),
    [currentUserRoles]
  );

  const billingProfile = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.billingProfile
  );

  const currentProfile = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.currentProfile
  );

  const availableProfiles: UserProfileType[] | undefined = useSelector(
    authService,
    (state: State<AuthProviderContext>) => state.context.availableProfiles
  );

  const switchCurrentProfile = (profile: string) => {
    authService.send({
      type: AuthProviderEventTypes.SWITCH_CURRENT_PROFILE,
      data: {
        profile,
      },
    });
  };

  const organizationType = useMemo(
    () => billingProfile?.organizationType, // null old client, ENTERPRISE those who upgraded, TRIAL those in TRIAL
    [billingProfile]
  );

  const cardInfo = useMemo(() => billingProfile?.meta?.card, [billingProfile]);

  return [
    {
      currentProfile,
      availableProfiles,
      currentOrganization,
      currentUser,
      isOrkesUser,
      verificationStatus,
      isLoggedInAndVerified:
        currentUser != null && !!verificationStatus?.existing, // FIXME: This makes no sense ist it just logged in?
      loggedWithOrganization,
      loggedWithNoOrganization,
      isFormFieldsActive,
      isPasswordLess,
      isSignupSection,
      isLoginSection,
      isLoadingState,
      isMagicLinkRequestPending,
      isResetPasswordSection,
      messageType,
      isSubmittingNewPassword,
      isVerifyingEmail,
      isEmailVerificationFailed,
      isEmailVerificationUrlInvalid,
      inviteId,
      billingProfile,
      cardInfo,
      isSwitchingOrganization,
      currentUserRoles,
      organizationType,
      userHasLoggedIn,
      hasAdminOrRootRole,
    },
    {
      refetchVerifyUserStatus,
      handleLogOut,
      continueWithEmail,
      goBackToLoginSection,
      attemptToSendEmailLink,
      showForgotPasswordSection,
      acceptInvite,
      navigateToLogin,
      switchCurrentOrganization,
      switchCurrentProfile,
      refetchBillingProfile,
    },
  ] as const;
};
