import { useMemo, useState } from "react";
import { useActionWithPath } from "utils/query";
import useInterval from "utils/useInterval";
import { ModalStep } from "./common";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";
import { postDataLayer } from "utils/postToDataLayer";

export const useWelcomeModal = ({
  displayName,
  organizationName,
}: {
  displayName?: string;
  organizationName?: string;
}) => {
  const [currentStep, setCurrentStep] = useState(ModalStep.PERSONAL_INFO_STEP);

  const [userInfo, setUserInfo] = useState({
    name: displayName ?? "",
    company: organizationName ?? "",
    phoneNumber: "",
    marketingPreference: true,
    termsAndConditionsAccepted: true,
  });

  const [verificationCode, setVerificationCode] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [open, setOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [uiUrl, setUiUrl] = useState<null | string>(null);
  const { authService } = useAuth();
  const [{ currentUser }] = useAuthMachine(authService);

  const [countdown, setCountdown] = useState(0);

  const handleFieldChange = (value: string | boolean, name: string) => {
    setErrorMessage("");
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  const handleVerificationCode = (val: string) => {
    setErrorMessage("");
    setVerificationCode(val);
  };
  const handleSelectedRegion = (val: string) => setSelectedRegion(val);
  const goBackToPersonalInfoStep = () => {
    setErrorMessage("");
    setCurrentStep(ModalStep.PERSONAL_INFO_STEP);
  };

  const handleCloseModal = () => setOpen(false);

  const assignTrialClusterAction = useActionWithPath({
    onSuccess: async (data: any) => {
      setUiUrl(data.conductorUIUrl);
      setCurrentStep(ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP);
    },
    onError: () => {},
  });

  const sendOtpCodeAction = useActionWithPath({
    onSuccess: () => {
      postDataLayer("free_trial_phone_submitted", currentUser.email);
      setCurrentStep(ModalStep.PHONE_VERIFICATION_STEP);
      setCountdown(60);
    },
    onError: async (response: any) => {
      const error = await response.json();
      setErrorMessage(error.message ?? "Failed to send the verification code");
    },
  });

  const validateOtpAction = useActionWithPath({
    onSuccess: () => {
      postDataLayer("free_trial_phone_confirmed", currentUser.email);
      setCurrentStep(ModalStep.REGION_SELECTION_STEP);
    },
    onError: () => {
      setErrorMessage(
        "Wrong code entered. Please check your code and try again."
      );
    },
  });

  const handleContinue = () => {
    setErrorMessage("");
    if (currentStep === ModalStep.PERSONAL_INFO_STEP) {
      sendOtpCode();
    } else if (currentStep === ModalStep.PHONE_VERIFICATION_STEP) {
      validateOtp();
    } else {
      submitUserInfo();
    }
  };

  useInterval(() => {
    if (countdown > 0) {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }
  }, 1000);

  const sendOtpCode = () => {
    setVerificationCode("");
    const { phoneNumber } = userInfo;
    if (phoneNumber) {
      // @ts-ignore
      sendOtpCodeAction.mutate({
        method: "post",
        path: `/sendOtpCode`,
        body: JSON.stringify({
          phone: phoneNumber,
        }),
        successMessage: "Verification code sent.",
        useLocalMessage: true,
      });
    }
  };

  const validateOtp = () => {
    const { phoneNumber } = userInfo;
    if (phoneNumber && verificationCode) {
      // @ts-ignore
      validateOtpAction.mutate({
        method: "post",
        path: `/validateOtpCode`,
        body: JSON.stringify({
          phone: phoneNumber,
          code: verificationCode,
        }),
        successMessage: "Phone number verified.",
        useLocalMessage: true,
      });
    }
  };

  const submitUserInfo = () => {
    const {
      name,
      company,
      phoneNumber,
      termsAndConditionsAccepted,
      marketingPreference,
    } = userInfo;
    const trimmedUserName = name?.trim();
    const trimmedOrgName = company?.trim();
    if (trimmedUserName && trimmedOrgName) {
      // @ts-ignore
      assignTrialClusterAction.mutate({
        method: "post",
        path: `/assignTrialOrganization`,
        body: JSON.stringify({
          organizationName: trimmedOrgName,
          clusterLocation: selectedRegion,
          userName: trimmedUserName,
          phone: phoneNumber,
          emailPreferred: marketingPreference,
          termsAndConditionsAccepted: termsAndConditionsAccepted,
        }),
        successMessage: "Information has been successfully updated.",
      });
    }
  };

  const isPersonalInfoValid = useMemo(() => {
    const { name, company, phoneNumber, termsAndConditionsAccepted } = userInfo;
    return (
      (name?.trim() || displayName) &&
      company?.trim() &&
      phoneNumber &&
      termsAndConditionsAccepted
    );
  }, [displayName, userInfo]);

  const buttonState = useMemo(() => {
    switch (currentStep) {
      case ModalStep.PERSONAL_INFO_STEP:
        return {
          disabled: !isPersonalInfoValid,
          loading: sendOtpCodeAction.isLoading,
        };
      case ModalStep.PHONE_VERIFICATION_STEP:
        return {
          disabled: !verificationCode,
          loading: validateOtpAction.isLoading,
        };
      case ModalStep.REGION_SELECTION_STEP:
        return {
          disabled: !selectedRegion,
          loading: assignTrialClusterAction.isLoading,
        };
      default:
        return {};
    }
  }, [
    assignTrialClusterAction.isLoading,
    currentStep,
    isPersonalInfoValid,
    selectedRegion,
    sendOtpCodeAction.isLoading,
    validateOtpAction.isLoading,
    verificationCode,
  ]);

  return [
    {
      buttonState,
      open,
      uiUrl,
      selectedRegion,
      countdown,
      userInfo,
      currentStep,
      verificationCode,
      otpVerificationInProgress: sendOtpCodeAction.isLoading,
      errorMessage,
    },
    {
      handleContinue,
      handleCloseModal,
      sendOtpCode,
      handleFieldChange,
      handleVerificationCode,
      handleSelectedRegion,
      goBackToPersonalInfoStep,
    },
  ] as const;
};
