import { Box, Typography } from "@mui/material";
import { commonStyle, freeTrialFeatures } from "./common";
import { FeatureCheckIcon } from "components/icons/FeatureCheckIcon";

export const FreeTrialSection = () => {
  const { freeTrialStyle } = commonStyle;
  return (
    <Box sx={freeTrialStyle.container}>
      <Typography sx={freeTrialStyle.title}>
        FREE 14-Day Enterprise Trial
      </Typography>
      <Typography sx={freeTrialStyle.subTitle}>
        *No credit card required
      </Typography>
      <Box sx={freeTrialStyle.featureContainer}>
        <Box>
          {freeTrialFeatures.map((feature) => (
            <Box key={feature} sx={freeTrialStyle.featureItem}>
              <FeatureCheckIcon />
              <Typography sx={freeTrialStyle.featureText}>{feature}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Typography sx={freeTrialStyle.featureText} textAlign="center">
        For personal projects, check out our{" "}
        <Typography
          sx={freeTrialStyle.linkText}
          component="span"
          onClick={() => window.open("https://play.orkes.io/")}
        >
          Developer Playground
        </Typography>
      </Typography>
    </Box>
  );
};
