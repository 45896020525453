import Input from "components/Input";
import { modalStyle } from "./common";
import ActionButton from "components/buttons/ActionButton";
import { Typography } from "@mui/material";
import { ResentSvg } from "images/svg/ResendSvg";

interface PhoneVerificationStepProps {
  verificationCode: string;
  handleVerificationCode: (val: string) => void;
  resendVerificationCode: () => void;
  countdown: number;
  buttonProgress: boolean;
  errorMessage?: string;
}

export const PhoneVerificationStep = ({
  verificationCode,
  handleVerificationCode,
  resendVerificationCode,
  countdown,
  buttonProgress,
  errorMessage,
}: PhoneVerificationStepProps) => {
  return (
    <>
      <Input
        value={verificationCode}
        onChange={(val) => handleVerificationCode(val)}
        required
        label="Verification Code"
        type="text"
        sx={modalStyle.inputStyle}
        error={!!errorMessage}
        helperText={errorMessage}
        inputProps={{
          autocomplete: "off",
        }}
      />
      {countdown > 0 ? (
        <Typography
          sx={{
            textAlign: "left",
            width: "100%",
            paddingX: "40px",
            fontSize: "12px",
            mt: 2,
            position: "relative",
            top: 10,
            left: -10,
          }}
        >
          Resend code in {countdown}s
        </Typography>
      ) : (
        <ActionButton
          startIcon={
            <ResentSvg
              color={countdown > 0 || buttonProgress ? "#c5c5c5" : "#1976D2"}
            />
          }
          variant="text"
          size="medium"
          onClick={resendVerificationCode}
          sx={{
            color: "#1976D2",
            mt: 2,
            position: "relative",
            left: "-185px",
            top: 10,
          }}
          disabled={countdown > 0 || buttonProgress}
          progress={buttonProgress}
        >
          Resend Code
        </ActionButton>
      )}
    </>
  );
};
