export const FeatureCheckIcon = ({ size = 20, ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clip-path="url(#clip0_1988_3186)">
      <path
        d="M14.0875 5.8125L15.85 7.575L9.225 14.2C8.975 14.45 8.6625 14.5625 8.3375 14.5625C8.0125 14.5625 7.7 14.4375 7.45 14.2L4.1375 10.8875L5.9 9.125L8.325 11.55L14.0625 5.8125H14.0875ZM20 10C20 15.5125 15.5125 20 10 20C4.4875 20 0 15.5125 0 10C0 4.4875 4.4875 0 10 0C15.5125 0 20 4.4875 20 10ZM18.75 10C18.75 5.175 14.825 1.25 10 1.25C5.175 1.25 1.25 5.175 1.25 10C1.25 14.825 5.175 18.75 10 18.75C14.825 18.75 18.75 14.825 18.75 10Z"
        fill="#3AC9A5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1988_3186">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
