import { Alert, AlertColor, AlertTitle, Box } from "@mui/material";
import LinearProgress from "components/LinearProgress";
import { LINEAR_PROGRESS_INDEX } from "constants/common";
import { WorkflowStatus } from "utils/saastypes";

interface AlertMessage {
  color: AlertColor;
  text: string;
}
interface TrialClusterProgressProps {
  alertMessage: AlertMessage | null;
  workflowStatus?: WorkflowStatus;
  progressValue: number;
  progressColor: AlertColor;
  progressText: string;
}

export const TrialClusterProgress = ({
  alertMessage,
  workflowStatus,
  progressValue,
  progressColor,
  progressText,
}: TrialClusterProgressProps) => {
  if (!alertMessage) return null;

  return (
    <Box>
      <Alert
        severity={alertMessage.color}
        sx={{
          ".MuiAlert-message": {
            width: "100%",
          },
        }}
      >
        <AlertTitle>{workflowStatus?.status}</AlertTitle>

        <Box>
          <LinearProgress
            variant="determinate"
            value={progressValue}
            color={progressColor}
            sx={{
              minHeight: "30px",
              height: "100%",
              my: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              ":after": {
                content: `"${progressText}"`,
                color: "white",
                zIndex: LINEAR_PROGRESS_INDEX + 1,
                pointerEvents: "none",
              },
            }}
          />
        </Box>
      </Alert>
    </Box>
  );
};
