import { useMemo, useState } from "react";
import DataTable from "components/DataTable/DataTable";
import { customStyles } from "utils/utils";
import { useAgentLogs, useHistoryEntry, useUserIds } from "utils/query";
import { Link } from "react-router-dom";
import { TabPanel } from "./TabPanel";
import useInterval from "utils/useInterval";
import _uniq from "lodash/uniq";
import LinearProgress from "../../../components/LinearProgress";
import { HistoryEntry } from "pages/HistoryEntry";
import { Box, Button, Tooltip } from "@mui/material";
import StyledMainButton from "../../../components/buttons/StyledMainButton";
import { CLUSTER_DETAIL_URL } from "utils/routePaths";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { tableButtonStyle } from "theme/styles";
import { AgentLogsResults } from "utils/saastypes";

export default function ActionsHistoryTab({
  selectedTabIndex,
  entryId,
  clusterDetail,
  index,
}: {
  selectedTabIndex: number;
  clusterDetail: any;
  index: number;
  entryId?: string;
}) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);

  const {
    data: agentLogs,
    refetch: refetchLogs,
    isFetching,
    error,
  } = useAgentLogs(clusterDetail.name, page, pageSize);

  const { data: userData } = useUserIds(
    (agentLogs && _uniq(agentLogs.results.map((x: any) => x.createdBy))) || []
  );

  const hasPendingLogs = useMemo(
    () =>
      agentLogs?.results?.some(
        (log: AgentLogsResults) =>
          log?.executionStatus === "IN_PROGRESS" ||
          log?.executionStatus === "SCHEDULED"
      ),
    [agentLogs?.results]
  );

  useInterval(
    () => {
      if (!isFetching) {
        refetchLogs();
      }
    },
    hasPendingLogs ? 5000 : null
  );

  return (
    <TabPanel
      style={{
        padding: 16,
        position: "relative",
        borderBottom: "1px solid lightgrey",
      }}
      value={selectedTabIndex}
      index={index}
    >
      {isFetching && <LinearProgress />}
      {error && (
        <div
          style={{
            margin: 16,
          }}
        >
          Error while fetching Actions History, please try again.
        </div>
      )}
      {entryId && (
        <HistoryEntryModal entryId={entryId} clusterName={clusterDetail.name} />
      )}
      {!error && (
        <DataTable
          customStyles={customStyles}
          data={agentLogs ? agentLogs.results : []}
          paginationServer={true}
          paginationTotalRows={agentLogs?.total ? agentLogs.total : 0}
          onChangeRowsPerPage={(newPerPage: number, page: number) => {
            setPageSize(newPerPage);
            setPage(page);
          }}
          onChangePage={(page: number) => {
            setPage(page);
          }}
          customActions={[
            <Tooltip title="Refresh History" key="refresh-history">
              <Button
                size="small"
                startIcon={<RefreshOutlinedIcon />}
                key="refresh"
                onClick={refetchLogs}
                sx={tableButtonStyle}
              >
                Refresh
              </Button>
            </Tooltip>,
          ]}
          columns={[
            {
              name: "id",
              label: "ID",
              selector: (row: any) => (
                <Link
                  to={`${CLUSTER_DETAIL_URL.ROOT}/${clusterDetail.name}/${CLUSTER_DETAIL_URL.HISTORY}/${row.id}`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {row.id}
                </Link>
              ),
            },
            {
              name: "commandLabel",
              label: "Operation",
              selector: (row: any) => row.commandLabel,
            },
            {
              name: "executionStatus",
              label: "Execution Status",
              selector: (row: any) => row.status,
              renderer: (
                val:
                  | "IN_PROGRESS"
                  | "FAILED"
                  | "FAILED_WITH_TERMINAL_ERROR"
                  | "COMPLETED"
              ) => {
                const colors = {
                  IN_PROGRESS: { color: "orange", name: "In Progress" },
                  COMPLETED: { color: "green", name: "Completed" },
                  COMPLETED_WITH_ERRORS: {
                    color: "orange",
                    name: "Completed with Errors",
                  },
                  FAILED: { color: "red", name: "Failed" },
                  FAILED_WITH_TERMINAL_ERROR: {
                    color: "red",
                    name: "Failed with Terminal Error",
                  },
                  SCHEDULED: { color: "orange", name: "Scheduled" },
                  CANCELED: { color: "red", name: "Cancelled" },
                  TIMED_OUT: { color: "red", name: "Timed Out" },
                  SKIPPED: { color: "orange", name: "Skipped" },
                };
                if (!colors[val]) {
                  return <span>{val}</span>;
                }
                return (
                  <span style={{ color: colors[val].color }}>
                    {colors[val].name}
                  </span>
                );
              },
            },
            {
              name: "createdAt",
              label: "Created At",
              selector: (row: any) => {
                const time = new Date(row.createdAt).toLocaleTimeString();
                const date = new Date(row.createdAt).toLocaleDateString();
                return `${date} ${time}`;
              },
            },
            {
              name: "createdBy",
              label: "Created By",
              selector: (row: any) => row.createdBy,
              renderer: (val: string) => {
                return userData && userData[val]
                  ? userData[val].userEmail
                  : "Unknown";
              },
            },
          ]}
        />
      )}
    </TabPanel>
  );
}

function HistoryEntryModal({
  entryId,
  clusterName,
}: {
  entryId?: string;
  clusterName: string;
}) {
  const {
    data: historyEntry,
    refetch,
    error,
    isFetching,
  } = useHistoryEntry(clusterName as string, entryId as string);

  useInterval(
    () => {
      refetch();
    },
    historyEntry?.executionStatus === "SCHEDULED" ||
      historyEntry?.executionStatus === "IN_PROGRESS"
      ? 2000
      : null
  );

  return (
    <div
      style={{
        position: "absolute",
        top: 16,
        left: 0,
        padding: 16,
        zIndex: 20,
        background: "white",
        minHeight: "100%",
        width: "100%",
      }}
    >
      <StyledMainButton
        style={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
        component={(props) => (
          <Link {...props} to={`/home/clusterDetails/${clusterName}/history`} />
        )}
      >
        Close
      </StyledMainButton>
      {historyEntry && !error && <HistoryEntry historyEntry={historyEntry} />}
      {isFetching && <Box>Loading...</Box>}
      {error && <Box>Error when fetching History Entry. Please try again.</Box>}
    </div>
  );
}
