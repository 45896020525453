import { UserOrganization } from "utils/query";
import { BraavosBillingProfile, Role, UserProfileType } from "utils/saastypes";
import { DoneInvokeEvent } from "xstate";
import { User as Auth0User } from "@auth0/auth0-react";

export enum ErrorMessageType {
  PERMISSION_DENIED = "PERMISSION_DENIED",
  VERIFICATION_EMAIL_SENT = "VERIFICATION_EMAIL_SENT",
  EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED",
  MAGIC_LINK_SENT = "MAGIC_LINK_SENT",
  RESET_PASSWORD_LINK_SENT = "RESET_PASSWORD_LINK_SENT",
}

export enum AuthProviderStates {
  INIT = "INIT",
  IDLE = "IDLE",
  UNLOGGED = "UNLOGGED",

  CLEAN_AND_UNLOG = "CLEAN_AND_UNLOG",
  LOGGED_USER = "LOGGED_USER",
  ACCEPT_INVITE_FLOW = "ACCEPT_INVITE_FLOW",
  ACCEPT_INVITE = "ACCEPT_INVITE",
  CHECK_FOR_INVITE_ID = "CHECK_FOR_INVITE_ID",
  VERIFY_USER = "VERIFY_USER",
  CONTINUE_WITH_GOOGLE = "CONTINUE_WITH_GOOGLE",
  CONTINUE_WITH_MAGIC_LINK = "CONTINUE_WITH_MAGIC_LINK",
  LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD",
  SIGNUP_WITH_PASSWORD = "SIGNUP_WITH_PASSWORD",
  LOGIN_SECTION = "LOGIN_SECTION",
  LOGIN_FORM = "LOGIN_FORM",
  SIGNUP_SECTION = "SIGNUP_SECTION",
  SIGNUP_FORM = "SIGNUP_FORM",
  FORGOT_PASSWORD_SECTION = "FORGOT_PASSWORD_SECTION",
  RESET_PASSWORD_SECTION = "RESET_PASSWORD_SECTION",
  PASSWORD_LESS_FORM = "PASSWORD_LESS_FORM",
  FORM_WITH_PASSWORD = "FORM_WITH_PASSWORD",
  SENDING_RESET_PASSWORD_LINK = "SENDING_RESET_PASSWORD_LINK",
  SHOW_MESSAGE_TO_USER = "SHOW_MESSAGE_TO_USER",
  RESENDING_VERIFICATION_EMAIL = "RESENDING_VERIFICATION_EMAIL",
  IS_EMAIL_VERIFIED = "IS_EMAIL_VERIFIED",
  INVOKE_LOGIN_WITH_EMAIL_LINK = "INVOKE_LOGIN_WITH_EMAIL_LINK",
  SUBMIT_NEW_PASSWORD = "SUBMIT_NEW_PASSWORD",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  IS_VERIFY_EMAIL_URL_VALID = "IS_VERIFY_EMAIL_URL_VALID",
  VERIFYING_EMAIL = "VERIFYING_EMAIL",
  INVALID_EMAIL_VERIFICATION_URL = "INVALID_EMAIL_VERIFICATION_URL",
  EMAIL_VERIFICATION_FAILED = "EMAIL_VERIFICATION_FAILED",
  EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS",

  LOGGED_USER_FETCH_NEEDED_DATA = "LOGGED_USER_FETCH_NEEDED_DATA",

  LOGGED_USER_FETCH_PROFILE_INFORMATION = "LOGGED_USER_FETCH_PROFILE_INFORMATION",

  LOGGED_USER_IS_ORKES_USER = "LOGGED_USER_IS_ORKES_USER",

  LOGGED_USER_HAS_ORGANIZATION = "LOGGED_USER_HAS_ORGANIZATION",
  LOGGED_USER_HAS_NO_ORGANIZATION = "LOGGED_USER_HAS_NO_ORGANIZATION",

  SWITCHING_CURRENT_ORGANIZATION = "SWITCHING_CURRENT_ORGANIZATION",
  FETCHING_ORGANIZATION = "FETCHING_ORGANIZATION",

  SWITCH_CURRENT_PROFILE = "SWITCH_CURRENT_PROFILE",
}
export enum AuthProviderEventTypes {
  SET_USER_EVENT = "SET_USER_EVENT",
  LOG_OUT_EVENT = "LOG_OUT_EVENT",
  CONTINUE_WITH_GOOGLE_EVENT = "CONTINUE_WITH_GOOGLE_EVENT",
  CONTINUE_WITH_EMAIL_EVENT = "CONTINUE_WITH_EMAIL_EVENT",
  SWITCH_TO_SIGNUP_EVENT = "SWITCH_TO_SIGNUP_EVENT",
  SWITCH_TO_LOGIN_EVENT = "SWITCH_TO_LOGIN_EVENT",
  SWITCH_TO_PASSWORD_EVENT = "SWITCH_TO_PASSWORD_EVENT",
  SWITCH_TO_PASSWORD_LESS_EVENT = "SWITCH_TO_PASSWORD_LESS_EVENT",
  SEND_MAGIC_LINK_EVENT = "SEND_MAGIC_LINK_EVENT",
  REFETCH_VERIFY_USER_STATUS_EVENT = "REFETCH_VERIFY_USER_STATUS_EVENT",
  GO_BACK_EVENT = "GO_BACK_EVENT",
  SWITCH_TO_FORGOT_PASSWORD_EVENT = "SWITCH_TO_FORGOT_PASSWORD_EVENT",
  LOGIN_WITH_PASSWORD_EVENT = "LOGIN_WITH_PASSWORD_EVENT",
  SIGNUP_WITH_PASSWORD_EVENT = "SIGNUP_WITH_PASSWORD_EVENT",
  SEND_RESET_PASSWORD_EVENT = "SEND_RESET_PASSWORD_EVENT",
  RESEND_VERIFICATION_EMAIL_EVENT = "RESEND_VERIFICATION_EMAIL_EVENT",
  ACCEPT_INVITE_EVENT = "ACCEPT_INVITE_EVENT",
  SIGN_IN_WITH_EMAIL_LINK_EVENT = "SIGN_IN_WITH_EMAIL_LINK_EVENT",
  SUBMIT_NEW_PASSWORD_EVENT = "SUBMIT_NEW_PASSWORD_EVENT",
  NAVIGATE_TO_LOGIN_EVENT = "NAVIGATE_TO_LOGIN_EVENT",

  FETCH_CURRENT_ORGANIZATION = "FETCH_CURRENT_ORGANIZATION",

  ASSIGN_TRIAL_ORGANIZATION = "ASSIGN_TRIAL_ORGANIZATION",
  SWITCH_CURRENT_ORGANIZATION = "SWITCH_CURRENT_ORGANIZATION",
  SWITCH_CURRENT_PROFILE = "SWITCH_CURRENT_PROFILE",
  REFETCH_BILLING_PROFILE = "REFETCH_BILLING_PROFILE",
}

export type VerificationStatus = {
  existing: boolean;
};

export type AuthProviderContext = {
  auth?: Auth0User;
  inviteId?: string;
  currentUser?: any;
  verificationStatus?: VerificationStatus;
  currentOrganization?: UserOrganization;
  billingProfile?: BraavosBillingProfile;
  messageType?: string;
  currentUserRoles?: Role[];
  availableProfiles?: UserProfileType[];
  currentProfile?: UserProfileType;
};

export type SetUserEvent = {
  type: AuthProviderEventTypes.SET_USER_EVENT;
  data: any;
};

export type LogoutEvent = {
  type: AuthProviderEventTypes.LOG_OUT_EVENT;
};

export type ContinueWithEmailEvent = {
  type: AuthProviderEventTypes.CONTINUE_WITH_EMAIL_EVENT;
};

export type RefetchVerifyUserEvent = {
  type: AuthProviderEventTypes.REFETCH_VERIFY_USER_STATUS_EVENT;
};

export type SendMagicLinkEvent = {
  type: AuthProviderEventTypes.SEND_MAGIC_LINK_EVENT;
  email: string;
};

export type GoBackEvent = {
  type: AuthProviderEventTypes.GO_BACK_EVENT;
};

export type SwitchToPasswordFormEvent = {
  type: AuthProviderEventTypes.SWITCH_TO_PASSWORD_EVENT;
};

export type SwitchToPasswordLessFormEvent = {
  type: AuthProviderEventTypes.SWITCH_TO_PASSWORD_LESS_EVENT;
};

export type SwitchToSignupSectionEvent = {
  type: AuthProviderEventTypes.SWITCH_TO_SIGNUP_EVENT;
};

export type SwitchToLoginSectionEvent = {
  type: AuthProviderEventTypes.SWITCH_TO_LOGIN_EVENT;
};

export type LoginWithPasswordEvent = {
  type: AuthProviderEventTypes.LOGIN_WITH_PASSWORD_EVENT;
  data: {
    email: string;
    password: string;
  };
};

export type SwitchToForgotPasswordEvent = {
  type: AuthProviderEventTypes.SWITCH_TO_FORGOT_PASSWORD_EVENT;
};

export type SignupWithPasswordEvent = {
  type: AuthProviderEventTypes.SIGNUP_WITH_PASSWORD_EVENT;
  data: {
    email: string;
    password: string;
  };
};

export type SendResetPasswordEvent = {
  type: AuthProviderEventTypes.SEND_RESET_PASSWORD_EVENT;
  email: string;
};

export type ResendVerificationEmailEvent = {
  type: AuthProviderEventTypes.RESEND_VERIFICATION_EMAIL_EVENT;
};

export type AcceptInviteEvent = {
  type: AuthProviderEventTypes.ACCEPT_INVITE_EVENT;
  inviteId: string;
};

export type SignInWithEmailLinkEvent = {
  type: AuthProviderEventTypes.SIGN_IN_WITH_EMAIL_LINK_EVENT;
  email: string;
  pageUrl: string;
};

export type SubmitNewPasswordEvent = {
  type: AuthProviderEventTypes.SUBMIT_NEW_PASSWORD_EVENT;
  data: {
    email: string;
    password: string;
    token: string;
  };
};

export type NavigateToLoginEvent = {
  type: AuthProviderEventTypes.NAVIGATE_TO_LOGIN_EVENT;
};

export type FetchForCurrentOrganizationEvent = {
  type: AuthProviderEventTypes.FETCH_CURRENT_ORGANIZATION;
};

export type AssignTrialOrganizationPayload = {
  organizationName: string;
  clusterLocation: string;
  userName: string;
  phone: string;
  emailPreferred: string;
  termsAndConditionsAccepted: boolean;
};

export type AssignTrialOrganizationEvent = {
  type: AuthProviderEventTypes.ASSIGN_TRIAL_ORGANIZATION;
  data: AssignTrialOrganizationPayload;
};

export type SwitchCurrentOrganizationEvent = {
  type: AuthProviderEventTypes.ASSIGN_TRIAL_ORGANIZATION;
  data: {
    id: string;
  };
};

export type SwitchCurrentProfileEvent = {
  type: AuthProviderEventTypes.SWITCH_CURRENT_PROFILE;
  data: {
    profile: string;
  };
};

export type RefetchBillingProfileEvent = {
  type: AuthProviderEventTypes.REFETCH_BILLING_PROFILE;
};
export type AuthProviderEvents =
  | SetUserEvent
  | AcceptInviteEvent
  | LogoutEvent
  | SendMagicLinkEvent
  | SignInWithEmailLinkEvent
  | GoBackEvent
  | SubmitNewPasswordEvent
  | RefetchVerifyUserEvent
  | SwitchToLoginSectionEvent
  | SwitchToForgotPasswordEvent
  | SendResetPasswordEvent
  | SwitchToSignupSectionEvent
  | SwitchToPasswordLessFormEvent
  | SwitchToPasswordFormEvent
  | SignupWithPasswordEvent
  | LoginWithPasswordEvent
  | ResendVerificationEmailEvent
  | ContinueWithEmailEvent
  | NavigateToLoginEvent
  | FetchForCurrentOrganizationEvent
  | AssignTrialOrganizationEvent
  | SwitchCurrentOrganizationEvent
  | SwitchCurrentProfileEvent
  | RefetchBillingProfileEvent
  | DoneInvokeEvent<any>;
