import { ChangeEvent, ReactNode, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export default function AlertDialog({
  children,
  textTitle,
  textContent,
  open,
  setOpen,
  closeLabel = "Cancel",
  confirmLabel = "Confirm",
  isConfirmDisabled = false,
  handleConfirm,
  sx,
  maxWidth = "md",
  isInputConfirmation,
  valueToBeDeleted,
  closeBtnStyle,
  confirmBtnStyle,
}: {
  children?: ReactNode;
  textTitle: string;
  textContent: string | ReactNode;
  open: boolean;
  setOpen: any;
  closeLabel?: string;
  confirmLabel?: string;
  isConfirmDisabled?: boolean;
  handleConfirm: () => void;
  sx?: any;
  maxWidth?: "sm" | "md" | "lg";
  isInputConfirmation?: boolean;
  valueToBeDeleted?: string;
  closeBtnStyle?: any;
  confirmBtnStyle?: any;
}) {
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => {
    setInputValue("");
    setOpen(false);
  };

  const confirm = () => {
    handleClose();
    handleConfirm();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={sx}
    >
      <DialogTitle id="alert-dialog-title">{textTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {textContent}
        </DialogContentText>
        <div>{children}</div>
        {valueToBeDeleted && (
          <DialogContentText id="alert-dialog-confirm-label" sx={{ mt: 2 }}>
            Please type <strong>{valueToBeDeleted}</strong> to confirm
          </DialogContentText>
        )}
        {isInputConfirmation && (
          <TextField
            id="alert-dialog-confirm-input"
            sx={{ mt: 2 }}
            value={inputValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.target.value);
            }}
            variant="outlined"
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={closeBtnStyle}>
          {closeLabel}
        </Button>
        <Button
          autoFocus
          color={isInputConfirmation ? "error" : "primary"}
          disabled={
            isInputConfirmation
              ? inputValue !== valueToBeDeleted
              : isConfirmDisabled
          }
          onClick={confirm}
          sx={confirmBtnStyle}
        >
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
