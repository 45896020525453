import { Box } from "@mui/material";

import { modalStyle, regions } from "./common";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface RegionSelectionStepProps {
  selectedRegion: string;
  handleSelectedRegion: (region: string) => void;
}

export const RegionSelectionStep = ({
  selectedRegion,
  handleSelectedRegion,
}: RegionSelectionStepProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        px: 2,
        mt: 4,
        mb: 8,
      }}
    >
      {regions.map((region, i) => (
        <Box
          key={i}
          onClick={() => handleSelectedRegion(region.value)}
          sx={{
            ...modalStyle.regionBox,
            ...(selectedRegion === region.value && modalStyle.selectedRegion),
          }}
        >
          {region.label}
          {selectedRegion === region.value && (
            <CheckCircleOutlineIcon
              fontSize="small"
              sx={{
                position: "absolute",
                top: 2,
                right: 2,
                color: "#1976D2",
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};
