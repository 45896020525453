import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography,
} from "@mui/material";

import Input from "components/Input";
import { MuiPhone } from "./MuiPhone";
import { modalStyle } from "./common";

const commonStyle = {
  linkStyle: {
    fontWeight: "700",
    textDecoration: "none",
    color: "#1976D2",
  },
};

type UserInfo = {
  name: string;
  company: string;
  phoneNumber: string;
  marketingPreference: boolean;
  termsAndConditionsAccepted: boolean;
};

interface PersonalInfoStepProps {
  displayName: string;
  userInfo: UserInfo;
  handleFieldChange: (val: string | boolean, fieldName: string) => void;
  errorMessage?: string;
}

export const PersonalInfoStep = ({
  displayName,
  userInfo,
  handleFieldChange,
  errorMessage,
}: PersonalInfoStepProps) => (
  <>
    {!displayName && (
      <Input
        placeholder="Enter your name"
        value={userInfo.name}
        onChange={(val) => handleFieldChange(val, "name")}
        required
        label="Your Full Name"
        type="text"
        sx={modalStyle.inputStyle}
      />
    )}
    <Input
      placeholder="Enter your organization name"
      value={userInfo.company}
      onChange={(val) => handleFieldChange(val, "company")}
      required
      label="Company Name"
      type="text"
      sx={modalStyle.inputStyle}
    />
    <MuiPhone
      required
      defaultCountry="us"
      value={userInfo.phoneNumber}
      onChange={(phone) => handleFieldChange(phone, "phoneNumber")}
      error={!!errorMessage}
      helperText={
        errorMessage
          ? errorMessage
          : "We will send a verification code to this phone number, so make sure it is SMS capable."
      }
      sx={{
        ...modalStyle.inputStyle,
        ".MuiFormHelperText-root": {
          fontSize: "11px",
        },
      }}
    />
    <FormGroup sx={{ width: "100%", px: 7, mt: 4 }}>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={userInfo.marketingPreference}
            onChange={(e) =>
              handleFieldChange(e.target.checked, "marketingPreference")
            }
          />
        }
        label="I’d like emails about new features and events from Orkes"
        sx={{
          ".MuiFormControlLabel-label": {
            fontSize: "13px",
          },
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={userInfo.termsAndConditionsAccepted}
            onChange={(e) =>
              handleFieldChange(e.target.checked, "termsAndConditionsAccepted")
            }
          />
        }
        label={
          <Typography fontSize={13}>
            By clicking continue, you agree to our{" "}
            <Link
              target={"_blank"}
              rel={"noreferrer noopener"}
              href="https://orkes.io/cloud-services-agreement/"
              sx={commonStyle.linkStyle}
            >
              Orkes Cloud Service Agreement
            </Link>
          </Typography>
        }
      />
    </FormGroup>
  </>
);
