import { DoneInvokeEvent, assign } from "xstate";
import {
  AcceptInviteEvent,
  AuthProviderContext,
  ErrorMessageType,
  VerificationStatus,
} from "./types";
import { UserOrganization } from "utils/query";
import { User } from "firebase/auth";
import { BraavosBillingProfile, UserProfileType } from "utils/saastypes";

export const persistCurrentUser = assign<
  AuthProviderContext,
  DoneInvokeEvent<User>
>({
  currentUser: (
    _context: AuthProviderContext,
    event: DoneInvokeEvent<User>
  ) => ({ ...event.data }),
});

export const persistVerifyEmailMessageType = assign<AuthProviderContext>({
  messageType: ErrorMessageType.VERIFICATION_EMAIL_SENT,
});

export const persistPermissionDeniedMessageType = assign<AuthProviderContext>({
  messageType: ErrorMessageType.PERMISSION_DENIED,
});

export const persistResetPasswordMessageType = assign<AuthProviderContext>({
  messageType: ErrorMessageType.RESET_PASSWORD_LINK_SENT,
});

export const persistMagicLinkMessageType = assign<AuthProviderContext>({
  messageType: ErrorMessageType.MAGIC_LINK_SENT,
});

export const persistEmailNotVerifiedMessage = assign<AuthProviderContext>({
  messageType: ErrorMessageType.EMAIL_NOT_VERIFIED,
});

export const clearMessageType = assign<AuthProviderContext>({
  messageType: undefined,
});

export const persistVerification = assign<
  AuthProviderContext,
  DoneInvokeEvent<VerificationStatus>
>({
  verificationStatus: (
    _context: AuthProviderContext,
    event: DoneInvokeEvent<VerificationStatus>
  ) => event.data,
});

export const clearLoginEmail = () => {
  localStorage.removeItem("emailForSignIn");
};

export const persistInviteInfo = assign<AuthProviderContext, AcceptInviteEvent>(
  {
    inviteId: (
      _context: AuthProviderContext,
      { inviteId }: AcceptInviteEvent
    ) => inviteId,
  }
);

export const cleanInvitation = assign<AuthProviderContext>({
  inviteId: undefined,
});

export const persistUserOrganization = assign<
  AuthProviderContext,
  DoneInvokeEvent<UserOrganization>
>({
  currentOrganization: (_context, { data }) => data,
});

export const persistBillingProfile = assign<
  AuthProviderContext,
  DoneInvokeEvent<BraavosBillingProfile>
>({
  billingProfile: (_context, { data }) => data,
});

export const persistUserProfiles = assign<
  AuthProviderContext,
  DoneInvokeEvent<{
    availableProfiles: UserProfileType[];
    currentProfile: UserProfileType;
  }>
>((_context, { data: { availableProfiles, currentProfile } }) => ({
  availableProfiles,
  currentProfile,
}));

export const cleanup = assign<AuthProviderContext>((_context) => ({
  auth0: undefined,
  inviteId: undefined,
  currentUser: undefined,
  verificationStatus: undefined,
  currentOrganization: undefined,
  billingProfile: undefined,
  messageType: undefined,
  currentUserRoles: undefined,
  availableProfiles: undefined,
  currentProfile: undefined,
}));
