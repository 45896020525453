import { UIEvent, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "auth/AuthContext";
import {
  Autocomplete,
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { User } from "@auth0/auth0-spa-js";
import { faCaretSquareDown } from "@fortawesome/free-solid-svg-icons";
import { colorOverrides } from "theme/colorOverrides";
import { UserProfile, userProfileLabel } from "components/profile/UserProfile";
import {
  UserOrganization,
  useClusterListing,
  useUserOrganizations,
} from "utils/query";
import LogOutIcon from "components/icons/LogOutcon";
import UserIcon from "components/icons/UserIcon";
import AlertDialog from "./AlertDialog";
import { useAuthMachine } from "auth/state/hook";

export default function AppBarModules() {
  const { authService } = useAuth();

  const [
    {
      currentUser,
      currentOrganization,
      loggedWithOrganization,
      isSwitchingOrganization,
      currentProfile,
      availableProfiles,
      isLoadingState,
    },
    { handleLogOut, switchCurrentOrganization, switchCurrentProfile },
  ] = useAuthMachine(authService);

  useClusterListing(); //  Having this here will refresh the cluster on organization change

  const { data: userOrganizations } = useUserOrganizations(); // This will refresh when the profile is changed

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [selectedOrg, setSelectedOrg] = useState<
    UserOrganization | null | undefined
  >(null);

  const [userProfileAnchorEl, setUserProfileAnchorEl] =
    useState<Element | null>(null);

  const [isPhotoUrlError, setIsPhotoUrlError] = useState(false);

  const handleClick = (event: UIEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const greaterThanMinWidth = useMediaQuery(theme.breakpoints.up(1250));
  const greaterThanSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const getUserName = (currentUser: User) => {
    const userName = currentUser.email ? currentUser?.email.split("@")[0] : "";
    return currentUser && (currentUser.displayName || userName);
  };

  const getAvatarText = (currentUser: User) => {
    const name = getUserName(currentUser);
    return name?.substring(0, 1);
  };

  const handleOpenUserProfile = (event: UIEvent) => {
    setUserProfileAnchorEl(event.currentTarget);
  };

  const handleCloseUserProfile = () => {
    setUserProfileAnchorEl(null);
  };

  const currentProfileText = currentProfile
    ? userProfileLabel[currentProfile]
    : "View as";

  const isViewAsHidden = useMemo(
    () => (availableProfiles ?? []).length === 0,
    [availableProfiles]
  );

  const menuItems = [
    {
      label: currentProfileText,
      icon: <UserIcon />,
      onClick: handleOpenUserProfile,
      hidden: isViewAsHidden,
    },
    {
      label: "Logout",
      icon: <LogOutIcon />,
      onClick: handleLogOut,
      hidden: greaterThanSmall,
    },
  ];

  const buttonStyle: SxProps = {
    p: 0,
    color: colorOverrides.leftBlue,
    opacity: 0.8,
    fontSize: "16px",
    fontWeight: 200,
    textTransform: "none",
    minWidth: "auto",
    "&:hover": {
      opacity: 1,
      backgroundColor: "unset",
    },
    ".MuiButton-startIcon": {
      mr: greaterThanMinWidth ? 1.5 : 0,
    },
  };

  const filterOptions = (
    options: UserOrganization[],
    { inputValue }: { inputValue: string }
  ) => {
    const trimmedInput = inputValue?.trim();
    return options.filter(
      (option: UserOrganization) =>
        option.name.toLowerCase().includes(trimmedInput.toLowerCase()) ||
        option.id.includes(trimmedInput)
    );
  };

  return loggedWithOrganization || isSwitchingOrganization ? (
    <>
      <AlertDialog
        textTitle={"Switch Organization Confirmation"}
        textContent={
          <Typography component="span">
            Please confirm to switch to{" "}
            <b>{selectedOrg?.name || "the selected organization"}</b>{" "}
            organization. Once request is successful, you will be redirected to
            home page.
          </Typography>
        }
        open={Boolean(
          selectedOrg != null &&
            currentOrganization?.id != null &&
            selectedOrg.id !== currentOrganization.id
        )}
        setOpen={() => setSelectedOrg(currentOrganization ?? null)}
        handleConfirm={() => {
          // @ts-ignore
          if (selectedOrg != null) {
            switchCurrentOrganization(selectedOrg?.id);
            setSelectedOrg(null);
          }
        }}
        confirmLabel={isSwitchingOrganization ? "Saving..." : "Confirm"}
        isConfirmDisabled={isSwitchingOrganization}
      />
      <Stack
        flexDirection="row"
        justifyContent="end"
        alignItems="center"
        gap={5}
        sx={{ alignSelf: "center" }}
      >
        {userOrganizations != null && userOrganizations.length > 1 && (
          <Autocomplete
            value={currentOrganization || null}
            onChange={(_event, newValue) => {
              setSelectedOrg(newValue);
            }}
            options={userOrganizations.sort((orgA, orgB) =>
              orgA.name?.localeCompare(orgB.name)
            )}
            getOptionLabel={(option) => option?.name ?? option.id}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              );
            }}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Switch Organization"
                variant="outlined"
                size="small"
                sx={{ width: 300 }}
              />
            )}
          />
        )}
        {greaterThanSmall &&
          menuItems.map(({ label, hidden, icon, onClick }) =>
            hidden ? null : (
              <Button
                key={label}
                disableTouchRipple
                sx={buttonStyle}
                startIcon={icon}
                onClick={onClick}
              >
                {greaterThanMinWidth ? label : ""}
              </Button>
            )
          )}

        {currentUser.photoURL && !isPhotoUrlError ? (
          <img
            src={currentUser.photoURL}
            alt="logo"
            style={{ borderRadius: "50%", width: 35, height: 35 }}
            onError={() => setIsPhotoUrlError(true)}
          />
        ) : (
          <Avatar sx={{ width: 40, height: 40 }}>
            <span style={{ fontSize: 18, fontWeight: 500, color: "white" }}>
              {getAvatarText(currentUser)}
            </span>
          </Avatar>
        )}

        {greaterThanSmall ? (
          <>
            <Stack ml={-4}>
              <Typography color={colorOverrides.leftBlue}>
                {currentUser?.displayName ?? currentOrganization?.userName}
              </Typography>
              <Typography color={colorOverrides.leftBlue} fontSize={12}>
                {currentUser?.email}
              </Typography>
            </Stack>
            <IconButton
              onClick={handleLogOut}
              size="small"
              sx={{ ml: -5, alignSelf: "center" }}
            >
              <LogOutIcon />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: -5, alignSelf: "center" }}
            aria-controls="basic-menu"
          >
            <FontAwesomeIcon
              style={{ color: colorOverrides.leftBlue }}
              icon={faCaretSquareDown}
            />
          </IconButton>
        )}
      </Stack>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              marginTop: "35px",
              border: "1px solid gray",
              "& li": {
                fontSize: "14px",
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>Signed in as {getUserName(currentUser)}</MenuItem>
        <Divider />
        {menuItems.map(({ label, icon, onClick, hidden }) =>
          hidden ? null : (
            <MenuItem key={label} onClick={onClick}>
              {<ListItemIcon>{icon}</ListItemIcon>}
              {label}
            </MenuItem>
          )
        )}
      </Menu>

      <UserProfile
        anchorEl={userProfileAnchorEl}
        onClick={handleCloseUserProfile}
        onClose={handleCloseUserProfile}
        allProfiles={availableProfiles ?? []}
        isLoading={isLoadingState}
        currentProfile={currentProfile}
        onItemClick={(profile) => {
          // @ts-ignore
          switchCurrentProfile(profile);
        }}
      />
    </>
  ) : null;
}
