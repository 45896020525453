import {
  BraavosSubscription,
  CompatibilityDto,
  SupportType,
} from "./saastypes";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import { SelectItem } from "./types";
import { ReleaseDto, Role, State, TelemetryType } from "utils/saastypes";
import { CLUSTER_STATE } from "constants/common";
import DevelopmentEnvironment from "images/svg/chip/development-environment.svg";
import OrkesTestEnvironment from "images/svg/chip/orkes-test-environment.svg";
import POCEnvironment from "images/svg/chip/poc-environment.svg";
import StagingEnvironment from "images/svg/chip/staging-environment.svg";
import ProductionEnvironment from "images/svg/chip/production-environment.svg";
import TestEnvironment from "images/svg/chip/test-environment.svg";
import StarterClusterIcon from "images/svg/chip/starter-cloud-cluster.svg";
import StandardClusterIcon from "images/svg/chip/standard-cloud-cluster.svg";
import CustomUnitIcon from "images/svg/chip/custom-unit-computation.svg";
import OneUnitIcon from "images/svg/chip/1-unit-computation.svg";
import TwoUnitsIcon from "images/svg/chip/2-units-computation.svg";
import ThreeUnitsIcon from "images/svg/chip/3-units-computation.svg";
import FourUnitsIcon from "images/svg/chip/4-units-computation.svg";
import DisableTelemetry from "images/svg/telemetryState/disabledTelemetry.svg";
import MuteTelemetry from "images/svg/telemetryState/muteTelemetry.svg";
import NormalTelemetry from "images/svg/telemetryState/normalTelemetry.svg";
import AuditTelemetry from "images/svg/telemetryState/auditTelemetry.svg";
import DebugTelemetry from "images/svg/telemetryState/debugTelemetry.svg";
import { SEMVER_REGEX } from "./regex";
/**
 * When there are validation errors in the request payload the backend may respond with something like:
 * {
 *  "message": "Bad request",
 *  "errors": [ { "field": "name", "message": "must not be blank"} ]
 * }
 *
 * This functions returns an object with the errors as properties e.g.
 * { name: "must not be blank" }
 *
 * @param response Fetch response object
 * @returns if "errors" exists in the response an object which properties are the errors.
 */
export const getErrors = async (response: Response) => {
  const body = await response.clone().json();

  if (body.message) {
    return { message: body.message };
  }

  if (_isEmpty(body.errors)) {
    throw Object.assign(Error("No errors in response"), { body });
  }

  return Object.assign(
    {},
    ...body.errors.map((error: { field: string; message: string }) => ({
      [error.field]: error.message,
    }))
  );
};

export const GENERIC_ERROR = "Error performing action";

export const defaultGenericErrorHandler = (response: Response) => ({
  message: `${GENERIC_ERROR} - Status code: ${response.status} - Status text: ${response.statusText}`,
});

export const getErrorsMessage = async (response: any) => {
  if (typeof response === "string") {
    return response;
  }

  const contentType = response.headers.get("content-type");

  if (!_isNil(contentType) && contentType.indexOf("application/json") !== -1) {
    try {
      const errorObj = await getErrors(response);

      if (errorObj.message) {
        return errorObj.message;
      }
    } catch (error) {
      console.error("[getErrorsMessage] error: ", error);
    }
  }

  return defaultGenericErrorHandler(response).message;
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getTitleSuffix = (type: string, id: string) => {
  if (type) {
    return ` - ${capitalizeFirstLetter(type)}` + (id ? ` - ${id}` : "");
  }
  return "";
};

export function getValueForAutocomplete(value: SelectItem) {
  if (Array.isArray(value)) {
    return value.map((item) => item.id);
  }
  return value === null ? null : value.id;
}

export const clusterNameExp = /^[a-zA-Z0-9][a-zA-Z0-9\\-]*$/;

export const isValidClusterName = (name: string | undefined) => {
  if (name) {
    return clusterNameExp.test(name);
  }
};

export const customStyles = {
  rows: {
    style: {},
  },
  head: {
    style: {
      color: "#6f7a92",
      fontSize: "14px",
      fontFamily: "Lexend, sans-serif",
      fontWeight: 500,
    },
  },
  headCells: {
    style: {},
  },
  cells: {
    style: {},
  },
};

export const getAPIErrorMessage = (json: any) => {
  return json.errors
    ? json.errors.reduce(
        (message: string, currentError: any, index: number) => {
          if (currentError.message) {
            return message.concat(
              index === 0 ? "" : " | ",
              currentError.message
            );
          }

          return message;
        },
        ""
      )
    : json.message;
};

export const getColorFromState = (state: State) => {
  switch (state) {
    case CLUSTER_STATE.DELETED:
    case CLUSTER_STATE.DELETING:
    case CLUSTER_STATE.SCHEDULE_DELETION:
    case CLUSTER_STATE.FAILED:
      return "error";
    case CLUSTER_STATE.MODIFYING:
    case CLUSTER_STATE.PROVISIONING:
      return "primary";
    case CLUSTER_STATE.PROVISIONED:
      return "success";
    default:
      return "info";
  }
};

export const getResponseErrorMessage = async (error: Response) => {
  const isJSON = error.headers
    .get("content-type")
    ?.includes("application/json");
  const response = isJSON ? await error.json() : await error.text();

  return isJSON ? response?.message : response;
};

export const hasAnyRole = (userRoles: Role[], allowedRoles: Role[]) => {
  return userRoles?.findIndex((role) => allowedRoles.includes(role)) > -1;
};

export const resolveVersion = (version: string): string => {
  return version?.replace(/.+:/g, "") || "N/A";
};

export const calculateTimeFromMillis = (timeInSeconds: number) => {
  let totalTime = timeInSeconds >= 0 ? timeInSeconds : 0;
  const perDay = 24 * 60 * 60;
  const perHour = 60 * 60;
  const perMinute = 60;

  const days = Math.floor(totalTime / perDay);

  if (days > 0) {
    totalTime %= days * perDay;
  }

  const hours = Math.floor(totalTime / perHour);

  if (hours > 0) {
    totalTime %= hours * perHour;
  }

  const minutes = Math.floor(totalTime / perMinute);

  if (minutes > 0) {
    totalTime %= minutes * perMinute;
  }

  if (days === 0) {
    if (hours === 0) {
      return `${minutes}m ${totalTime}s`;
    }
    return `${hours}h ${minutes}m ${totalTime}s`;
  }
  return `${days}d ${hours}h ${minutes}m ${totalTime}s`;
};

export const getEnvironmentIcon = (environmentType: string) => {
  switch (environmentType) {
    case "POC":
      return POCEnvironment;
    case "ORKESTEST":
      return OrkesTestEnvironment;
    case "PRODUCTION":
      return ProductionEnvironment;
    case "TEST":
      return TestEnvironment;
    case "DEVELOPMENT":
      return DevelopmentEnvironment;
    case "STAGING":
      return StagingEnvironment;
    default:
      return OrkesTestEnvironment;
  }
};

export const getClusterCapacityIcon = (clusterCapacityId: string) => {
  switch (clusterCapacityId) {
    case "1":
      return StandardClusterIcon;
    case "99":
      return StarterClusterIcon;
    default:
      return StarterClusterIcon;
  }
};

export const getComputeUnitIcon = (computeUnitIdOrNumber: string | number) => {
  const computeUnitId =
    typeof computeUnitIdOrNumber === "number"
      ? computeUnitIdOrNumber.toString()
      : computeUnitIdOrNumber;

  switch (computeUnitId) {
    case "1":
      return OneUnitIcon;

    case "2":
      return TwoUnitsIcon;

    case "3":
      return ThreeUnitsIcon;

    case "4":
      return FourUnitsIcon;

    default:
      return CustomUnitIcon;
  }
};

export const optionalPropertySort = ({
  rowA,
  rowB,
  selector,
}: {
  rowA: any;
  rowB: any;
  selector: string;
}) => {
  const a = rowA?.[selector];
  const b = rowB?.[selector];

  if (_isNil(a)) return 1; // Optional property handling

  if (_isNil(b)) return -1; // Optional property handling

  if (a > b) return 1;

  if (b > a) return -1;

  return 0;
};

export const getTelemetryStateIcon = (telemetryType: TelemetryType) => {
  switch (telemetryType) {
    case "DISABLED":
      return DisableTelemetry;
    case "SUPPRESSED":
      return MuteTelemetry;
    case "AUDIT":
      return AuditTelemetry;
    case "DEBUG":
      return DebugTelemetry;
    default:
      return NormalTelemetry;
  }
};

// release version sorting

const getVersionNumber = (version: string) =>
  version.split("-")[0].split(".").map(Number);

const getRCInfo = (version: string) => version.split("-")[1] || "";

const isRCVersion = (version: string) => getRCInfo(version) !== "";

export const sortByVersion = (rowA: ReleaseDto, rowB: ReleaseDto) => {
  const versionA = rowA.version;
  const versionB = rowB.version;
  const versionAComponent = getVersionNumber(versionA);
  const versionBComponent = getVersionNumber(versionB);

  const versionComparison = versionBComponent.reduce(
    (result, comp, i) => result || comp - versionAComponent[i],
    0
  );

  if (versionComparison !== 0) {
    return versionComparison;
  }

  // If version number are equal, prioritize the main version over RC version
  if (isRCVersion(versionA) && !isRCVersion(versionB)) {
    return 1;
  } else if (!isRCVersion(versionA) && isRCVersion(versionB)) {
    return -1;
  }

  // compare RC version
  return (getRCInfo(versionB) || "0").localeCompare(
    getRCInfo(versionA) || "0",
    undefined,
    { numeric: true, sensitivity: "base" }
  );
};

export const isVersionInRange = ({
  version,
  rangeStart,
  rangeEnd,
}: {
  version: string;
  rangeStart: string;
  rangeEnd: string;
}): boolean => {
  const parsedVersion = version.split(".").map(Number);
  const parsedRangeStart = rangeStart.split(".").map(Number);
  const parsedRangeEnd = rangeEnd.split(".").map(Number);

  // Check if the version is greater than or equal to the range start
  if (parsedVersion < parsedRangeStart) {
    return false;
  }

  // Check if the version is less than the range end
  if (parsedVersion >= parsedRangeEnd) {
    return false;
  }

  return true;
};

export const getValidVersionRange = ({
  version,
  compatibilityVersionList,
}: {
  version: string;
  compatibilityVersionList: CompatibilityDto[];
}) => {
  return compatibilityVersionList.reduce(
    (tempResult, currentValue) => {
      const { from, to } = currentValue;

      if (
        isVersionInRange({
          version,
          rangeStart: from.version,
          rangeEnd: to.version,
        })
      ) {
        return { rangeStart: from.version, rangeEnd: to.version };
      }

      return tempResult;
    },
    { rangeStart: "0.0.0", rangeEnd: "0.0.0" }
  );
};

export const isValidSemVer = (version: string): boolean =>
  SEMVER_REGEX.test(version);

export function extractSupportInformation(
  subscriptionArray: BraavosSubscription[]
) {
  const supportInfo: SupportType[] = [];

  subscriptionArray.forEach((subscription) => {
    subscription.rates.forEach((rate) => {
      const productName = rate.product.name;

      const matchedSupportType = Object.values(SupportType).find(
        (supportType) =>
          productName.toLowerCase().includes(supportType.toLowerCase())
      );
      if (matchedSupportType) {
        supportInfo.push(matchedSupportType);
      }
    });
  });

  return supportInfo;
}
