import "../App.css";
import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { Login } from "components/Login/Login";
import logo from "../images/branding/orkes-logo-purple-2x.png";
import Footer from "components/Login/LoginFooter";

function LoginPage({ signup = false }: { signup: boolean }) {
  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Login</title>
      </Helmet>
      <Box className={"container"}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginY: 2,
          }}
        >
          <img src={logo} alt="logo" width="300px" />
          <Typography
            sx={{ fontSize: 36, fontWeight: "bold", textAlign: "center" }}
          >
            Orkes Cloud Platform
          </Typography>
        </Box>
        <main
          style={{
            overflowX: "hidden",
            marginTop: 10,
          }}
        >
          <Login signup={signup} />
        </main>
        <Footer />
      </Box>
    </>
  );
}

export default LoginPage;
