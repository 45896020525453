import { Box, Button, Paper, Typography } from "@mui/material";
import { UnlockIcon } from "./icons/UnlockIcon";
import { useNavigate } from "react-router";
import { UserOrganization } from "utils/query";
import { differenceInDays } from "date-fns";

const TRIAL_DAYS = Number(process.env.REACT_APP_TRIAL_DAYS) || 14;

const styles = {
  container: {
    position: "absolute",
    bottom: "16px",
    width: "95%",
    px: 3,
  },
  paper: {
    background: "#D7DCDD",
    px: 5,
    py: 3,
    display: "flex",
    flexDirection: "column",
    gap: 5,
    borderRadius: 2,
  },
  daysBadge: {
    background: "#FCD181",
    fontSize: 10,
    fontWeight: 500,
    px: 2,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
  },
  upgradeCta: {
    textTransform: "none",
    alignSelf: "center",
    fontSize: 12,
  },
};

const calculateTrialLeftDays = (createdDate?: string) => {
  if (createdDate) {
    const targetDate = new Date(createdDate).getTime();
    const today = new Date().getTime();

    const daysPassed = differenceInDays(today, targetDate);
    const daysLeft = TRIAL_DAYS - daysPassed;
    return daysLeft > 0 ? daysLeft : 0;
  }
  return TRIAL_DAYS;
};

export const SidebarTrialCard = ({
  currentOrganization,
}: {
  currentOrganization?: UserOrganization;
}) => {
  const navigate = useNavigate();

  const daysLeft = calculateTrialLeftDays(currentOrganization?.createdAt);

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontSize: 16, fontWeight: 600, letterSpacing: 1.6 }}
          >
            TRIAL
          </Typography>
          <Typography sx={styles.daysBadge}>{daysLeft} days left</Typography>
        </Box>
        <Typography sx={{ whiteSpace: "normal", fontSize: 12 }}>
          You are trialing Enterprise - Base plan with premium features. Upgrade
          now to avoid interruption of service
        </Typography>

        <Button
          variant="contained"
          sx={styles.upgradeCta}
          startIcon={<UnlockIcon />}
          onClick={() => navigate("/home/upgrade")}
        >
          Upgrade Now
        </Button>
      </Paper>
    </Box>
  );
};
