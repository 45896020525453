import { AlertColor } from "@mui/material";
import { taskDescriptions } from "constants/provisioning";
import { useEffect, useMemo } from "react";
import { useWorkflowStatus } from "utils/query";
import {
  ProvisioningStatus,
  TaskStatus,
  WorkflowStatus,
} from "utils/saastypes";
import useInterval from "utils/useInterval";
import _last from "lodash/last";

const ruleOutSystemTasks = (task: { parentTask: string }) =>
  ["SWITCH"].indexOf(task.parentTask) < 0;

const createAlertMessage = (color: AlertColor, text: string) => ({
  color,
  text,
});

const AlertMessage = {
  info: (text: string) => createAlertMessage("info", text),
  warning: (text: string) => createAlertMessage("warning", text),
  error: (text: string) => createAlertMessage("error", text),
  success: (text: string) => createAlertMessage("success", text),
};

const mapString = (text: string | undefined, message: string) =>
  text ? message.replace("{}", text) : "";

const getDescription = (taskStatus?: TaskStatus) =>
  taskStatus?.label ? taskDescriptions.get(taskStatus.label)?.[0] : undefined;

const getSeverity = (workflowStatus?: WorkflowStatus) => {
  if (!workflowStatus) return null;

  const { status, taskStatusList } = workflowStatus;

  const scheduledTask = taskStatusList.find(
    (t) => t.status === ProvisioningStatus.SCHEDULED
  );
  const canceledTask = taskStatusList.find(
    (t) => t.status === ProvisioningStatus.CANCELED
  );
  const failedTask = taskStatusList.find(
    (t) => t.status === ProvisioningStatus.FAILED
  );

  switch (status) {
    case ProvisioningStatus.RUNNING:
      return AlertMessage.info(
        `Provisioning workflow is in progress${mapString(
          getDescription(scheduledTask),
          ": {}"
        )}`
      );

    case ProvisioningStatus.PAUSED:
      return AlertMessage.warning(
        `Provisioning workflow is paused${mapString(
          getDescription(scheduledTask),
          " at: {}"
        )}`
      );

    case ProvisioningStatus.TERMINATED:
      return AlertMessage.error(
        `Provisioning workflow has been terminated${mapString(
          getDescription(canceledTask),
          " at: {}"
        )}`
      );

    case ProvisioningStatus.FAILED:
      return AlertMessage.error(
        `Provisioning workflow has failed${mapString(
          getDescription(failedTask),
          " at: {}"
        )}`
      );

    case ProvisioningStatus.TIMED_OUT:
      return AlertMessage.error("Provisioning workflow has timed out.");

    case ProvisioningStatus.COMPLETED:
      return AlertMessage.success(
        "Provisioning workflow is complete. Your environment is ready for use."
      );

    default:
      return AlertMessage.warning("Unable to determine the workflow status.");
  }
};

export const useTrialClusterProgress = (
  clusterName: string,
  refetchAllClusters: () => void
) => {
  const { data: workflowStatus, refetch: refetchWorkflowStatus } =
    useWorkflowStatus(clusterName);

  useInterval(
    () => {
      refetchWorkflowStatus();
    },
    workflowStatus?.status === ProvisioningStatus.RUNNING ? 5000 : null
  );

  useEffect(() => {
    if (workflowStatus?.status) {
      refetchAllClusters();
    }
  }, [workflowStatus?.status, refetchAllClusters]);

  const alertMessage = getSeverity(workflowStatus);

  const provisioningProgresses = useMemo(
    () => workflowStatus?.taskStatusList?.filter(ruleOutSystemTasks) || [],
    [workflowStatus]
  );

  const completedProgresses = useMemo(
    () =>
      provisioningProgresses?.filter(
        (item) => item.status === ProvisioningStatus.COMPLETED
      ) || [],
    [provisioningProgresses]
  );

  const lastProgress = useMemo(
    () =>
      provisioningProgresses.length !== completedProgresses.length
        ? provisioningProgresses[completedProgresses.length]
        : _last(completedProgresses),
    [provisioningProgresses, completedProgresses]
  );

  const progressValue =
    (completedProgresses.length / provisioningProgresses.length) * 100;

  const progressText = `${alertMessage?.text} - ${completedProgresses.length}/${provisioningProgresses.length}`;

  const progressColor: AlertColor = useMemo(() => {
    switch (lastProgress?.status) {
      case ProvisioningStatus.FAILED:
      case ProvisioningStatus.TERMINATED:
      case ProvisioningStatus.TIMED_OUT:
      case ProvisioningStatus.CANCELED:
        return "error";

      case ProvisioningStatus.RUNNING:
      case ProvisioningStatus.SCHEDULED:
      case ProvisioningStatus.IN_PROGRESS:
        return "info";

      case ProvisioningStatus.COMPLETED:
        return "success";

      default:
        return "warning";
    }
  }, [lastProgress]);

  return {
    alertMessage,
    progressColor,
    progressValue,
    progressText,
    workflowStatus,
  };
};
