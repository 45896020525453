import { useAuthMachine } from "auth/state/hook";
import { UserOrganization } from "./types";
import {
  CustomerEnvironment,
  AgentLogsDto,
  BraavosSubscription,
  UserProfileType,
  InvitedUser,
  User,
  IObject,
} from "../saastypes";
import {
  STALE_TIME_UNLIMITED,
  STALE_TIME_30_SECONDS,
  STALE_TIME_10_MINS,
} from "./constants";
import { RefetchFunction } from "./types";
import { useAuth } from "../AuthProvider";
import { useFetch } from "./query";
import { isNotNilNorEmpty, isValidUUID } from "./helpers";
import _isArray from "lodash/isArray";
import { CLUSTER_ACTION_TYPE } from "constants/common";
import {
  FETCH_BRAAVOS_SUBSCRIPTION,
  FETCH_AVAILABLE_USER_PROFILES,
  FETCH_CURRENT_USER_PROFILE,
} from "../apiPaths";

export function useUserOrganizations(options?: object): {
  data: UserOrganization[] | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  isError: any;
} {
  const { authService } = useAuth();
  const [{ loggedWithOrganization, currentProfile }] =
    useAuthMachine(authService);

  const { data, ...rest } = useFetch<UserOrganization[]>(
    ["/userOrganizations", String(currentProfile)],
    {
      ...options,
      enabled: loggedWithOrganization,
      staleTime: STALE_TIME_UNLIMITED,
    }
  );

  return {
    data: data,
    ...rest,
  };
}

export function useClusterDetail(clusterName: string): {
  data: CustomerEnvironment | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { authService } = useAuth();
  const [{ loggedWithOrganization }] = useAuthMachine(authService);
  const { data, ...rest } = useFetch<CustomerEnvironment>(
    `/getCustomerCluster?clusterName=${clusterName}`,
    {
      staleTime: STALE_TIME_30_SECONDS,
      enabled: loggedWithOrganization,
      errorMessage: "Could not fetch cluster:",
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useClusterListing(options?: object): {
  data: CustomerEnvironment[] | undefined;
  isFetching: any;
  refetch: RefetchFunction;
} {
  const { authService } = useAuth();
  const [{ loggedWithOrganization, currentOrganization }] =
    useAuthMachine(authService);
  const { data, ...rest } = useFetch<CustomerEnvironment[]>(
    ["/getCustomerClusters"].concat(String(currentOrganization?.id)), // We want the cluster listing refreshed on organization change
    {
      ...options,
      enabled: loggedWithOrganization,
      staleTime: STALE_TIME_UNLIMITED,
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useAgentLogs(
  clusterName: string,
  page = 1,
  size = 15,
  command?: string
): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { authService } = useAuth();
  const [{ isLoggedInAndVerified }] = useAuthMachine(authService);

  const commandParam = isNotNilNorEmpty(command)
    ? `&command=${command}`
    : `&command=${[
        CLUSTER_ACTION_TYPE.UPGRADE_DEPLOYMENT,
        CLUSTER_ACTION_TYPE.SCALE_DEPLOYMENT,
        CLUSTER_ACTION_TYPE.ADD_EKS_ADMIN,
        CLUSTER_ACTION_TYPE.PULL_LOGS,
        CLUSTER_ACTION_TYPE.DELETE_POD,
        CLUSTER_ACTION_TYPE.ROLLOUT_RESTART,
        CLUSTER_ACTION_TYPE.ROLLBACK_DEPLOYMENT,
      ].join(",")}`;
  const { data, ...rest } = useFetch<AgentLogsDto>(
    `/agent/workflowsResults/${clusterName}?page=${page}&size=${size}${commandParam}`,
    {
      enabled: isLoggedInAndVerified,
    }
  );
  return {
    data: data,
    ...rest,
  };
}

export function useBraavosSubscription() {
  const { authService } = useAuth();
  const [{ loggedWithOrganization }] = useAuthMachine(authService);
  const result = useFetch<BraavosSubscription[]>(FETCH_BRAAVOS_SUBSCRIPTION, {
    enabled: loggedWithOrganization,
    staleTime: STALE_TIME_10_MINS,
  });
  return result;
}

export function useInvitedUsers(options?: object): {
  data: InvitedUser[];
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { authService } = useAuth();
  const [{ loggedWithOrganization, hasAdminOrRootRole }] =
    useAuthMachine(authService);
  const { data, ...rest } = useFetch(`/invitedUsers`, {
    ...options,
    enabled: loggedWithOrganization && hasAdminOrRootRole,
    staleTime: STALE_TIME_UNLIMITED,
  });
  return {
    data: data as InvitedUser[],
    ...rest,
  };
}

export function useCurrentUsers(options?: object) {
  const { authService } = useAuth();
  const [{ loggedWithOrganization, hasAdminOrRootRole }] =
    useAuthMachine(authService);
  const { data, ...rest } = useFetch<User[]>("/users", {
    ...options,
    enabled: loggedWithOrganization && hasAdminOrRootRole,
    staleTime: STALE_TIME_UNLIMITED,
  });
  return {
    data: data,
    ...rest,
  };
}

export function useFetchAvailableUserProfiles(option?: IObject) {
  const { authService } = useAuth();
  const [{ loggedWithOrganization }] = useAuthMachine(authService);
  return useFetch<{ available_user_profiles: UserProfileType[] }>(
    FETCH_AVAILABLE_USER_PROFILES,
    {
      ...option,
      enabled: loggedWithOrganization,
      staleTime: STALE_TIME_30_SECONDS,
    }
  );
}

export function useFetchCurrentUserProfile(option?: IObject) {
  const { authService } = useAuth();
  const [{ loggedWithOrganization }] = useAuthMachine(authService);

  return useFetch<{ current_user_profile: UserProfileType }>(
    FETCH_CURRENT_USER_PROFILE,
    {
      ...option,
      enabled: loggedWithOrganization,
      staleTime: STALE_TIME_30_SECONDS,
    }
  );
}

export function useUserIds(
  ids: Array<string>,
  options: Record<string, any> = {}
): {
  data: any | undefined;
  isFetching: any;
  refetch: RefetchFunction;
  error: any;
} {
  const { authService } = useAuth();
  const [{ loggedWithOrganization }] = useAuthMachine(authService);

  const filteredIds = ids
    .filter((id) => isNotNilNorEmpty(id) && isValidUUID(id))
    .sort(); // Sorting so we at least can have some cache
  const { data, ...rest } = useFetch(
    `/users/getByIds?id=${filteredIds.join("&id=")}`,
    {
      enabled:
        loggedWithOrganization &&
        _isArray(filteredIds) &&
        filteredIds.length > 0,
      ...options,
    }
  );
  return {
    data: data,
    ...rest,
  };
}
