import { useContext, useEffect, useMemo, useState } from "react";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Header } from "./Header";
import { Link, Outlet } from "react-router-dom";
import {
  BusinessRounded,
  FeedbackRounded,
  StorageRounded,
  NumbersRounded,
} from "@mui/icons-material";
import { colorOverrides } from "theme/colorOverrides";
import {
  ListItemButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppLogo from "./AppLogo";
import { useAuth } from "auth/AuthContext";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import TokenIcon from "./icons/TokenIcon";
import ChatIcon from "./icons/ChatIcon";
import HelpIcon from "./icons/HelpIcon";
import SlackIcon from "./icons/SlackIcon";
import { MessageContext } from "utils/MessageProvider";
import { OrganizationType, SupportType } from "utils/saastypes";
import { SupportModal } from "./SupportModal/SupportModal";
import { useAuthMachine } from "auth/state/hook";
import { SidebarTrialCard } from "./SidebarTrialCard";
import { useBraavosSubscription } from "utils/query";
import { extractSupportInformation } from "utils/utils";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(18)} + 1px)`, // (18 x 4) + 1 = 72px
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(18)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function PostLoginContent() {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(true);
  const [supportModalActive, setSupportModal] = useState(false);

  useEffect(() => {
    if (isScreenSmall) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isScreenSmall]);

  const { authService } = useAuth();
  const [{ isOrkesUser, currentUser, organizationType, currentOrganization }] =
    useAuthMachine(authService);

  const toggleLeftMenu = () => {
    setOpen(!open);
  };

  const currentYear = new Date().getFullYear();

  const { setMessage } = useContext(MessageContext);

  const handleCopyToken = () => {
    const token = currentUser?.accessToken
      ? currentUser.accessToken
      : "NO TOKEN";
    navigator.clipboard.writeText(token);
    setMessage({ text: "Copied!", severity: "success" });
  };

  // const { data: currentOrganization } = useCurrentOrganization();
  const { data: braavosSubscription } = useBraavosSubscription();

  const availableSupportPlan = useMemo(() => {
    console.log("bravossubscription", braavosSubscription);
    if (braavosSubscription) {
      const supportPlan = extractSupportInformation(braavosSubscription);
      return supportPlan.length ? supportPlan : [SupportType.BASIC];
    }
  }, [braavosSubscription]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        className={"leftDrawer"}
        sx={{ position: "relative" }}
      >
        <Link to={"/home"} style={{ textDecoration: "none", opacity: 1 }}>
          <AppLogo open={open} />
        </Link>
        <List
          sx={{
            "& a": {
              paddingTop: "15px",
            },
            color: colorOverrides.white,
            "& svg": {
              fontSize: "26px",
              color: colorOverrides.white,
            },
            "& span": {
              fontSize: "15px",
              color: colorOverrides.white,
            },
          }}
        >
          <Tooltip title={open ? "" : "Clusters"} arrow placement="right">
            <ListItemButton
              key={"Clusters"}
              component={Link}
              to={"/home/clusters"}
            >
              <ListItemIcon>
                <StorageRounded />
              </ListItemIcon>
              <ListItemText primary={"Clusters"} />
            </ListItemButton>
          </Tooltip>

          <Tooltip
            title={open ? "" : "Organization Settings"}
            arrow
            placement="right"
          >
            <ListItemButton
              key={"Organization Settings"}
              component={Link}
              to={"/home/organization-setting"}
            >
              <ListItemIcon>
                <BusinessRounded />
              </ListItemIcon>
              <ListItemText primary={"Organization Settings"} />
            </ListItemButton>
          </Tooltip>
          {isOrkesUser && (
            <Tooltip
              title={open ? "" : "Permitted Users"}
              arrow
              placement="right"
            >
              <ListItemButton
                key={"Permitted Users"}
                component={Link}
                to={"/home/permitted/users"}
              >
                <ListItemIcon>
                  <FactCheckIcon />
                </ListItemIcon>
                <ListItemText primary={"Permitted Users"} />
              </ListItemButton>
            </Tooltip>
          )}
          {isOrkesUser && (
            <Tooltip title={open ? "" : "Versions"} arrow placement="right">
              <ListItemButton
                key={"Versions"}
                component={Link}
                to={"/home/versions"}
              >
                <ListItemIcon>
                  <NumbersRounded />
                </ListItemIcon>
                <ListItemText primary={"Versions"} />
              </ListItemButton>
            </Tooltip>
          )}
        </List>
        <Divider />
        <List
          sx={{
            "& a": {
              paddingTop: "15px",
            },
            color: colorOverrides.white,
            "& svg": {
              fontSize: "26px",
              color: colorOverrides.white,
            },
            "& span": {
              fontSize: "15px",
              color: colorOverrides.white,
            },
          }}
        >
          <Tooltip title={open ? "" : "Feedback"} arrow placement="right">
            <ListItemButton
              key={"Feedback"}
              component={Link}
              to="https://portal.productboard.com/ywqhpnhka6tykd6zpfhrnhwl"
              target={"noopener noreferrer"}
            >
              <ListItemIcon>
                <FeedbackRounded />
              </ListItemIcon>
              <ListItemText primary={"Feedback"} />
            </ListItemButton>
          </Tooltip>
          {organizationType === OrganizationType.ENTERPRISE ||
          organizationType === OrganizationType.FREE_TRIAL ? (
            <Tooltip title={open ? "" : "Support"} arrow placement="right">
              <ListItemButton
                key={"Support"}
                onClick={() => setSupportModal(true)}
                sx={{ pt: "15px" }}
              >
                <ListItemIcon>
                  <ChatIcon size={24} />
                </ListItemIcon>
                <ListItemText primary={"Support"} />
              </ListItemButton>
            </Tooltip>
          ) : null}
          <Tooltip title={open ? "" : "Docs"} arrow placement="right">
            <ListItemButton
              key={"Docs"}
              component={Link}
              to="https://orkes.io/content/"
              target={"noopener noreferrer"}
              sx={{ pt: "15px" }}
            >
              <ListItemIcon>
                <HelpIcon size={24} />
              </ListItemIcon>
              <ListItemText primary={"Docs"} />
            </ListItemButton>
          </Tooltip>
          <Tooltip title={open ? "" : "Slack"} arrow placement="right">
            <ListItemButton
              key={"Slack"}
              component={Link}
              to="https://orkes-conductor.slack.com/join/shared_invite/zt-2hmxn0i3n-_W~a9rWMbvMoYmlJo3Y15g"
              target={"noopener noreferrer"}
              sx={{ pt: "15px" }}
            >
              <ListItemIcon>
                <SlackIcon size={22} />
              </ListItemIcon>
              <ListItemText primary={"Slack"} />
            </ListItemButton>
          </Tooltip>
          {(isOrkesUser ||
            organizationType === OrganizationType.ENTERPRISE) && (
            <Tooltip title={open ? "" : "Copy Token"} arrow placement="right">
              <ListItemButton
                key={"Copy Token"}
                onClick={handleCopyToken}
                sx={{ pt: "15px" }}
              >
                <ListItemIcon>
                  <TokenIcon size={22} />
                </ListItemIcon>
                <ListItemText primary={"Copy Token"} />
              </ListItemButton>
            </Tooltip>
          )}
        </List>

        {open && organizationType === OrganizationType.FREE_TRIAL && (
          <SidebarTrialCard currentOrganization={currentOrganization} />
        )}
      </Drawer>
      <SupportModal
        open={supportModalActive}
        onClose={() => setSupportModal(false)}
        availableSupportPlan={availableSupportPlan}
      />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        style={{
          marginTop: 0,
          padding: 0,
          backgroundColor: "#f9f9f9",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          handleDrawerOpen={toggleLeftMenu}
          organizationName={currentOrganization?.name}
        />
        <div style={{ flexGrow: 1 }}>
          <Outlet />
        </div>
        <footer className="innerFooter">
          © {currentYear} Orkes Inc. - Orkes, Orkes logo are registered
          trademarks of Orkes Inc.
          <a
            target={"_blank"}
            rel={"noreferrer noopener"}
            href="https://orkes.io/terms-and-conditions/"
          >
            Terms and Conditions
          </a>
          <a
            target={"_blank"}
            rel={"noreferrer noopener"}
            href="https://orkes.io/privacy-policy/"
          >
            Privacy Policy
          </a>
          <a
            target={"_blank"}
            rel={"noreferrer noopener"}
            href="https://orkes.io/legal/"
          >
            Legal
          </a>
        </footer>
      </Box>
    </Box>
  );
}
