import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import ActionButton from "components/buttons/ActionButton";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { ModalStep, modalStyle, stepTitles } from "./common";
import { PersonalInfoStep } from "./PersonalInfoStep";
import { PhoneVerificationStep } from "./PhoneVerificationStep";
import { RegionSelectionStep } from "./RegionSelectionStep";
import { TrialClusterConfirmationStep } from "./TrialClusterConfirmationStep";
import { useWelcomeModal } from "./useWelcomeModal";
import { FC } from "react";
import LogOutIcon from "components/icons/LogOutcon";
import { useAuth } from "auth/AuthContext";
import { useAuthMachine } from "auth/state/hook";

const DialogTitleContent = ({
  step,
  uiUrl,
}: {
  step: ModalStep;
  uiUrl: string | null;
}) => {
  return step === ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP && !uiUrl ? (
    <>
      Cluster Provisioning in Progress!
      <Typography sx={{ fontSize: "14px", marginTop: 5, textAlign: "center" }}>
        Click on Manage Cluster to check the progress.
      </Typography>
    </>
  ) : (
    <>
      {stepTitles[step].title}
      <Typography sx={{ fontSize: "14px", marginTop: 5 }}>
        {stepTitles[step].description}
      </Typography>
    </>
  );
};

interface UserModalProps {
  refetchVerifyUserStatus: () => void;
}

export const UserInfoModal: FC<UserModalProps> = ({
  refetchVerifyUserStatus,
}) => {
  const { authService } = useAuth();
  const [{ currentOrganization, currentUser }, { handleLogOut }] =
    useAuthMachine(authService);

  const displayName = currentUser?.displayName
    ? currentUser.displayName
    : currentOrganization?.userName;
  const [
    {
      open,
      userInfo,
      currentStep,
      buttonState,
      uiUrl,
      selectedRegion,
      countdown,
      verificationCode,
      otpVerificationInProgress,
      errorMessage,
    },
    {
      handleContinue,
      handleCloseModal,
      sendOtpCode,
      handleFieldChange,
      handleVerificationCode,
      handleSelectedRegion,
      goBackToPersonalInfoStep,
    },
  ] = useWelcomeModal({
    displayName,
    organizationName: currentOrganization?.name,
  });

  const stepContents = [
    <PersonalInfoStep
      displayName={displayName}
      userInfo={userInfo}
      handleFieldChange={handleFieldChange}
      errorMessage={errorMessage}
    />,
    <PhoneVerificationStep
      verificationCode={verificationCode}
      handleVerificationCode={handleVerificationCode}
      resendVerificationCode={sendOtpCode}
      countdown={countdown}
      buttonProgress={otpVerificationInProgress}
      errorMessage={errorMessage}
    />,
    <RegionSelectionStep
      selectedRegion={selectedRegion}
      handleSelectedRegion={handleSelectedRegion}
    />,
    <TrialClusterConfirmationStep
      onClose={() => {
        refetchVerifyUserStatus(); // Will yield a verification. which (for now) means it will refresh the current organization
        // This should be migrated to xstate
        handleCloseModal();
      }}
      uiUrl={uiUrl}
    />,
  ];

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      {currentStep !== ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP && (
        <Button
          disableRipple
          startIcon={<LogOutIcon size={18} />}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            textTransform: "none",
            color: "#ABAAAA",
            fontWeight: 500,
            fontSize: 11,
          }}
          onClick={handleLogOut}
        >
          Logout
        </Button>
      )}
      <DialogTitle sx={modalStyle.dialogTitle}>
        <DialogTitleContent step={currentStep} uiUrl={uiUrl} />
      </DialogTitle>
      <DialogContent sx={modalStyle.dialogContent}>
        {stepContents[currentStep]}
      </DialogContent>
      <DialogActions sx={{ px: 13 }}>
        {currentStep === ModalStep.PHONE_VERIFICATION_STEP && (
          <ActionButton
            variant="text"
            sx={{ textTransform: "none" }}
            size="medium"
            onClick={goBackToPersonalInfoStep}
          >
            Back
          </ActionButton>
        )}
        {currentStep !== ModalStep.ASSIGN_TRIAL_CLUSTER_CONFIRMATION_STEP && (
          <ActionButton
            startIcon={<ArrowCircleRightOutlinedIcon />}
            variant="contained"
            size="medium"
            sx={modalStyle.btnStyle}
            onClick={handleContinue}
            disabled={buttonState.disabled}
            progress={buttonState.loading}
          >
            Continue
          </ActionButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
