import { useMemo } from "react";
import { Role, OrganizationType } from "utils/saastypes";
import { UNAUTHORIZED_ROUTE_URL } from "utils/routePaths";
import { Navigate } from "react-router-dom";
import { useAuthMachine } from "auth/state/hook";
import { useAuth } from "auth/AuthContext";
import _isEmpty from "lodash/isEmpty";

export const PrivateRouteWithOrganization = ({
  children,
  hasAnyRoles = [],
  requiresOrkesUser = false,
  restrictedOrganizationTypes: restrictedGroupTypes = [],
}: {
  children: JSX.Element;
  hasAnyRoles?: Role[];
  requiresOrkesUser?: boolean;
  restrictedOrganizationTypes?: OrganizationType[];
}) => {
  const { authService } = useAuth();
  const [
    {
      loggedWithOrganization,
      isLoadingState,
      isOrkesUser,
      currentUserRoles,
      organizationType,
    },
  ] = useAuthMachine(authService);

  const isUnauthorized = useMemo(() => {
    // Orkes user can do everything
    if (isOrkesUser) {
      return false;
    }
    const unAuthorizedByOrgType =
      _isEmpty(restrictedGroupTypes) || organizationType === undefined
        ? false
        : organizationType == null ||
          restrictedGroupTypes?.includes(organizationType);

    // User is restricted by the roles he has in the organization
    const restrictedByRoles = _isEmpty(hasAnyRoles)
      ? false
      : currentUserRoles.some((r) => hasAnyRoles.includes(r));

    return unAuthorizedByOrgType && !restrictedByRoles;
  }, [
    organizationType,
    isOrkesUser,
    currentUserRoles,
    hasAnyRoles,
    restrictedGroupTypes,
  ]);

  if (authService.status === 0) {
    // Machine did not initialize
    return null;
  }

  return (loggedWithOrganization || isLoadingState) && !isUnauthorized ? (
    children
  ) : (
    <Navigate to={UNAUTHORIZED_ROUTE_URL} replace />
  );
};
