import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import LoginSection from "./LoginSection";
import { useLogin } from "./useLogin";
import LoginPageImage from "../../images/login-page-image.svg";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { FormComponent } from "./FormComponent";
import { ErrorMessageType } from "auth/state/types";
import { commonStyle } from "./common";
import { FreeTrialSection } from "./FreeTrialSection";
import { TermsAndConditionText } from "./TermsAndConditions";

export const Login = ({ signup = false }: { signup: boolean }) => {
  const [
    {
      userEmail,
      userPassword,
      isSignUpActive,
      isPasswordLess,
      isLoading,
      messageType,
      isButtonLoading,
      isFormFieldsActive,
      isResetPasswordSection,
      isPasswordVisible,
      inviteId,
    },
    {
      handleLoginOrSignup,
      toggleSignupHandler,
      handleEmailInput,
      handlePasswordInput,
      togglePasswordLessLogin,
      attemptLoginWithGoogle,
      goBackToLoginSection,
      showFormField,
      showForgotPasswordSection,
      triggerResetPasswordEmail,
      togglePasswordVisibility,
      resendVerificationEmail,
      resendMagicLink,
    },
  ] = useLogin(signup);

  const theme = useTheme();

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box sx={commonStyle.loadingContainer}>
          <Typography paddingTop={8} fontSize={20} fontWeight={300}>
            Request is in progress. Please wait...
          </Typography>
          <CircularProgress />
        </Box>
      );
    }

    if (isFormFieldsActive || messageType) {
      return (
        <FormComponent
          userEmail={userEmail}
          userPassword={userPassword}
          isSignUpActive={isSignUpActive}
          isPasswordLess={isPasswordLess}
          handleEmailInput={handleEmailInput}
          handlePasswordInput={handlePasswordInput}
          isButtonLoading={isButtonLoading}
          isResetPasswordSection={isResetPasswordSection}
          handleLoginOrSignup={handleLoginOrSignup}
          togglePasswordLessLogin={togglePasswordLessLogin}
          messageType={messageType}
          goBackToLoginSection={goBackToLoginSection}
          showForgotPasswordSection={showForgotPasswordSection}
          triggerResetPasswordEmail={triggerResetPasswordEmail}
          isPasswordVisible={isPasswordVisible}
          togglePasswordVisibility={togglePasswordVisibility}
          resendVerificationEmail={resendVerificationEmail}
          resendMagicLink={resendMagicLink}
          inviteId={inviteId}
        />
      );
    }
    return (
      <>
        <Typography paddingTop={8} fontSize={31} fontWeight={300}>
          {isSignUpActive ? "Create an account" : "Welcome back!"}
        </Typography>

        <Typography fontSize={20} fontWeight={700} paddingTop={8} mb={12}>
          {isSignUpActive ? "SIGN UP" : "LOGIN"}
        </Typography>

        <LoginSection
          isSignUpActive={isSignUpActive}
          inviteId={inviteId}
          showFormField={showFormField}
          toggleSignupHandler={toggleSignupHandler}
          attemptLoginWithGoogle={attemptLoginWithGoogle}
        />
      </>
    );
  };

  return (
    <Grid container spacing={4} sx={commonStyle.container}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          [theme.breakpoints.down("md")]: {
            display: "flex",
            justifyContent: "center",
            px: 3,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {(isFormFieldsActive || messageType) && (
            <Link
              sx={{
                ...commonStyle.goBackBtnStyle,
                maxWidth:
                  messageType === ErrorMessageType.PERMISSION_DENIED
                    ? 500
                    : 420,
              }}
              onClick={goBackToLoginSection}
            >
              <ArrowBackOutlinedIcon /> Go Back
            </Link>
          )}
          <Paper
            variant="outlined"
            sx={{
              ...commonStyle.paperStyle,
              minHeight: isResetPasswordSection || messageType ? 350 : 450,
              maxWidth:
                messageType === ErrorMessageType.PERMISSION_DENIED ? 500 : 420,
            }}
          >
            {renderContent()}
          </Paper>
          {!messageType && <TermsAndConditionText />}
        </Box>
      </Grid>
      <Grid
        item
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        }}
      >
        {isSignUpActive ? (
          <FreeTrialSection />
        ) : (
          <img src={LoginPageImage} alt="" style={{ height: 500 }} />
        )}
      </Grid>
    </Grid>
  );
};
