import { useState } from "react";
import { TabPanel } from "./TabPanel";
import ClipboardCopy from "../../../components/ClipboardCopy";
import {
  useActionWithPath,
  useDeploymentInfo,
  useHistoryEntry,
} from "../../../utils/query/query";
import LinearProgress from "../../../components/LinearProgress";
import {
  Box,
  Tooltip,
  Chip,
  Paper,
  Avatar,
  Button,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import {
  AccountBox,
  ArticleRounded,
  DeleteRounded,
  Refresh,
  Settings,
  Storage,
} from "@mui/icons-material";
import useInterval from "../../../utils/useInterval";
import StyledMainButton from "../../../components/buttons/StyledMainButton";
import DataTable from "components/DataTable/DataTable";
import { calculateTimeFromMillis, customStyles } from "utils/utils";
import conductor from "../../../images/conductor.png";
import { Pod } from "utils/saastypes";
import { CLUSTER_ACTION_TYPE } from "constants/common";

const StatusChip = ({ stat }: { stat: string | undefined }) => {
  switch (stat) {
    case "Pending":
      return (
        <Chip label="Pending" color="warning" variant="outlined" size="small" />
      );
    case "Running":
      return (
        <Chip label="Running" color="success" variant="outlined" size="small" />
      );
    case "Succeeded":
      return (
        <Chip label="Succeeded" color="info" variant="outlined" size="small" />
      );
    case "Failed":
      return (
        <Chip label="Failed" color="error" variant="outlined" size="small" />
      );
    default:
      return (
        <Chip
          label="Unknown status"
          color="default"
          variant="outlined"
          size="small"
        />
      );
  }
};

type UsageBarProps = {
  value: string;
  backgroundColor: string;
};
const UsageBar = ({ value, backgroundColor }: UsageBarProps) => {
  const barWidth = parseFloat(value);
  return (
    <Box
      sx={{
        border: `1px solid rgba(141,141,141, 0.84)`,
        minWidth: "100px",
        display: "flex",
        position: "relative",
        overflow: "unset",
      }}
    >
      <Box
        style={{
          height: "20px",
          backgroundColor: backgroundColor,
          width: `${barWidth}%`,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {barWidth.toFixed(1)}%
      </Box>
    </Box>
  );
};

export default function PodsTab({
  selectedTabIndex,
  index,
  clusterDetail,
  deletePodAction,
  pullLogsAction,
}: {
  selectedTabIndex: number;
  index: number;
  clusterDetail: any;
  deletePodAction: Function;
  pullLogsAction: Function;
}) {
  const {
    data: deploymentInfo,
    isFetching,
    error: deploymentInfoError,
  } = useDeploymentInfo(clusterDetail?.name);
  const [entryId, setEntryId] = useState("");

  const [showFailedPods, setShowFailedPods] = useState(false);

  const {
    data: historyEntry,
    isFetching: isEntryFetching,
    refetch,
    error: historyEntryError,
  } = useHistoryEntry(clusterDetail?.name, entryId);

  const isWorking =
    isFetching ||
    isEntryFetching ||
    historyEntry?.executionStatus === "SCHEDULED" ||
    historyEntry?.executionStatus === "IN_PROGRESS";

  const podsData =
    historyEntry?.datas ||
    deploymentInfo?.pods_data ||
    /* fallback */ deploymentInfo?.pod_names?.map((name: any) => ({ name })) ||
    [] ||
    [];

  const nonrunning = podsData.filter(
    (pod: Pod) => pod.status !== "Running"
  ).length;

  const filteredPods = podsData.filter(
    (pod: Pod) => showFailedPods || pod.status === "Running"
  );

  const getPodsNamesAction = useActionWithPath({});

  const isError = !!(deploymentInfoError || historyEntryError);

  useInterval(
    async () => {
      refetch();
    },
    historyEntry?.executionStatus === "SCHEDULED" ||
      historyEntry?.executionStatus === "IN_PROGRESS"
      ? 2000
      : null
  );

  return (
    <TabPanel value={selectedTabIndex} index={index}>
      <Box
        style={{
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {!isWorking && (
          <>
            <StyledMainButton
              startIcon={<Refresh />}
              style={{
                marginLeft: 16,
                marginTop: 16,
              }}
              onClick={async () => {
                try {
                  // @ts-ignore
                  let res = await getPodsNamesAction.mutateAsync({
                    method: "post",
                    path: `/agent/command`,
                    body: JSON.stringify({
                      clusterName: clusterDetail?.name,
                      command: CLUSTER_ACTION_TYPE.GET_PODS_DATA,
                      parameters: {},
                    }),
                  });
                  if (res && res.id && res.status === "SUCCESS") {
                    setEntryId(res.id);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              Refresh Pods
            </StyledMainButton>

            {nonrunning > 0 && (
              <FormControlLabel
                sx={{ marginLeft: 4 }}
                control={
                  <Switch
                    checked={showFailedPods}
                    onChange={(e) => setShowFailedPods(e.target.checked)}
                    color="error"
                  />
                }
                label={
                  <Typography fontSize={14}>
                    Show <strong>{nonrunning}</strong> nonrunning Pod
                    {nonrunning > 1 ? "s" : ""}
                  </Typography>
                }
              />
            )}
          </>
        )}
      </Box>
      {isWorking && <LinearProgress />}
      {!isWorking && !isError && (
        <div style={{ borderBottom: "1px solid lightgrey", padding: "16px" }}>
          <Paper variant="outlined">
            <DataTable
              customStyles={customStyles}
              data={filteredPods || []}
              pagination={false}
              noDataComponent={
                <Box padding={5} fontWeight={600}>
                  No Pods found
                </Box>
              }
              columns={[
                {
                  name: "status",
                  label: "Status",
                  selector: (pod: Pod) => pod.status,
                  renderer: (status: string) => <StatusChip stat={status} />,
                  grow: 1,
                },
                {
                  name: "name",
                  label: "Name",
                  selector: (pod: any) => pod.name,
                  renderer: (name: string) => (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PodIcon name={name} />
                      <ClipboardCopy value={name}>{name}</ClipboardCopy>
                    </Box>
                  ),
                  grow: 3,
                },
                {
                  name: "creationTime",
                  label: "Age",
                  selector: (pod: Pod) => pod.creationTime,
                  renderer: (time: string) => {
                    if (time === "unknown" || !time)
                      return <Chip label="Not Available" />;
                    const timestamp =
                      new Date().getTime() - new Date(time)?.getTime() ?? 0;
                    return timestamp < 1000
                      ? `${timestamp} ms`
                      : calculateTimeFromMillis(Math.floor(timestamp / 1000));
                  },
                },
                {
                  name: "cpuUsagePercentage",
                  label: "CPU Usage %",
                  grow: 1.5,
                  selector: (pod: Pod) => pod.cpuUsagePercentage,
                  renderer: (cpuUsage: string) =>
                    cpuUsage === "unknown" || !cpuUsage ? (
                      <Chip label="Not Available" />
                    ) : (
                      <UsageBar value={cpuUsage} backgroundColor="lightblue" />
                    ),
                },
                {
                  name: "memoryUsagePercentage",
                  label: "Memory Usage %",
                  grow: 1.5,
                  selector: (pod: Pod) => pod.memoryUsagePercentage,
                  renderer: (memoryUsage: string) =>
                    memoryUsage === "unknown" || !memoryUsage ? (
                      <Chip label="Not Available" />
                    ) : (
                      <UsageBar
                        value={memoryUsage}
                        backgroundColor="lightgreen"
                      />
                    ),
                },
                {
                  name: "restarts",
                  label: "Restart Count",
                  selector: (pod: Pod) => pod.restarts,
                  renderer: (count?: number) => count || "-",
                  grow: 1,
                },
                {
                  name: "actions",
                  label: "Actions",
                  selector: (pod: any) => pod.name,
                  right: true,
                  grow: 2,
                  renderer(val: any, pod: any) {
                    return (
                      <>
                        <Tooltip title="Pull Pod Logs">
                          <Button
                            variant="outlined"
                            size="small"
                            startIcon={
                              <ArticleRounded
                                style={{ fontSize: 20, color: "error" }}
                              />
                            }
                            sx={{
                              textTransform: "none",
                              marginTop: 1,
                              marginBottom: 1,
                            }}
                            onClick={() => {
                              pullLogsAction(pod.name);
                            }}
                          >
                            Pull logs
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title={
                            canBeSafelyDeleted(pod, podsData)
                              ? `Delete Pod ${pod.name}`
                              : "Deleting the only running Pod may cause downtime"
                          }
                        >
                          <span>
                            <Button
                              disabled={!canBeSafelyDeleted(pod, podsData)}
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                deletePodAction(pod.name);
                              }}
                              size="small"
                              aria-controls="basic-menu"
                              startIcon={
                                <DeleteRounded
                                  style={{ fontSize: 20, color: "error" }}
                                />
                              }
                              sx={{
                                ml: 2,
                                textTransform: "none",
                                marginTop: 1,
                                marginBottom: 1,
                              }}
                            >
                              Delete
                            </Button>
                          </span>
                        </Tooltip>
                      </>
                    );
                  },
                },
              ]}
            />
          </Paper>
        </div>
      )}
      {isError && (
        <div
          style={{
            borderBottom: "1px solid lightgrey",
            padding: "16px",
            marginTop: "16px",
          }}
        >
          <Box>Could not load pods, please try again</Box>
        </div>
      )}
    </TabPanel>
  );
}

function PodIcon({ name }: { name: string }): any {
  if (name.indexOf("conductor") >= 0) {
    return (
      <Avatar src={conductor} sx={{ width: 20, height: 20, marginRight: 1 }} />
    );
  }
  if (name.indexOf("agent") >= 0) {
    return <AccountBox sx={{ fontSize: 22, marginRight: 1 }} />;
  }
  if (name.indexOf("worker") >= 0) {
    return <Settings sx={{ fontSize: 22, marginRight: 1 }} />;
  }
  return <Storage sx={{ fontSize: 22, marginRight: 1 }} />;
}

function getDeploymentStatus(
  referencePod: Pod,
  pods: Pod[]
): {
  deployment: string;
  running: string[];
  unavailable: string[];
} {
  const [deployment] = referencePod.name.split(/-[0-9a-z]{5}$/g);
  const all = pods.filter((p) => p.name.includes(deployment));
  const running = all.filter((p) => p.status === "Running").map((p) => p.name);
  const unavailable = all
    .filter((p) => p.status !== "Running")
    .map((p) => p.name);
  return { deployment, running, unavailable };
}

function canBeSafelyDeleted(pod: Pod, pods: Pod[]): boolean {
  const deployment = getDeploymentStatus(pod, pods);
  // yes we can if it's not running or it isn't the last one running of its "species" :)
  return pod.status !== "Running" || deployment.running.length > 1;
}
