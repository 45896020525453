import { Button, Grid } from "@mui/material";
import { TabPanel } from "./TabPanel";
import { useState } from "react";
import ChangeAuthenticationTypeDialog, {
  AuthenticationType,
  AuthenticationTypeLabel,
} from "components/dialogs/ChangeAuthenticationTypeDialog";
import { CustomerEnvironment } from "utils/saastypes";
import { capitalizeFirstLetter } from "utils/utils";
import { CAPITALIZED_CHARS_REGEX } from "constants/common";

export interface AuthenticationTabProps {
  selectedTabIndex: number;
  index: number;
  clusterDetail: CustomerEnvironment;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  refetchClusterDetail: Function;
}

const AuthenticationTab = ({
  selectedTabIndex,
  index,
  clusterDetail,
  showError,
  showSuccess,
  refetchClusterDetail,
}: AuthenticationTabProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const renderAuthenticationParams = (data: any, renderKeys: string[]) => {
    return Object.keys(data).map((key) =>
      renderKeys.includes(key) ? (
        <Grid key={key} item xs={12}>
          <Grid container>
            <Grid item xs={12} md={2} sx={{ fontWeight: "bold" }}>
              {capitalizeFirstLetter(key)
                ?.split(CAPITALIZED_CHARS_REGEX)
                ?.join(" ")}
              :&nbsp;
            </Grid>
            <Grid item xs={12} md={10}>
              {data[key]}
            </Grid>
          </Grid>
        </Grid>
      ) : null
    );
  };

  return (
    <TabPanel
      style={{
        padding: 16,
        position: "relative",
        borderBottom: "1px solid lightgrey",
      }}
      value={selectedTabIndex}
      index={index}
    >
      {openDialog && (
        <ChangeAuthenticationTypeDialog
          clusterDetail={clusterDetail}
          handleClose={() => setOpenDialog(false)}
          open={openDialog}
          showError={showError}
          showSuccess={showSuccess}
          refetchClusterDetail={refetchClusterDetail}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={2} sx={{ fontWeight: "bold" }}>
              Authentication Type:&nbsp;
            </Grid>
            <Grid item xs={12} md={10}>
              {AuthenticationTypeLabel[clusterDetail.authenticationType]}
            </Grid>
          </Grid>
        </Grid>

        {clusterDetail.authenticationType === AuthenticationType.GENERIC_OIDC &&
          clusterDetail?.authenticationParams?.genericOidcConfig &&
          renderAuthenticationParams(
            clusterDetail.authenticationParams.genericOidcConfig,
            ["audience", "clientId", "issuer", "oidcConfigUrl"]
          )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ textTransform: "unset" }}
            onClick={() => setOpenDialog(true)}
          >
            Change Authentication Type
          </Button>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default AuthenticationTab;
