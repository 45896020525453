import { Box, Typography } from "@mui/material";
import StyledMainButton from "components/buttons/StyledMainButton";
import { NEW_CLUSTER } from "utils/routePaths";
import { Link } from "react-router-dom";
import { useAuth } from "utils/AuthProvider";
import { useAuthMachine } from "auth/state/hook";
import { OrganizationType } from "utils/saastypes";

export const NoClusterContent = () => {
  const { authService } = useAuth();
  const [{ organizationType, isOrkesUser }] = useAuthMachine(authService);
  return (
    <Box
      style={{
        paddingTop: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      fontWeight={500}
    >
      <h3>Welcome, create a new cluster to get started</h3>
      <Typography component="span">
        <StyledMainButton
          style={{
            width: 250,
            fontSize: 15,
            lineHeight: "27px",
            marginRight: 0,
            textAlign: "center",
            borderRadius: 5,
          }}
          component={Link}
          // @ts-ignore
          to={NEW_CLUSTER}
          // This is the "No clusters yet page", so clusters can be created by:
          // - NULL or ENTERPRISE org types
          // - Orkes users
          // So it's only disabled if the org type is FREE_TRIAL and the user is not an Orkes user.
          disabled={
            organizationType === OrganizationType.FREE_TRIAL && !isOrkesUser
          }
        >
          Create New Cluster
        </StyledMainButton>
      </Typography>
    </Box>
  );
};
