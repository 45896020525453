import { yupResolver } from "@hookform/resolvers/yup";
import _isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as yup from "yup";

import { useAuth } from "auth/AuthContext";
import { useActionWithPath, usePackageVersions } from "utils/query";
import {
  CompatibilityDto,
  CustomerEnvironment,
  PackageGroupLabel,
  PackageVersion,
  ResourceType,
  Role,
  UserProfileType,
} from "utils/saastypes";
import {
  getValidVersionRange,
  hasAnyRole,
  isValidSemVer,
  isVersionInRange,
} from "utils/utils";
import {
  PARAMETERS_KEY,
  isMajorVersionUpgrade,
  resourceTypesToValidate,
  upgradeResourceTypes,
} from "./common";
import { useAuthMachine } from "auth/state/hook";

const KEY_NAME_REGEX = /^[a-zA-Z._-][a-zA-Z0-9._-]*$/;

export const useUpgradeDeploymentDialog = (
  clusterDetail: CustomerEnvironment,
  compatibilityVersionList: CompatibilityDto[],
  showError: (message: string) => void,
  showSuccess: (message: string) => void,
  refetchLogs: () => void,
  closeDialog: () => void,
  clusterName?: string
) => {
  const { authService } = useAuth();
  const [{ isOrkesUser, currentUserRoles, currentProfile }] =
    useAuthMachine(authService);

  const navigate = useNavigate();
  const [selectedResourceType, setSelectedResourceType] = useState(
    upgradeResourceTypes[0]
  );
  const [currentVersion, setCurrentVersion] = useState("");

  const [isAcknowledged, setIsAcknowledged] = useState(false);

  const isOrkesAdmin = useMemo(
    () =>
      isOrkesUser &&
      currentUserRoles &&
      (hasAnyRole(currentUserRoles, [Role.COMPANY_ROOT, Role.COMPANY_ADMIN]) ||
        currentProfile === UserProfileType.ENGINEERING),
    [currentUserRoles, isOrkesUser, currentProfile]
  );

  const clusterVersion = useMemo(() => {
    const version =
      clusterDetail?.appVersions?.[selectedResourceType.reference];

    if (version) {
      const tempVersion = version.split(":")?.pop();

      return {
        group: PackageGroupLabel.MAJOR,
        title: `${tempVersion} (current)`,
        value: tempVersion,
      };
    }

    return undefined;
  }, [clusterDetail, selectedResourceType]);

  const validVersionRange = getValidVersionRange({
    version: clusterVersion?.value || "",
    compatibilityVersionList,
  });
  const { data: packageVersions, isFetching: isPackageVersionsFetching } =
    usePackageVersions(selectedResourceType.reference);

  const packages = useMemo<PackageVersion[]>(() => {
    if (packageVersions) {
      return packageVersions.reduce((result, item) => {
        if (item.metadata.length) {
          const title =
            item.metadata[0] === currentVersion
              ? `${item.metadata[0]} (current)`
              : item.metadata[0];

          if (item.metadata.length > 1) {
            return [
              ...result,
              {
                group: PackageGroupLabel.MAJOR,
                title: `Latest - ${title}`,
                value: item.metadata[0],
              },
            ];
          }

          if (
            item.metadata[0].indexOf("local") !== -1 ||
            item.metadata[0].indexOf("creator") !== -1 ||
            item.metadata[0].indexOf(".") === -1
          ) {
            return [
              ...result,
              {
                group: PackageGroupLabel.MINOR,
                title: title,
                value: item.metadata[0],
              },
            ];
          }

          return [
            ...result,
            {
              group: PackageGroupLabel.MAJOR,
              title: title,
              value: item.metadata[0],
            },
          ];
        }

        return result;
      }, [] as PackageVersion[]);
    }

    return [];
  }, [packageVersions, currentVersion]);
  const defaultValues = {
    deploymentVersion: clusterVersion,
    resource: upgradeResourceTypes[0].value,
    [PARAMETERS_KEY]: [] as { key: string; value: string }[],
  };

  const parameterSchema = yup.object().shape({
    key: yup
      .string()
      .matches(KEY_NAME_REGEX, {
        message: "The key name is invalid",
        excludeEmptyString: false,
      })
      .required("Parameter key is required")
      .test("key", "Key need to be unique", function () {
        const parentValues =
          // @ts-ignore
          this.options?.from?.[1]?.value?.[PARAMETERS_KEY];
        return (
          new Set(
            parentValues?.map((item: { [key: string]: string }) => item.key)
          ).size === parentValues?.length
        );
      }),
    value: yup.string(),
  });

  const formSchema = yup.object().shape({
    deploymentVersion: yup
      .object()
      .required("Version is required")
      .typeError("Version is required")
      .test(
        "deploymentVersion",
        "This is a custom version. The version compatibility won't be checked and there is a risk of breaking changes.",
        function (deploymentVersion) {
          const isResourceTypeValid = resourceTypesToValidate.includes(
            selectedResourceType.value
          );
          if (!isResourceTypeValid) {
            return true;
          }
          return deploymentVersion && isValidSemVer(deploymentVersion.value);
        }
      )
      .test(
        "deploymentVersion",
        "Upgrading to this version is supported. Please click on Agree to upgrade below.",
        function (deploymentVersion) {
          const isResourceTypeValid = resourceTypesToValidate.includes(
            selectedResourceType.value
          );
          if (!isResourceTypeValid) {
            return true;
          }

          const isMajorUpgrade = isMajorVersionUpgrade(
            selectedDeploymentVersion?.value,
            currentVersion
          );
          return deploymentVersion && !isMajorUpgrade;
        }
      )
      .test(
        "deploymentVersion",
        "Rolling back to this version is not supported.",
        function (deploymentVersion) {
          const isResourceTypeValid = resourceTypesToValidate.includes(
            selectedResourceType.value
          );
          if (!isResourceTypeValid) {
            return true;
          }
          return (
            deploymentVersion &&
            (isVersionInRange({
              version: deploymentVersion.value,
              rangeStart: validVersionRange.rangeStart,
              rangeEnd: validVersionRange.rangeEnd,
            }) ||
              isOrkesAdmin)
          );
        }
      ),
    resource: yup
      .string()
      .required("Resource type is required")
      .typeError("Resource type is required"),
    [PARAMETERS_KEY]: yup.array().of(parameterSchema),
  });

  const {
    control,
    formState: { errors: formErrors, isValid: isFormValid },
    handleSubmit,
    setValue,
    watch,
    trigger,
    getValues,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(formSchema),
    defaultValues,
  });
  const {
    fields: parametersFields,
    append: appendParameter,
    remove: removeParameter,
  } = useFieldArray({
    control,
    name: PARAMETERS_KEY,
  });

  // Set current version when changing resource type
  useEffect(() => {
    if (clusterVersion) {
      setCurrentVersion(clusterVersion.value || "");

      const existedVersion =
        packages.find((item) => item.value === clusterVersion.value) ||
        clusterVersion;

      setValue("deploymentVersion", existedVersion);
    }
  }, [clusterVersion, packages, setCurrentVersion, setValue]);

  const selectedResource: ResourceType = watch("resource");
  const selectedDeploymentVersion = watch("deploymentVersion");
  const parameters = watch("parameters") as { key: string; value: string }[];

  useEffect(() => {
    if (selectedResource) {
      const temp = upgradeResourceTypes.find(
        (item) => item.value === selectedResource
      );

      if (temp) {
        setSelectedResourceType(temp);
      }
    }
  }, [selectedResource, setSelectedResourceType]);

  useEffect(() => {
    setIsAcknowledged(false);
    trigger();
  }, [selectedDeploymentVersion, trigger]);

  const updateConductorAction = useActionWithPath({
    onSuccess: (data: any) => {
      if (data?.status === "ERROR") {
        showError(`Update deployment failed: ${data.message}`);
      } else {
        showSuccess(`Deployment update has been scheduled successfully.`);
        if (data.id) {
          navigate(`/home/clusterDetails/${clusterName}/history`);
        }
      }
      refetchLogs();
    },
    onError: async (response: any) => {
      const json = await response.json();
      showError(`Update deployment failed: ${json.message}`);
      refetchLogs();
    },
  });

  const onSubmit = (data: {
    deploymentVersion: PackageVersion;
    resource: ResourceType;
    parameters: { key: string; value: string }[];
  }) => {
    closeDialog();
    const resources =
      upgradeResourceTypes.find(
        (resourceType) => resourceType.value === data.resource
      )?.resources || [];
    resources.forEach((res) =>
      // @ts-ignore
      updateConductorAction.mutate({
        method: "post",
        path: `/agent/command`,
        body: JSON.stringify({
          clusterName: clusterDetail?.name,
          command: "UPGRADE_DEPLOYMENT",
          parameters: {
            deploymentVersion: data.deploymentVersion?.value,
            resource: res,
            environmentVariables: data.parameters.reduce(
              (prev: { [key: string]: string }, curr) => {
                prev[curr.key] = curr.value;
                return prev;
              },
              {}
            ),
            acknowledgeIncompatibleUpgrade: isAcknowledged,
          },
        }),
      })
    );
  };

  const handleAcknowledged = () => {
    setIsAcknowledged((currentValue) => {
      return !currentValue;
    });
    trigger();
  };

  const isConfirmDisabled =
    isPackageVersionsFetching ||
    !selectedDeploymentVersion?.value ||
    (selectedDeploymentVersion?.value === currentVersion &&
      parameters?.length <= 0) ||
    (!_isEmpty(formErrors) && !isAcknowledged);

  const handleConfirm = () => {
    // Force submit form even having errors
    if (!isFormValid && isAcknowledged) {
      const data = getValues() as any;

      onSubmit(data);
    } else {
      // @ts-ignore
      handleSubmit(onSubmit)();
    }
  };

  const shouldShowToggle =
    (isMajorVersionUpgrade(selectedDeploymentVersion?.value, currentVersion) ||
      !isValidSemVer(selectedDeploymentVersion?.value)) &&
    resourceTypesToValidate.includes(selectedResource);

  return [
    {
      selectedResourceType,
      validVersionRange,
      packages,
      selectedResource,
      selectedDeploymentVersion,
      currentVersion,
      formErrors,
      isAcknowledged,
      parametersFields,
      isConfirmDisabled,
      shouldShowToggle,
      isOrkesAdmin,
    },
    {
      control,
      removeParameter,
      appendParameter,
      handleAcknowledged,
      handleConfirm,
    },
  ] as const;
};
