import "../../css/ClusterListings.css";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import LinearProgress from "components/LinearProgress";
import AlertDialog from "components/AlertDialog";
import ClusterDetailDisplay from "components/ClusterDetailCard/ClusterDetailDisplay";
import { TrialClusterCard } from "components/ClusterDetailCard/TrialCluster/TrialClusterCard";
import { UserInfoModal } from "components/WelcomeUserModal/UserInfoModal";
import { ClusterListingHeader } from "./ClusterListingHeader";
import { NoClusterContent } from "./NoClusterContent";
import { useClusterListings } from "./useClusterListing";
import { useAuth } from "utils/AuthProvider";
import { useAuthMachine } from "auth/state/hook";
export default function ClusterListings() {
  const [
    {
      filterPopupState,
      sortPopupState,
      clusterListFetching,
      refetchVerifyUserStatus,
      confirmDelete,
      filteredEnvironments,
      currentProfile,
      confirmEnableDelete,
      clusterData,
      filters,
      sortingCriteria,
    },
    {
      toggleFilter,
      refetchAllClusters,
      setConfirmDelete,
      triggerDeletionFlow,
      triggerEnableClusterDeletion,
      setConfirmEnableDelete,
      handleSearchValueChange,
      setSortingCriteria,
      setFilters,
    },
  ] = useClusterListings();
  const { authService } = useAuth();
  const [{ loggedWithNoOrganization }] = useAuthMachine(authService);
  return (
    <>
      <Helmet>
        <title>Orkes Cloud - Cluster Listing</title>
      </Helmet>
      {clusterListFetching && <LinearProgress />}

      {loggedWithNoOrganization ? (
        <UserInfoModal refetchVerifyUserStatus={refetchVerifyUserStatus} />
      ) : null}

      <AlertDialog
        textTitle={""}
        textContent={`Please confirm if you want to proceed to delete this cluster (${confirmDelete.clusterName}). This action cannot be undone. All data will be permanently lost.`}
        open={confirmDelete.confirmDelete}
        setOpen={() =>
          setConfirmDelete({ confirmDelete: false, clusterName: undefined })
        }
        handleConfirm={() =>
          triggerDeletionFlow(true, confirmDelete.clusterName)
        }
      />
      <AlertDialog
        textTitle={""}
        textContent={`Please confirm if you want to enable deletion for cluster (${confirmEnableDelete.clusterName}).`}
        open={confirmEnableDelete.confirmDelete}
        setOpen={() =>
          setConfirmEnableDelete({
            confirmDelete: false,
            clusterName: undefined,
          })
        }
        handleConfirm={() =>
          triggerEnableClusterDeletion(true, confirmEnableDelete.clusterName)
        }
      />
      <ClusterListingHeader
        filteredEnvironments={filteredEnvironments}
        refetchAllClusters={refetchAllClusters}
        sortPopupState={sortPopupState}
        setSortingCriteria={setSortingCriteria}
        clusterData={clusterData}
        filters={filters}
        filterPopupState={filterPopupState}
        setFilters={setFilters}
        handleSearchValueChange={handleSearchValueChange}
        sortingCriteria={sortingCriteria}
        toggleFilter={toggleFilter}
      />

      {filteredEnvironments?.length !== 0 && (
        <Box>
          {filteredEnvironments?.map((custEnv) => {
            const isTrialTypeCluster = custEnv.environmentType === "TRIAL";
            const maybeEngineeringRole =
              currentProfile && currentProfile === "ENGINEERING";

            return isTrialTypeCluster && !maybeEngineeringRole ? (
              <TrialClusterCard
                key={`${custEnv.id}-paper`}
                skipMiddleBar={false}
                custEnv={custEnv}
                refetchAllClusters={refetchAllClusters}
              />
            ) : (
              <ClusterDetailDisplay
                deleteClusterMethod={triggerDeletionFlow}
                enableClusterDeletionMethod={triggerEnableClusterDeletion}
                key={`${custEnv.id}-paper`}
                skipMiddleBar={false}
                custEnv={custEnv}
              />
            );
          })}
        </Box>
      )}
      {filteredEnvironments?.length === 0 && <NoClusterContent />}
    </>
  );
}
