import AlertDialog from "components/AlertDialog";
import {
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  AddRounded,
  RemoveRounded,
  ToggleOffRounded,
  ToggleOnRounded,
} from "@mui/icons-material";

import {
  CompatibilityDto,
  CustomerEnvironment,
  PackageGroupLabel,
  PackageVersion,
  ResourceType,
} from "utils/saastypes";
import { Controller } from "react-hook-form";
import { PARAMETERS_KEY, StyledPopper, upgradeResourceTypes } from "./common";
import { useUpgradeDeploymentDialog } from "./useUpgradeDeploymentDialog";

const filter = createFilterOptions<PackageVersion>();

const UpgradeDeploymentDialog = ({
  clusterName,
  open,
  closeDialog,
  clusterDetail,
  showError,
  showSuccess,
  refetchLogs,
  compatibilityVersionList,
}: {
  clusterName?: string;
  open: boolean;
  closeDialog: () => void;
  clusterDetail: CustomerEnvironment;
  showError: (message: string) => void;
  showSuccess: (message: string) => void;
  refetchLogs: () => void;
  compatibilityVersionList: CompatibilityDto[];
}) => {
  const [
    {
      selectedResourceType,
      packages,
      selectedResource,
      formErrors,
      isAcknowledged,
      parametersFields,
      isConfirmDisabled,
      shouldShowToggle,
    },
    {
      control,
      removeParameter,
      appendParameter,
      handleAcknowledged,
      handleConfirm,
    },
  ] = useUpgradeDeploymentDialog(
    clusterDetail,
    compatibilityVersionList,
    showError,
    showSuccess,
    refetchLogs,
    closeDialog,
    clusterName
  );

  return (
    <AlertDialog
      textTitle={"Upgrade Deployment"}
      textContent={``}
      open={open}
      setOpen={() => closeDialog()}
      isConfirmDisabled={isConfirmDisabled}
      handleConfirm={handleConfirm}
    >
      Upgrade the cluster <strong>{clusterDetail?.name}</strong> to the
      following Conductor version:
      <form>
        <Box sx={{ minWidth: 120, marginTop: "16px", display: "flex" }}>
          <Box style={{ width: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="resource-type-label">Resource Type</InputLabel>
              <Controller
                control={control}
                name="resource"
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="resource-type-label"
                    id="resource-type-select"
                    label="Resource Type"
                    onChange={(event: any) => {
                      field.onChange(event);
                      removeParameter(); //remove key-value parametersFields
                    }}
                  >
                    {upgradeResourceTypes.map((x: any) =>
                      // prevent from showing metrics proxy if it's not installed
                      clusterDetail?.appVersions?.metricsProxy === undefined &&
                      [
                        ResourceType.METRICS_PROXY,
                        ResourceType.CONDUCTOR_SERVER_WORKERS_AND_METRICS_PROXY,
                      ].includes(x.value) ? null : (
                        <MenuItem key={x.value} value={x.value}>
                          {x.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                )}
                rules={{ required: true }}
              />
            </FormControl>

            <FormControl
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
              fullWidth
            >
              <Controller
                control={control}
                rules={{ required: true }}
                name="deploymentVersion"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="conductor-version"
                    fullWidth
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    freeSolo={selectedResource !== ResourceType.AGENT}
                    groupBy={(option) => option.group}
                    PopperComponent={StyledPopper}
                    onChange={(__, newValue: any) => {
                      if (typeof newValue === "string") {
                        if (selectedResource !== ResourceType.AGENT) {
                          field.onChange({
                            group: PackageGroupLabel.MAJOR,
                            title: newValue,
                            value: newValue,
                          });
                        }
                      } else {
                        field.onChange(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );

                      if (
                        inputValue !== "" &&
                        !isExisting &&
                        selectedResource !== ResourceType.AGENT
                      ) {
                        filtered.push({
                          group: PackageGroupLabel.MAJOR,
                          value: inputValue,
                          title: inputValue,
                        });
                      }

                      return filtered;
                    }}
                    options={packages}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }

                      // Regular option
                      return option.title;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.title}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${selectedResourceType.name} version`}
                        error={
                          !isAcknowledged && !!formErrors["deploymentVersion"]
                        }
                        helperText={formErrors["deploymentVersion"]?.message}
                      />
                    )}
                  />
                )}
              />
            </FormControl>

            {shouldShowToggle && (
              <Box>
                <Typography
                  component="i"
                  style={{ marginTop: 0, marginBottom: 0 }}
                >
                  Agree to upgrade
                </Typography>
                <IconButton onClick={handleAcknowledged}>
                  {isAcknowledged ? (
                    <ToggleOnRounded
                      style={{ fontSize: 48, color: "#1976d2" }}
                    />
                  ) : (
                    <ToggleOffRounded
                      style={{
                        fontSize: 48,
                        stroke: "#1976d2",
                        color: "transparent",
                      }}
                    />
                  )}
                </IconButton>
              </Box>
            )}

            <Typography component="p" style={{ marginTop: 0, marginBottom: 0 }}>
              Key-Value parameters:
            </Typography>
            <Box>
              {[
                ResourceType.BOTH_CONDUCTOR_AND_WORKERS,
                ResourceType.CONDUCTOR_SERVER_WORKERS_AND_METRICS_PROXY,
              ].includes(selectedResource) ? (
                <Typography component="i">
                  Adding parameters is not permitted while upgrading multiple
                  resources. Please perform parameter adjustments separately for
                  each resource upgrade.
                </Typography>
              ) : (
                <Box>
                  <Typography component="i">
                    Leaving the value empty will remove the application property
                    if it exists
                  </Typography>
                  {
                    <Grid container spacing={2} sx={{ marginTop: 0.2 }}>
                      {parametersFields.map((item, index) => {
                        const parameterKey = `${PARAMETERS_KEY}[${index}].key`;
                        const parameterValue = `${PARAMETERS_KEY}[${index}].value`;
                        const errorMessage =
                          formErrors && formErrors[PARAMETERS_KEY]
                            ? (formErrors[PARAMETERS_KEY] as any)?.[index]
                            : null;

                        return (
                          <Grid item xs={12} key={item.id}>
                            <Grid container spacing={2}>
                              <Grid item md={5.5}>
                                <FormControl fullWidth>
                                  <Controller
                                    control={control}
                                    // @ts-ignore
                                    name={parameterKey}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Key"
                                        placeholder="Key"
                                        required
                                        error={!!errorMessage?.key?.message}
                                        helperText={errorMessage?.key?.message}
                                      />
                                    )}
                                    rules={{ required: true }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={5.5}>
                                <FormControl fullWidth>
                                  <Controller
                                    control={control}
                                    // @ts-ignore
                                    name={parameterValue}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        label="Value"
                                        placeholder="Value"
                                        error={!!errorMessage?.value?.message}
                                        helperText={
                                          errorMessage?.value?.message
                                        }
                                      />
                                    )}
                                    rules={{ required: false }}
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  sx={{ marginTop: 1 }}
                                  onClick={() => removeParameter(index)}
                                >
                                  <RemoveRounded />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}

                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            appendParameter({ key: "", value: "" })
                          }
                        >
                          <AddRounded />
                        </IconButton>
                      </Grid>
                    </Grid>
                  }
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </form>
    </AlertDialog>
  );
};

export default UpgradeDeploymentDialog;
