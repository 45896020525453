import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { CardInfoType, OrganizationType } from "utils/saastypes";
import { EditIcon } from "components/icons/EditIcon";
import { UnlockIcon } from "components/icons/UnlockIcon";
import { useClusterListings } from "pages/ClusterListings/useClusterListing";
import { useMemo } from "react";
import { CLUSTER_STATE } from "constants/common";

interface PaymentDataType {
  cardInfo?: CardInfoType;
  handleEditClick: () => void;
}

const PaymentData = ({ cardInfo, handleEditClick }: PaymentDataType) => {
  if (cardInfo?.last4) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <Typography>****{cardInfo.last4}</Typography>
        <IconButton onClick={handleEditClick} sx={{ cursor: "pointer", mr: 4 }}>
          <EditIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Button
      variant="contained"
      sx={{ textTransform: "none", mr: 4 }}
      onClick={handleEditClick}
    >
      Add Payment Method
    </Button>
  );
};

export const PaymentsTab = ({
  cardInfo,
  organizationType,
}: Partial<PaymentDataType> & { organizationType?: OrganizationType }) => {
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate("/home/update-payment-method");
  };

  const [{ clusterData }] = useClusterListings();

  const isTrialClusterProvisioning = useMemo(() => {
    return (
      clusterData?.length === 1 &&
      clusterData[0]?.state === CLUSTER_STATE.PROVISIONING
    );
  }, [clusterData]);

  return (
    <Box
      sx={{
        px: 6,
        py: 8,
      }}
    >
      <Typography variant="h4" sx={{ opacity: 0.8, mb: 6 }}>
        Manage Payment
      </Typography>

      {organizationType !== OrganizationType.ENTERPRISE ? (
        <Paper
          variant="outlined"
          sx={{
            px: 4,
            py: 6,
            width: "fit-content",
            maxWidth: 600,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Typography variant="h5" sx={{ letterSpacing: 1 }}>
            TRIAL
          </Typography>
          <Typography fontSize={14}>
            You are currently on an Enterprise Base free trial with access to
            premium add-ons. Upgrade now to avoid interruption of service.
          </Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              alignSelf: "flex-end",
              width: "100px",
            }}
            startIcon={<UnlockIcon />}
            onClick={() => navigate("/home/upgrade")}
            disabled={isTrialClusterProvisioning}
          >
            Upgrade
          </Button>
        </Paper>
      ) : (
        <Paper
          variant="outlined"
          sx={{
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            px: 5,
            py: 4,
            gap: 12,
          }}
        >
          <Typography variant="h5">Payment Method:</Typography>
          <PaymentData cardInfo={cardInfo} handleEditClick={handleEditClick} />
        </Paper>
      )}
    </Box>
  );
};
