import MuiLinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { LINEAR_PROGRESS_INDEX } from "constants/common";

export default function LinearProgress({ sx, ...props }: LinearProgressProps) {
  return (
    <MuiLinearProgress
      sx={{ marginBottom: "-4px", zIndex: LINEAR_PROGRESS_INDEX, ...sx }}
      {...props}
    />
  );
}
