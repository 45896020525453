import { Box } from "@mui/material";
import ActionButton from "components/buttons/ActionButton";

interface TrialClusterConfirmationStepProps {
  onClose: () => void;
  uiUrl: string | null;
}

export const TrialClusterConfirmationStep = ({
  onClose,
  uiUrl,
}: TrialClusterConfirmationStepProps) => {
  return (
    <>
      <Box sx={{ display: "flex", gap: 4, mt: 10, mb: 6 }}>
        <ActionButton
          variant="outlined"
          size="large"
          sx={{
            color: "#1976D2",
            borderColor: "#1976D2",
            textTransform: "capitalize",
          }}
          onClick={onClose}
        >
          Manage Cluster
        </ActionButton>
        <ActionButton
          variant="contained"
          size="large"
          sx={{
            backgroundColor: "#1976D2",
            borderColor: "#1976D2",
            textTransform: "capitalize",
          }}
          href={`https://${uiUrl}`}
          target="_blank"
          disabled={!uiUrl}
        >
          Open My Cluster
        </ActionButton>
      </Box>
    </>
  );
};
