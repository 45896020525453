import { useState } from "react";
import { black } from "../theme/colors";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { FileCopyRounded } from "@mui/icons-material";

export default function ClipboardCopy({ children, style = {}, value }) {
  const [isToastOpen, setIsToastOpen] = useState(false);
  function copyContent() {
    setIsToastOpen(true);
    navigator.clipboard
      .writeText(value)
      .then(() => {})
      .catch((e) => {
        console.log("Unable to copy to clipboard!", e);
      });
  }
  return (
    <>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={2000}
        onClose={() => setIsToastOpen(false)}
      >
        <Alert
          style={{
            background: "#2e7d32",
            color: "white",
          }}
          severity="success"
        >
          Copied to Clipboard
        </Alert>
      </Snackbar>
      <div style={{ display: "flex" }}>
        {children}
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            sx={{
              padding: 0,
              marginLeft: "10px",
              "& svg": {
                fontSize: "15px",
                color: black,
              },
              "& svg:active": {
                color: "#5bb8d4",
              },
            }}
            onClick={copyContent}
            size={"small"}
          >
            <FileCopyRounded color="primary" />
          </IconButton>
        </div>
      </div>
    </>
  );
}
